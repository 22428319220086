import type { RcFile } from 'antd/lib/upload';
import t from 'zod';

export const REQUIRED_MESSAGE_CLIENT_ID = 'Por favor, informe o Client ID.';
export const REQUIRED_MESSAGE_CLIENT_SECRET =
  'Por favor, informe o Client Secret.';
export const REQUIRED_MESSAGE_CERT_CRT =
  'Por favor, informe o Arquivo do Certificado.';
export const REQUIRED_MESSAGE_CERT_KEY =
  'Por favor, informe o Arquivo da Chave Privada.';
export const REQUIRED_MESSAGE_TERM =
  'Por favor, confirme que já possui as credenciais da API.';

export const InterAutoPaymentTermResolver = t.object({
  term: t.literal(true, {
    errorMap: () => ({ message: REQUIRED_MESSAGE_TERM }),
  }),
});

export const InterAutoPaymentResolver = t.object({
  clientId: t
    .string({
      required_error: REQUIRED_MESSAGE_CLIENT_ID,
    })
    .min(1, { message: REQUIRED_MESSAGE_CLIENT_ID }),

  clientSecret: t
    .string({
      required_error: REQUIRED_MESSAGE_CLIENT_SECRET,
    })
    .min(1, { message: REQUIRED_MESSAGE_CLIENT_SECRET }),

  certCrt: t.array(t.custom<RcFile>()).min(1, REQUIRED_MESSAGE_CERT_CRT),
  certKey: t.array(t.custom<RcFile>()).min(1, REQUIRED_MESSAGE_CERT_KEY),
});
