import { useState } from 'react';

import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Stack,
} from '@mui/material';
import { MenuIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { RouteKey } from 'src/router/types';

import { useIsEnabledBillsApprovalsInCompany } from 'src/libs/finbits/Organization/Companies/Options';

import NavigationItem from 'src/features/topmenu/Navigation/NavigationItem';
import SwitchCompanyAutocomplete from 'src/features/topmenu/SwitchCompanyAutocomplete';

import { navigation } from './NavigationBurger.sx';
import styles from './NavigationBurguer.module.scss';

export default function NavigationBurger() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { isEnabledBillsApprovals } = useIsEnabledBillsApprovalsInCompany();

  const isVisibility = !!anchorEl;

  return (
    <Stack direction="row" sx={navigation}>
      <SwitchCompanyAutocomplete />

      <IconButton
        size="large"
        aria-controls="navigation"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>

      <Menu
        id="navigation"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={isVisibility}
        onClose={() => setAnchorEl(null)}
        sx={{
          display: { md: 'block', lg: 'none' },
        }}
      >
        <MenuList>
          <NavigationItem
            routeKey={RouteKey.OVERVIEW}
            permission={{
              action: 'show_overview',
              resource: 'webappPages',
              scope: 'company',
            }}
          >
            Visão geral
          </NavigationItem>

          <Divider component="li" className={styles.divider} />

          <NavigationItem
            routeKey={RouteKey.INBOX_ITEMS}
            permission={{
              action: 'index',
              resource: 'inboxItems',
              scope: 'company',
            }}
          >
            Caixa de Entrada
          </NavigationItem>

          <Divider component="li" className={styles.divider} />

          <MenuItem className={styles.menuSectionTitle} disabled>
            <Typography color="grey.400" fontWeight="500" variant="text-xs">
              Pagamentos
            </Typography>
          </MenuItem>

          <NavigationItem
            routeKey={RouteKey.BILLS_TO_PAY}
            permission={{
              action: 'index',
              resource: 'bills',
              scope: 'company',
            }}
          >
            Contas a Pagar
          </NavigationItem>
          {isEnabledBillsApprovals && (
            <NavigationItem
              routeKey={RouteKey.APPROVAL}
              permission={{
                action: 'index_pending_approval',
                resource: 'bills',
                scope: 'company',
              }}
            >
              Aprovações
            </NavigationItem>
          )}
          <Divider component="li" className={styles.divider} />
          <NavigationItem
            routeKey={RouteKey.ENTRIES}
            permission={{
              action: 'index',
              resource: 'financialStatements',
              scope: 'company',
            }}
          >
            Lançamentos
          </NavigationItem>
          <Divider component="li" className={styles.divider} />
          <NavigationItem
            routeKey={RouteKey.NOTA_FISCAL}
            permission={{
              action: 'index',
              resource: 'notasFiscais',
              scope: 'company',
            }}
          >
            Notas Fiscais
          </NavigationItem>
          <Divider component="li" className={styles.divider} />

          <MenuItem className={styles.menuSectionTitle} disabled>
            <Typography color="grey.400" fontWeight="500" variant="text-xs">
              Resumo
            </Typography>
          </MenuItem>

          <NavigationItem
            routeKey={RouteKey.DASHBOARD}
            permission={{
              action: 'show',
              resource: 'companies',
              scope: 'company',
            }}
          >
            Empresa atual
          </NavigationItem>
          <NavigationItem
            routeKey={RouteKey.BALANCES}
            permission={{
              action: 'index',
              resource: 'companiesBalances',
              scope: 'organization',
            }}
          >
            Todas as empresas
          </NavigationItem>
        </MenuList>
      </Menu>
    </Stack>
  );
}
