import { Box } from '@mui/material';
import { Typography } from 'src/design-system/components';
import { Link } from 'src/mui/_scss';

import { useOpenPortal } from 'src/ui';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import { useOpenBankingConnectionFeatures } from 'src/libs/finbits/Bank/OpenBanking';
import { PaymentConnectionScopes } from 'src/libs/finbits/Bank/OpenBanking/enum';
import { CONNECTION_SCOPES_DICTIONARY } from 'src/libs/finbits/Bank/OpenBanking/translation';
import { RoutingNumber } from 'src/libs/finbits/Bank/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import {
  BBAutoPayment,
  InterAutoPayment,
  ItauAutoPayment,
  SantanderAutoPayment,
} from '../AutoPayment/';

import AccountFeatureBadge from './AccountFeatureBadge';

type Props = {
  account: Account;
  title?: string;
};

function getPaymentMethodsText(
  scopes: PaymentConnectionScopes[],
  routingNumber: RoutingNumber
) {
  const defaultPaymentText =
    routingNumber === RoutingNumber.BTG
      ? 'Pagamento automático via Pix, TED e Boleto'
      : 'Pagamento automático via Pix e TED';

  let filteredScopes = scopes.filter(
    (method) => method in CONNECTION_SCOPES_DICTIONARY
  );

  if (filteredScopes.includes(PaymentConnectionScopes.QRCODE)) {
    filteredScopes = filteredScopes.filter(
      (method) => method !== PaymentConnectionScopes.PIX
    );
  }

  const paymentText = filteredScopes
    .map(
      (method) =>
        CONNECTION_SCOPES_DICTIONARY[
          method as keyof typeof CONNECTION_SCOPES_DICTIONARY
        ]
    )
    .join(', ')
    .replace(/, ([^,]*)$/, ' e $1');

  return paymentText
    ? `Pagamento automático via ${paymentText}`
    : defaultPaymentText;
}

export default function AccountFeatures({
  account,
  title = 'Funcionalidades',
}: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const openPortal = useOpenPortal();

  const { bankStatement, paymentConnection } =
    useOpenBankingConnectionFeatures({
      companyId,
      organizationId,
      accountIds: [account.id],
    })?.features?.[0] || {};

  const isSupportedBankForConnection = [
    RoutingNumber.SANTANDER,
    RoutingNumber.ITAU,
    RoutingNumber.INTER,
    RoutingNumber.BB,
  ].includes(account.routingNumber as RoutingNumber);

  const hasPaymentConnection =
    paymentConnection?.scopes?.some((scope) =>
      [
        PaymentConnectionScopes.PIX,
        PaymentConnectionScopes.BOLETO,
        PaymentConnectionScopes.TED,
      ].includes(scope)
    ) || false;

  const hasDDA =
    paymentConnection?.scopes?.some(
      (scope) => scope === PaymentConnectionScopes.DDA
    ) || false;

  function handleRegisterBalance() {
    const AutoPayment = {
      [RoutingNumber.BB]: BBAutoPayment,
      [RoutingNumber.INTER]: InterAutoPayment,
      [RoutingNumber.ITAU]: ItauAutoPayment,
      [RoutingNumber.SANTANDER]: SantanderAutoPayment,
    };

    openPortal(AutoPayment[account.routingNumber as keyof typeof AutoPayment], {
      account,
    });
  }

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography
        component="h6"
        variant="text-sm"
        color="black"
        fontWeight={700}
      >
        {title}
      </Typography>

      <AccountFeatureBadge
        isAccountFeatureEnabled={!!bankStatement}
        title="Leitura automática de extrato"
      />

      <AccountFeatureBadge
        isAccountFeatureEnabled={hasPaymentConnection}
        title={getPaymentMethodsText(
          paymentConnection?.scopes || [],
          account.routingNumber as RoutingNumber
        )}
      >
        {!hasPaymentConnection && isSupportedBankForConnection && (
          <Link onClick={handleRegisterBalance} role="button">
            Conectar
          </Link>
        )}
      </AccountFeatureBadge>

      {account.routingNumber === RoutingNumber.BTG && (
        <AccountFeatureBadge
          nested={true}
          isAccountFeatureEnabled={hasDDA}
          title="Leitura automática de boletos via DDA"
        />
      )}
    </Box>
  );
}
