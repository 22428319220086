import { createContext, useContext, useState } from 'react';

import type { TabPanelsContextProps, TabPanelsProviderProps } from './types';
import { PreviewTab, Tab } from './types';

const TabPanelsContext = createContext({} as TabPanelsContextProps);

export default function TabPanelsProvider({
  children,
  disabledTabs,
}: TabPanelsProviderProps) {
  const [selectedBillTab, setSelectedBillTab] = useState(Tab.INFORMATION);

  const [selectedPreviewTab, setSelectedPreviewTab] = useState(
    PreviewTab.SUGGESTIONS
  );

  return (
    <TabPanelsContext.Provider
      value={{
        selectedBillTab,
        setSelectedBillTab,
        selectedPreviewTab,
        setSelectedPreviewTab,
        disabledTabs,
      }}
    >
      {children}
    </TabPanelsContext.Provider>
  );
}

export function useTabPanelsContext() {
  const context = useContext(TabPanelsContext);

  if (!context) {
    throw new Error('To use context, you must provide a TabPanelsProvider');
  }

  return context;
}
