import type t from 'zod';

import type { EntryClassificationsDecoder } from 'src/libs/finbits/Classifications/types';
import type { PaymentDetails } from 'src/libs/finbits/Payments/types';

import type { ManualConciliateFormProps } from 'src/features/entries/ManualConciliateHeaderButton/ManualConciliateModal';

import type {
  BillFormResolver,
  BillPayableAssignmentDecoder,
  BillPayableDecoder,
  BillPayableLastApprovalDecoder,
  ClassificationLabelsDecoder,
  RecurringBillDecoder,
} from './validations';

export enum BillFrequencyRecurrence {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  BIWEEKLY = 'biweekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly',
}

export enum BillStatus {
  PENDING = 'pending',
  WAITING_APPROVAL = 'waiting_approval',
  APPROVED = 'approved',
  REPROVED = 'reproved',
  WAITING_PAYMENT = 'waiting_payment',
}

export enum BillApprovalStatus {
  PENDING = 'pending',
  WAITING_APPROVAL = 'waiting_approval',
  APPROVED = 'approved',
  REPROVED = 'reproved',
}

export enum BillPayableApprovalType {
  ALL = 'all',
  ANY = 'any',
}

export type ClassificationLabels = t.TypeOf<typeof ClassificationLabelsDecoder>;
export type EntryClassification = t.TypeOf<typeof EntryClassificationsDecoder>;

export type BillFormParams = t.TypeOf<typeof BillFormResolver>;
export type BillParams = Omit<
  t.TypeOf<typeof BillFormResolver>,
  'classifications' | 'attachments'
> & {
  classifications: ClassificationLabels[];
  attachmentsIds: string[];
  inboxItemsIds: string[];
};

type Entry = Omit<BillParams, 'isRecurrenceEnabled'>;
export type BillRecurrence = {
  entries: Entry[];
};
export type BillRecurrencePostParam = BillRecurrence & {
  companyId: string;
  organizationId: string;
};

type EditableParams = Pick<
  Partial<BillPayable>,
  | 'amount'
  | 'description'
  | 'accountId'
  | 'categoryId'
  | 'contactId'
  | 'comments'
> & {
  date?: Date;
  relevantDate?: Date | null;
  attachmentsIds?: string[];
  labelsIds?: string[];
  classifications?: ClassificationLabels[];
  paymentDetails?:
    | (Omit<PaymentDetails, 'boleto'> & {
        boletoId?: string | null;
        boleto?: { digitableLine?: string | null };
      })
    | null;
};

export type BillPayable = t.TypeOf<typeof BillPayableDecoder>;
export type RecurringBill = t.TypeOf<typeof RecurringBillDecoder>;

export type BillPayableLastApproval = t.TypeOf<
  typeof BillPayableLastApprovalDecoder
>;

export type BillPayableAssignment = t.TypeOf<
  typeof BillPayableAssignmentDecoder
>;

export type BillGetParams = {
  organizationId?: string;
  companyId?: string;
  billId: string;
  includingDeleted?: boolean;
};

export type BillsGetParams = {
  organizationId?: string;
  companyId?: string;
  order?: 'asc' | 'desc';
  status?: BillStatus;
  startDate?: string;
  endDate?: string;
};

export type BillsPendingMyApprovalGetParams = {
  organizationId?: string;
  companyId?: string;
};

export type BillPostParams = EditableParams & {
  organizationId?: string;
  companyId?: string;
  inboxItemId?: string;
  classifications?: ClassificationLabels[];
};

export type BillPatchParams = EditableParams & {
  billId: string;
  organizationId?: string;
  companyId?: string;
  inboxItemId?: string;
};

export type BillsDeleteManyParams = {
  organizationId?: string;
  companyId?: string;
  billIds: string[];
};

export type RecurringBillPostParams = EditableParams & {
  organizationId?: string;
  companyId?: string;
  inboxItemsIds?: string[];
  quantity?: number | null;
  frequencyInterval?: number | null;
  frequencyUnit?: string | null;
};

export type CreateFinancialFromBillParams = {
  organizationId?: string;
  companyId?: string;
  billId?: string;
} & ManualConciliateFormProps;

export type BillRecurrencesDeleteParams = {
  organizationId?: string;
  companyId?: string;
  billId?: string;
};

export type CreateFinancialFromBillsParams = {
  organizationId?: string;
  companyId?: string;
  bills: {
    billId: string;
    financialEntryDate: string;
  }[];
};
