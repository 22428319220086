import { useEffect, useMemo, useRef } from 'react';

import { Stack } from '@mui/material';
import { useWatch } from 'react-hook-form';

import { Loader } from 'src/ui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useAttachment } from 'src/libs/finbits/Management/Attachments';

import { usePreviewContext } from 'src/features/entries/Modals/EntryModal/Preview/PreviewProvider';

import EmptyState from './EmptyState';
import AttachmentPreview from './AttachmentPreview';
import AttachmentPagination from './AttachmentPagination';
import styles from './Attachments.module.scss';

export default function Attachments() {
  const ref = useRef<HTMLDivElement>(null);

  const { companyId, organizationId } = useCompanyParams();
  const { currentAttachmentIndex, setCurrentAttachmentIndex } =
    usePreviewContext();

  const attachments = useWatch({ name: 'attachments' });

  const attachmentId = useMemo(() => {
    const currentAttachment = attachments[currentAttachmentIndex - 1];

    return currentAttachment?.id;
  }, [currentAttachmentIndex, attachments]);

  const { attachment: fetchedAttachment, isLoading } = useAttachment({
    companyId,
    organizationId,
    attachmentId,
  });

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (
        document.activeElement !== document.body &&
        document.activeElement !== ref.current
      )
        return;

      if (event.key === 'ArrowLeft') {
        event.preventDefault();

        if (currentAttachmentIndex === 1) return;

        return setCurrentAttachmentIndex(currentAttachmentIndex - 1);
      }

      if (event.key === 'ArrowRight') {
        event.preventDefault();

        if (currentAttachmentIndex === attachments.length) return;

        return setCurrentAttachmentIndex(currentAttachmentIndex + 1);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentAttachmentIndex, attachments, setCurrentAttachmentIndex]);

  function handleClick() {
    ref.current?.focus();
  }

  if (attachments.length === 0) {
    return <EmptyState />;
  }

  if (!fetchedAttachment) {
    return <Loader />;
  }

  return (
    <Stack
      ref={ref}
      tabIndex={1}
      onClick={handleClick}
      className={styles.preview}
    >
      <AttachmentPagination
        attachment={fetchedAttachment}
        totalAttachments={attachments.length}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <AttachmentPreview attachment={fetchedAttachment} />
      )}
    </Stack>
  );
}
