import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import cn from 'classnames';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import AutoPaymentCard from 'src/features/open-banking/AutoPayment/AutoPaymentModal/AutoPaymentCard';
import { HelperText } from 'src/features/open-banking/AutoPayment/AutoPaymentModal/HelperText';
import type { StepperContentProps } from 'src/features/open-banking/AutoPayment/VerticalStepper/types';

import type { VerifyCredentialsType } from './types';
import { VerifyCredentialsResolver } from './validations';
import styles from './VerifyCredentialsStep.module.scss';

export default function VerifyCredentialsStep({
  onNext,
  onPrev,
}: StepperContentProps) {
  const form = useForm<VerifyCredentialsType>({
    resolver: zodResolver(VerifyCredentialsResolver),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      confirmCredentials: false,
    },
  });

  async function handleNextStep(event: React.MouseEvent) {
    const isValid = await form.trigger();

    if (isValid) onNext?.(event);
  }

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={6}>
        <AutoPaymentCard
          stepIndex={4.1}
          heading="Acesse o Portal Developers BB."
          buttonLabel="Acessar Portal do BB"
          buttonHref="https://acesso.bb.com.br/v2/login?appId=ipa-developers"
          buttonTarget="_blank"
        >
          <Typography variant="text-sm" color="grey.600">
            Entre com seu CPF e crie uma senha para o Portal para se cadastrar.
          </Typography>
        </AutoPaymentCard>

        <AutoPaymentCard
          stepIndex={4.2}
          heading="No portal do BB, siga o passo a passo abaixo:"
          buttonLabel="Ver passo a passo"
          buttonHref="https://www.finbits.com.br/duvidas-frequentes/conta-bancaria-conectando-com-o-banco-do-brasil"
          buttonTarget="_blank"
        >
          <Typography variant="text-sm" color="grey.600">
            Você irá solicitar a criação de uma API de conexão do Banco do
            Brasil com a FinBits para realizar{' '}
            <strong>pagamentos automáticos.</strong>
          </Typography>
        </AutoPaymentCard>

        <AutoPaymentCard
          stepIndex={4.3}
          heading="Volte ao app da FinBits com as credenciais geradas"
        >
          <Typography variant="text-sm" color="grey.600">
            Copie as credenciais geradas ao final do processo de criação da API
            no Portal Developers BB.
          </Typography>
        </AutoPaymentCard>
      </Box>

      <Box>
        <FormProvider {...form}>
          <Card
            variant="outlined"
            className={cn(styles.card, {
              [styles.error]: form.formState.errors.confirmCredentials,
            })}
          >
            <Box className={styles.formControlLabel}>
              <Controller
                name="confirmCredentials"
                control={form.control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        size="small"
                        title="Confirmação de credenciais BB"
                        className={styles.checkbox}
                      />
                    }
                    label="Tenho as credenciais geradas pelo Portal Developers BB."
                    title="Confirmação de credenciais BB"
                  />
                )}
              />

              <Typography color="grey.600" marginLeft={6}>
                Confirme que já possui as credenciais para seguir para próxima
                etapa.
              </Typography>
            </Box>
          </Card>

          <HelperText
            message={form.formState.errors.confirmCredentials?.message}
          />
        </FormProvider>
      </Box>

      <Box display="flex" justifyContent="end" gap={2}>
        <Button size="medium" variant="outlined" onClick={onPrev}>
          Voltar
        </Button>

        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={handleNextStep}
        >
          Continuar
        </Button>
      </Box>
    </Box>
  );
}
