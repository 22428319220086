import { useEffect, useMemo, useReducer } from 'react';

import orderBy from 'lodash/orderBy';

import {
  endOfMonth,
  isBefore,
  parseISO,
  startOfMonth,
  subMonths,
} from 'src/libs/finbits/Date';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { CashFlowGroupBy } from 'src/libs/finbits/Overview/enum';
import { getItem, setItem } from 'src/libs/finbits/Storage';
import { useAccountsInitialBalances } from 'src/libs/finbits/Organization/Companies/Balances';
import type { AccountBalance } from 'src/libs/finbits/Organization/Companies/Balances/types';

import type { ReduceAction, ReduceState } from './types';

const TODAY = new Date();
const TWELVE_MONTHS_BEFORE = subMonths(TODAY, 12);
const EMPTY_STATE: ReduceState = {
  filters: {
    selectedAccountIds: [],
    selectedDateRange: [startOfMonth(TWELVE_MONTHS_BEFORE), endOfMonth(TODAY)],
    xAxis: CashFlowGroupBy.MONTH,
  },
  configs: {
    showMoreDetails: false,
  },
};

export function buildStorageKey(companyId: string) {
  return `chart_page_filters:v2:${companyId}`;
}

function getOldestDate(
  accounts: AccountBalance[],
  selectedAccountIds: string[]
) {
  const oldestBalanceDate = orderBy(
    accounts.filter((acc) => selectedAccountIds.includes(acc.accountId)),
    (item) => item.date,
    ['desc']
  ).pop()?.date;

  return oldestBalanceDate ? parseISO(oldestBalanceDate) : undefined;
}

function storeState(companyId: string, newState: ReduceState) {
  const storageKey = buildStorageKey(companyId);

  setItem(storageKey, newState);
}

function reducer(state: ReduceState, action: ReduceAction) {
  const { type, payload } = action;

  switch (type) {
    case 'selectedAccountIds': {
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedAccountIds: payload.newValue,
        },
      };
    }
    case 'selectedDateRange': {
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedDateRange: payload.newValue,
        },
      };
    }
    case 'xAxis': {
      return {
        ...state,
        filters: {
          ...state.filters,
          xAxis: payload.newValue,
        },
      };
    }
    case 'showMoreDetails': {
      return {
        ...state,
        configs: {
          ...state.configs,
          showMoreDetails: payload.newValue,
        },
      };
    }
    default:
      return state;
  }
}

function parseStoredDateFilters(value: Date | string) {
  if (typeof value === 'string') {
    return parseISO(value);
  }

  return value;
}

function getStateFromStrorage(
  companyId: string,
  defaultState: ReduceState
): ReduceState {
  const storageKey = buildStorageKey(companyId);

  const storedState = getItem(storageKey, defaultState);

  const parsedDateRange: [Date, Date] = [
    parseStoredDateFilters(storedState.filters.selectedDateRange[0]),
    parseStoredDateFilters(storedState.filters.selectedDateRange[1]),
  ];

  return {
    ...storedState,
    filters: { ...storedState.filters, selectedDateRange: parsedDateRange },
  };
}

function createInitialReduceState(companyId: string) {
  return (initialState: ReduceState) =>
    getStateFromStrorage(companyId, initialState);
}

export default function usePageFilters() {
  const { companyId, organizationId } = useCompanyParams();

  const [state, dispatch] = useReducer(
    reducer,
    EMPTY_STATE,
    createInitialReduceState(companyId)
  );

  const {
    filters: { xAxis, selectedAccountIds, selectedDateRange },
    configs: { showMoreDetails },
  } = state;

  const { accountsBalances, isLoading } = useAccountsInitialBalances(
    { companyId, organizationId },
    {
      onSuccess: (accountsBalances) => {
        if (selectedAccountIds.length === 0) {
          dispatch({
            type: 'selectedAccountIds',
            payload: {
              newValue: accountsBalances.map((balance) => balance.accountId),
            },
          });
        }
      },
    }
  );

  const minDateRange = useMemo(() => {
    const limitStartDate = getOldestDate(accountsBalances, selectedAccountIds);

    if (limitStartDate && isBefore(selectedDateRange[0], limitStartDate)) {
      dispatch({
        type: 'selectedDateRange',
        payload: { newValue: [limitStartDate, selectedDateRange[1]] },
      });
    }

    return limitStartDate;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsBalances, selectedAccountIds]);

  function handleUpdate(
    key: keyof ReduceState['filters'] | keyof ReduceState['configs']
  ) {
    return function updateValue(newValue: any) {
      dispatch({
        type: key,
        payload: { newValue },
      });
    };
  }

  useEffect(() => {
    storeState(companyId, state);
  }, [companyId, state]);

  return {
    xAxis,
    setXAxis: handleUpdate('xAxis'),
    selectedAccountIds,
    setSelectedAccountIds: handleUpdate('selectedAccountIds'),
    selectedDateRange,
    setSelectedDateRange: handleUpdate('selectedDateRange'),
    minDateRange,
    isLoadingBalances: isLoading,
    showMoreDetails,
    setShowMoreDetails: handleUpdate('showMoreDetails'),
  };
}
