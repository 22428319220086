import type { ComponentProps } from 'react';

import { Divider, MenuList } from '@mui/material';
import type { GridUpdateAction } from '@mui/x-data-grid-premium';
import { ContextItem } from 'src/mui/_scss/Table/GridContextMenu';
import type { CustomContextMenuOptions } from 'src/mui/_scss/Table/types';

import { Tooltip } from 'src/mui';

import type { BillPayable } from 'src/libs/finbits/Bills/types';

import { useBuildActions } from 'src/features/bills-to-pay/Actions/useBuildActions';

export default function ContextMenuRowActions({
  info,
  onClose,
  api,
}: ComponentProps<CustomContextMenuOptions<BillPayable>>) {
  function handleSuccess(selecteds?: BillPayable[]) {
    if (!selecteds) return;

    const rowsToRemove: Array<{ id: string; _action: GridUpdateAction }> =
      selecteds.map((s) => ({
        id: s.id,
        _action: 'delete',
      }));

    api.updateRows(rowsToRemove);
  }

  const { buildAllActions } = useBuildActions(info.row, handleSuccess);

  const actions = buildAllActions();

  return (
    <MenuList>
      {actions.map((action, index) => (
        <div key={action.name}>
          <Tooltip
            title={action?.tooltipTitle}
            placement="top"
            disableHoverListener={!action?.tooltipActive}
          >
            <ContextItem
              {...action}
              onClose={onClose}
              key={action.name}
              disabled={action?.tooltipActive}
            />
          </Tooltip>

          {action.dividerAfter && actions[index + 1] !== undefined && (
            <Divider variant="fullWidth" />
          )}
        </div>
      ))}
    </MenuList>
  );
}
