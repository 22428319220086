import { useEffect, useRef, useState } from 'react';

import { Stack } from '@mui/material';

import { isImageType, isPDFType } from 'src/libs/finbits/File/File';
import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';

import PreviewIMG from 'src/features/preview/PreviewIMG';
import PreviewPDF from 'src/features/preview/PreviewPDF';
import { useControls } from 'src/features/preview/PreviewBase';
import {
  EXPANSION_ENTER_TIME,
  EXPANSION_EXIT_TIME,
  usePreviewContext,
} from 'src/features/entries/Modals/EntryModal/Preview/PreviewProvider';

import PreviewBase from './PreviewBase';
import PreviewError from './PreviewError';
import ToolbarActions from './ToolbarActions';
import styles from './AttachmentPreview.module.scss';

type Props = {
  attachment: Attachment;
};

export default function AttachmentPreview({ attachment }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(ref.current?.clientWidth);

  const {
    pageNumber,
    handlePDFLoadSuccess,
    resetPageNumber,
    isExpandedPreview,
  } = usePreviewContext();

  const {
    zoom,
    rotate,
    handleRotateLeft,
    handleRotateRight,
    handleZoomIn,
    handleZoomOut,
    resetControls,
  } = useControls();

  const isPdf = isPDFType(attachment);
  const isImage = isImageType(attachment);
  const isPreviewError = !isPdf && !isImage;

  useEffect(() => {
    if (!isPdf) return;

    function handleResize() {
      setWidth(ref.current?.clientWidth);
    }

    const time = isExpandedPreview ? EXPANSION_EXIT_TIME : EXPANSION_ENTER_TIME;
    const timer = setTimeout(handleResize, time);

    return () => clearTimeout(timer);
  }, [isExpandedPreview, isPdf]);

  function handleDocumentLoadSuccess(document: { numPages: number }) {
    resetControls();
    resetPageNumber();

    handlePDFLoadSuccess(document);
  }

  return (
    <Stack ref={ref} className={styles.stack}>
      {isPdf && (
        <PreviewBase className={styles.previewPdf}>
          <PreviewPDF
            zoom={zoom}
            rotate={rotate}
            width={width}
            attachment={attachment}
            pageNumber={pageNumber}
            onDocumentLoadSuccess={handleDocumentLoadSuccess}
          />
        </PreviewBase>
      )}

      {isImage && (
        <PreviewBase>
          <PreviewIMG
            renderWithModal
            zoom={zoom}
            rotate={rotate}
            attachment={attachment}
            onLoad={resetControls}
            style={{
              width: `${zoom * 100}%`,
              transform: `rotate(${rotate}deg)`,
            }}
          />
        </PreviewBase>
      )}

      {isPreviewError && <PreviewError attachment={attachment} />}

      <ToolbarActions
        zoom={zoom}
        attachment={attachment}
        onRotateLeft={handleRotateLeft}
        onRotateRight={handleRotateRight}
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
        isPreviewError={isPreviewError}
      />
    </Stack>
  );
}
