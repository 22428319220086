import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function MessageChatCircleIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="message-chat-circle"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <path
        d="M8.33366 12.5L5.77095 15.0947C5.41348 15.4566 5.23475 15.6376 5.08112 15.6504C4.94784 15.6614 4.81734 15.6079 4.73029 15.5063C4.62996 15.3893 4.62996 15.1349 4.62996 14.6262V13.3263C4.62996 12.8699 4.25622 12.5397 3.80466 12.4736V12.4736C2.71178 12.3135 1.85347 11.4552 1.69339 10.3623C1.66699 10.1821 1.66699 9.96706 1.66699 9.537V5.66663C1.66699 4.26649 1.66699 3.56643 1.93948 3.03165C2.17916 2.56124 2.56161 2.17879 3.03202 1.93911C3.5668 1.66663 4.26686 1.66663 5.66699 1.66663H11.8337C13.2338 1.66663 13.9339 1.66663 14.4686 1.93911C14.939 2.17879 15.3215 2.56124 15.5612 3.03165C15.8337 3.56643 15.8337 4.26649 15.8337 5.66663V9.16663M15.8337 18.3333L14.02 17.0724C13.765 16.8951 13.6376 16.8065 13.4988 16.7437C13.3757 16.6879 13.2462 16.6473 13.1133 16.6228C12.9635 16.5952 12.8083 16.5952 12.4978 16.5952H11.0003C10.0669 16.5952 9.60019 16.5952 9.24368 16.4135C8.93007 16.2538 8.6751 15.9988 8.51532 15.6852C8.33366 15.3287 8.33366 14.862 8.33366 13.9285V11.8333C8.33366 10.8999 8.33366 10.4332 8.51532 10.0766C8.6751 9.76304 8.93007 9.50807 9.24368 9.34828C9.60019 9.16663 10.0669 9.16663 11.0003 9.16663H15.667C16.6004 9.16663 17.0671 9.16663 17.4236 9.34828C17.7372 9.50807 17.9922 9.76304 18.152 10.0766C18.3337 10.4332 18.3337 10.8999 18.3337 11.8333V14.0952C18.3337 14.8718 18.3337 15.2601 18.2068 15.5663C18.0376 15.9747 17.7132 16.2992 17.3048 16.4683V16.4683C16.5471 16.7822 15.8337 17.26 15.8337 18.0801V18.3333Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
