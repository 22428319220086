import type { Components } from '@mui/material';
import { menuItemClasses } from '@mui/material';

import type { ThemeConfig } from '..';

export function createMuiMenuItem({
  palette,
}: ThemeConfig): Components['MuiMenuItem'] {
  return {
    styleOverrides: {
      root: {
        padding: '8px',
        borderRadius: '8px',
        color: palette.text?.primary,

        transition: 'all 0.3s ease-in-out',

        [`&.${menuItemClasses.selected}`]: {
          background: palette.action?.selected,
        },

        ':hover, :focus': {
          background: `${palette.action?.hover} !important`,
        },
      },
    },
  };
}
