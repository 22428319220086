import type { ReactNode } from 'react';
import { useRef } from 'react';

import type { SxProps, Theme } from '@mui/material';
import { Box } from '@mui/material';
import type { TransitionStatus } from 'react-transition-group';
import { Transition } from 'react-transition-group';

type Props = {
  children: ReactNode;
  in: boolean;
  timeout?: number;
  sx?: SxProps<Theme>;
  className?: string;
};

const transitionStyles: Record<TransitionStatus, any> = {
  entering: {
    transform: 'rotate(-180deg)',
  },
  entered: {
    transform: 'rotate(-180deg)',
  },
  exiting: {
    transform: 'rotate(0)',
  },
  exited: {
    transform: 'rotate(0)',
  },
  unmounted: {
    transform: 'rotate(0)',
  },
};

export function Rotate({
  children,
  timeout = 300,
  sx,
  className,
  ...props
}: Props) {
  const nodeRef = useRef(null);

  return (
    <Transition nodeRef={nodeRef} timeout={timeout} {...props}>
      {(state) => (
        <Box
          className={className}
          ref={nodeRef}
          sx={{
            ...sx,
            display: 'grid',
            placeItems: 'center',
            transition: 'all 0.3s ease-in-out',
            ...transitionStyles[state],
          }}
        >
          {children}
        </Box>
      )}
    </Transition>
  );
}
