import { Card, Collapse, Skeleton as MuiSkeleton, Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from 'src/mui/_icons';
import { Box } from '@mui/system';

import { toCurrency } from 'src/libs/finbits/Money';

import styles from './TotalCard.module.scss';

type Props = {
  title: string;
  value: number;
  children?: React.ReactNode;
  bottom?: React.ReactNode;
  iconDirection?: 'up' | 'down';
  showMoreDetails?: boolean;
};

function Skeleton() {
  return (
    <Card variant="outlined" className={styles.totalCard}>
      <Stack padding={6} gap={2}>
        <MuiSkeleton variant="text" width={200} height={24} />
        <MuiSkeleton variant="rounded" width={150} height={30} />
      </Stack>
    </Card>
  );
}

function TotalCard({
  title,
  value,
  iconDirection,
  children,
  bottom,
  showMoreDetails,
}: Props) {
  return (
    <Card variant="outlined" className={styles.totalCard}>
      <Box padding={6}>
        <Stack direction="row" gap={2}>
          <Typography
            variant="text-md"
            fontWeight={600}
            className={styles.totalTitle}
          >
            {title}
          </Typography>
          {iconDirection === 'up' && (
            <ArrowCircleUpIcon className={styles.upIcon} />
          )}
          {iconDirection === 'down' && <ArrowCircleDownIcon />}
        </Stack>
        <Typography
          fontWeight={600}
          variant="text-xl"
          marginTop={2}
          className={styles.currency}
        >
          {toCurrency(value)}
        </Typography>
      </Box>

      <Collapse in={showMoreDetails}>
        <Box paddingX={6} paddingBottom={8} paddingTop={2}>
          {children}
        </Box>
        {bottom && (
          <Box paddingX={6} paddingY={4} borderTop={1} borderColor="grey.200">
            {bottom}
          </Box>
        )}
      </Collapse>
    </Card>
  );
}

TotalCard.Skeleton = Skeleton;

export default TotalCard;
