import { useEffect, useState } from 'react';

import { initChat, logOut, toggleChat, useLoadSDK } from 'src/libs/messageBird';

import { useIsAuthenticated } from '../finbits/Auth';
import { useProfile } from '../finbits/Organization/Users';

import type { MessageHandlerData } from './types';

export function useMessageBird() {
  const [hasMessage, setHasMessage] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const sdkStatus = useLoadSDK();

  const { user } = useProfile();

  useEffect(() => {
    function handler(message: MessageEvent<MessageHandlerData>) {
      if (message.data.type === 'SET_DOCUMENT_TITLE' && message.data.title) {
        setHasMessage(true);
      }
    }

    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  useEffect(() => {
    if (sdkStatus !== 'ready') return;
    if (isAuthenticated && user) {
      initChat(user);
    }

    return () => {
      if (isAuthenticated) logOut();
    };
  }, [user, isAuthenticated, sdkStatus]);

  return {
    hasMessage,
    toggleChat,
  };
}
