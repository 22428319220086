import { createContext, useContext, useState } from 'react';

import type {
  BBAutoPaymentContextProps,
  BBAutoPaymentProviderProps,
} from './types';

const BBAutoPaymentContext = createContext({} as BBAutoPaymentContextProps);

export default function BBAutoPaymentProvider({
  children,
}: BBAutoPaymentProviderProps) {
  const [certificateChain, setCertificateChain] = useState('');

  return (
    <BBAutoPaymentContext.Provider
      value={{
        certificateChain,
        setCertificateChain,
      }}
    >
      {children}
    </BBAutoPaymentContext.Provider>
  );
}

export function useBBAutoPaymentContext() {
  const context = useContext(BBAutoPaymentContext);

  if (!context) {
    throw new Error('To use context, you must provide a BBAutoPaymentProvider');
  }

  return context;
}
