export enum CashFlowGroupBy {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

export enum CashFlowDataKeys {
  CREDIT_AMOUNT = 'creditAmount',
  CREDIT_AMOUNT_TOTAL = 'creditAmountTotal',
  DEBIT_AMOUNT = 'debitAmount',
  DEBIT_AMOUNT_TOTAL = 'debitAmountTotal',
  BALANCE_AMOUNT = 'balanceAmount',
  CREDIT_AMOUNT_PROJECTED = 'creditAmountProjected',
  DEBIT_AMOUNT_PROJECTED = 'debitAmountProjected',
  BALANCE_AMOUNT_PROJECTED = 'balanceAmountProjected',
  BALANCE_AMOUNT_TOTAL = 'balanceAmountTotal',
  TOTAL_AMOUNT = 'totalAmount',
}
