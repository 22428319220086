import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import cn from 'classnames';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { HelperText } from 'src/features/open-banking/AutoPayment/AutoPaymentModal/HelperText';
import type { StepperContentProps } from 'src/features/open-banking/AutoPayment/VerticalStepper/types';

import styles from './GettingStartedStep.module.scss';
import type { GettingStartedType } from './types';
import { GettingStartedResolver } from './validations';

export default function GettingStartedStep({
  onNext,
  tradeName,
}: StepperContentProps) {
  const form = useForm<GettingStartedType>({
    resolver: zodResolver(GettingStartedResolver),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      filePasswordCertificate: false,
      servicePaymentAgreement: false,
    },
  });

  async function handleNextStep(event: React.MouseEvent) {
    const isValid = await form.trigger();

    if (isValid) onNext?.(event);
  }

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <Typography marginBottom={4}>
        Por favor, informe se sua empresa já possui os seguintes itens
        necessários para fazer a integração:
      </Typography>

      <FormProvider {...form}>
        <Box>
          <Box display="grid" gridTemplateColumns="1fr 1fr" gap={6}>
            <Card
              variant="outlined"
              className={cn(styles.card, {
                [styles.error]: form.formState.errors.servicePaymentAgreement,
              })}
            >
              <Stack gap={4}>
                <Typography color="grey.600" fontWeight="600">
                  Convênio PAG com o serviço 128 (pagamentos diversos)
                  contratados e ativos no Banco do Brasil
                </Typography>

                <Typography color="grey.600">
                  Você vai precisar do{' '}
                  <strong>número de identificação do aderente</strong> que
                  consta no contrato de serviços de pagamentos do {tradeName}.
                </Typography>

                <Typography color="grey.600">
                  <strong>Caso ainda não tenha contratado</strong>, entre em
                  contato com o seu Gerente de Cash ou agência do banco e faça a
                  contratação.
                </Typography>
              </Stack>

              <Box className={styles.formControlLabel}>
                <Controller
                  name="servicePaymentAgreement"
                  control={form.control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                          size="small"
                          title="Confirmação de contratação convênio"
                          className={styles.checkbox}
                        />
                      }
                      label="Tenho o convênio PAG e o serviço 128 contratados e ativos"
                      title="Confirmação de contratação convênio"
                    />
                  )}
                />
              </Box>
            </Card>

            <Card
              variant="outlined"
              className={cn(styles.card, {
                [styles.error]: form.formState.errors.filePasswordCertificate,
              })}
            >
              <Typography color="grey.600" fontWeight="600">
                Certificado digital A1
              </Typography>

              <Typography color="grey.600">
                Necessário para garantir a segurança dos pagamentos automáticos
                feitos no Banco do Brasil por meio da FinBits.
              </Typography>

              <Typography color="grey.600">
                Por favor, tenha em mãos:
              </Typography>

              <ul className={styles.list}>
                <li>
                  <strong>Arquivo</strong> do certificado, em formato{' '}
                  <strong>.pfx</strong>
                </li>
                <li>
                  <strong>Senha</strong> do certificado
                </li>
              </ul>

              <Box className={styles.formControlLabel}>
                <Controller
                  name="filePasswordCertificate"
                  control={form.control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                          size="small"
                          title="Arquivo e senha certificado"
                          className={styles.checkbox}
                        />
                      }
                      label="Tenho o arquivo .pfx e a senha do certificado"
                      title="Arquivo e senha certificado"
                    />
                  )}
                />
              </Box>
            </Card>
          </Box>

          <Box display="grid" gridTemplateColumns="1fr 1fr" gap={6}>
            <HelperText
              message={form.formState.errors.servicePaymentAgreement?.message}
            />

            <HelperText
              message={form.formState.errors.filePasswordCertificate?.message}
            />
          </Box>
        </Box>

        <Box display="flex" justifyContent="end">
          <Button
            size="medium"
            color="primary"
            variant="contained"
            onClick={handleNextStep}
          >
            Começar
          </Button>
        </Box>
      </FormProvider>
    </Box>
  );
}
