import { Stack } from '@mui/material';
import { Chip, Typography } from 'src/design-system/components';

import illustration from 'src/assets/escadas.svg';

import type { Category } from 'src/libs/finbits/Management/FinancialEntries/Categories/types';

import styles from './CashFlowBanner.module.scss';

type Props = {
  categories: Category[];
};

export default function CashFlowBanner({ categories }: Props) {
  if (
    categories.length === 0 ||
    categories.some((category) => category.includeInCashFlow === false)
  ) {
    return null;
  }

  return (
    <Stack className={styles.container} direction="row">
      <Stack gap={2} className={styles.textContainer}>
        <Stack direction="row" gap={3}>
          <Chip label="#FinDicas" className={styles.chip} square />
          <Typography fontWeight={600} className={styles.title}>
            Use as categorias para identificar lançamentos que devem ser
            desconsiderados no seu fluxo de caixa
          </Typography>
        </Stack>
        <Typography>
          Algumas transações rotineiras como transferências entre contas da
          empresa, aplicações, resgates ou rendimentos de investimentos podem
          distorcer a realidade do seu fluxo de caixa. Por isso, é importante
          criar categorias que identifiquem e desconsiderem estes lançamento
          nesse tipo de análise.
        </Typography>
        <Typography fontWeight={600}>
          Para fazer isso, ao criar ou editar uma categoria, marque a opção para
          desconsiderá-la na visualização do fluxo de caixa.
        </Typography>
      </Stack>
      <Stack className={styles.imgWrapper}>
        <img
          src={illustration}
          className={styles.illustration}
          alt="Identifique seus lançamentos"
        />
      </Stack>
    </Stack>
  );
}
