import { Upload } from 'antd';
import LoadingButton from '@mui/lab/LoadingButton';
import { UploadCloudIcon } from 'src/mui/_icons';
import type {
  RcFile,
  UploadRequestOption as UploadParams,
} from 'rc-upload/lib/interface';

import { snackbar } from 'src/mui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useCreateUpload } from 'src/libs/finbits/Management/Uploads';

import { validateBeforeUploadMulti } from 'src/features/attachments/UploadAttachments/Validators';

import { LoaderOverlay } from './LoaderOverlay';
import style from './CreateUploadButton.module.scss';

export default function CreateUploadButton() {
  const { organizationId, companyId } = useCompanyParams();
  const { createUpload, isLoading: isUploading } = useCreateUpload();

  function handleUpload({ file }: UploadParams) {
    createUpload(
      {
        organizationId,
        companyId,
        file: file as RcFile,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Arquivo(s) adicionado(s)!',
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao adicionar arquivo(s)!',
          });
        },
      }
    );
  }

  return (
    <>
      {isUploading && <LoaderOverlay />}
      <Upload
        multiple={true}
        showUploadList={false}
        data-testid="file-upload"
        customRequest={handleUpload}
        beforeUpload={(file: RcFile) => {
          return validateBeforeUploadMulti(file, []);
        }}
      >
        <LoadingButton
          className={style.loadingButton}
          size="small"
          title="Adicionar novo arquivo"
          aria-label="Upload novo arquivo"
          loading={isUploading}
          disabled={isUploading}
          loadingPosition="start"
          startIcon={<UploadCloudIcon />}
        >
          Enviar Arquivos
        </LoadingButton>
      </Upload>
    </>
  );
}
