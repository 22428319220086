import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert, AlertTitle } from 'src/design-system/components';
import { FormField, Input } from 'src/mui/_scss';

import { useCreateInterConsentments } from 'src/libs/finbits/Bank/InterConsentments';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import AutoPaymentCard from 'src/features/open-banking/AutoPayment/AutoPaymentModal/AutoPaymentCard';
import UploadDigitalCertificate from 'src/features/open-banking/AutoPayment/AutoPaymentModal/UploadDigitalCertificate';
import type { StepperContentProps } from 'src/features/open-banking/AutoPayment/VerticalStepper/types';

import styles from './CertificateStep.module.scss';
import { InterAutoPaymentResolver } from './validations';

export default function CertificateStep({
  onNext,
  onPrev,
  account,
}: StepperContentProps) {
  const [showError, setShowError] = useState(false);
  const { companyId, organizationId } = useCompanyParams();
  const { connectInterConsentments, isLoading } = useCreateInterConsentments();
  const form = useForm({
    resolver: zodResolver(InterAutoPaymentResolver),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      clientId: '',
      clientSecret: '',
      certCrt: [],
      certKey: [],
    },
  });

  async function onSubmit(event: React.MouseEvent) {
    const isValid = await form.trigger();
    if (isValid) {
      const { clientId, clientSecret, certCrt, certKey } = form.getValues();

      if (!certCrt || !certKey) {
        return;
      }

      connectInterConsentments(
        {
          organizationId,
          companyId,
          accountId: account!!.id,
          clientId,
          clientSecret,
          certCrt: certCrt[0],
          certKey: certKey[0],
        },
        {
          onSuccess: () => {
            onNext?.(event);
          },

          onError: () => {
            setShowError(true);
            return;
          },
        }
      );
    }
  }

  return (
    <>
      {showError && (
        <Alert severity="error" variant="standard" className={styles.errorCard}>
          <AlertTitle>
            A conexão não pode ser concluída com os dados informados.
          </AlertTitle>
          Por favor, verifique se preencheu e anexou corretamente as credenciais
          solicitadas e tente novamente.
        </Alert>
      )}

      <Box className={styles.cards}>
        <FormProvider {...form}>
          <AutoPaymentCard>
            <Box display="flex" flexDirection="column" width="100%" gap="32px">
              <FormField name="clientId" label="Client ID">
                {(field) => (
                  <Input {...field} placeholder="Informe o Client ID" />
                )}
              </FormField>

              <FormField name="clientSecret" label="Client Secret">
                {(field) => (
                  <Input {...field} placeholder="Informe o Client Secret" />
                )}
              </FormField>
            </Box>
          </AutoPaymentCard>

          <AutoPaymentCard>
            <Box display="flex" flexDirection="column" width="100%" gap="32px">
              <UploadDigitalCertificate
                name="certKey"
                label="Arquivo da Chave Privada:"
                acceptFiles={{ 'application/x-pem-file': ['.key'] }}
              />

              <UploadDigitalCertificate
                name="certCrt"
                label="Arquivo do Certificado:"
                acceptFiles={{ 'application/x-x509-ca-cert': ['.ctr'] }}
              />
            </Box>
          </AutoPaymentCard>
        </FormProvider>
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        width="100%"
        marginTop="48px"
        gap={3}
      >
        <Button size="medium" variant="outlined" onClick={onPrev}>
          Voltar
        </Button>

        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={isLoading}
        >
          Concluir conexão
        </Button>
      </Box>
    </>
  );
}
