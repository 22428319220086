import { Stack } from '@mui/material';
import type {
  GridApi,
  GridRowModel,
  GridUpdateAction,
} from '@mui/x-data-grid-premium';
import CountUp from 'react-countup';
import { Typography } from 'src/design-system/components';

import type { BillPayable } from 'src/libs/finbits/Bills/types';

import DeleteBillsButton from 'src/features/bills-to-pay/DeleteBillsButton';
import MarkDoneBillButton from 'src/features/bills-to-pay/MarkDoneBillButton';

import PaymentFlowActions from './PaymentFlowActions/PaymentFlowActions';

type Props = {
  selecteds: GridRowModel<BillPayable>[];
  apiRef: GridApi;
};

export default function ToolbarSelectedActions({ selecteds, apiRef }: Props) {
  function handleSuccess(selecteds?: BillPayable[]) {
    if (!selecteds) return;

    const rowsToRemove: Array<{ id: string; _action: GridUpdateAction }> =
      selecteds.map((s) => ({
        id: s.id,
        _action: 'delete',
      }));

    apiRef.updateRows(rowsToRemove);
  }

  function handleDeleteSuccess() {
    handleSuccess(selecteds);
  }

  const totalDebit = selecteds.reduce<number>((acc, row) => {
    acc = acc + row.amount;

    return acc;
  }, 0);

  return (
    <>
      <DeleteBillsButton bills={selecteds} onSuccess={handleDeleteSuccess} />
      <MarkDoneBillButton bills={selecteds} />
      <PaymentFlowActions selecteds={selecteds} onSuccess={handleSuccess} />

      <Stack
        direction="row"
        gap="16px"
        justifyContent="flex-end"
        flexGrow="1"
        alignItems="center"
      >
        <Typography>
          <CountUp
            prefix="R$ - "
            end={totalDebit / 100}
            decimals={2}
            decimal=","
            separator="."
            duration={0.5}
            preserveValue
            useEasing={false}
            delay={0}
          />
        </Typography>
      </Stack>
    </>
  );
}
