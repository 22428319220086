import throttle from 'lodash/throttle';
import type { QueryClient, UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import t from 'zod';

import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';
import { format } from 'src/libs/finbits/Date';
import { invalidateAttachmentsQueries } from 'src/libs/finbits/Management/Attachments';
import { invalidateEntriesToLinkNotaFiscalCache } from 'src/libs/finbits/Management/Entries/AvailableToLinkNotaFiscal';
import type {
  FinancialDoneEntry,
  FinancialEntry,
} from 'src/libs/finbits/Management/FinancialEntries/types';
import { FinancialEntryDecoder } from 'src/libs/finbits/Management/FinancialEntries/types';
import { invalidateConciliationSuggestionsQueries } from 'src/libs/finbits/Management/FinancialStatements/ConciliationSuggestions';
import {
  invalidateEntriesQueries,
  invalidateFinancialStatementEntriesQueries,
} from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { invalidateScheduledEntriesQueries } from 'src/libs/finbits/Management/ScheduledEntries';
import { invalidateNotasFiscaisQueries } from 'src/libs/finbits/NotaFiscal/NotaFiscal';
import { FIVE_SECONDS_IN_MS } from 'src/libs/finbits/Time';

import type {
  CreateFinancialFromReceivableParams,
  CreateFinancialFromReceivablesParams,
  DEPRECATED_RecurringReceivablePostParams,
  Receivable,
  ReceivablePatchParams,
  ReceivablePostParams,
  ReceivableRecurrencesDeleteParams,
  ReceivablesGetAllParams,
  ReceivablesGetParams,
  RecurringReceivable,
  RecurringReceivablePostParams,
} from './types';
import { ReceivableDecoder, RecurringReceivableDecoder } from './types';

function receivablesCacheKey(
  params: ReceivablesGetAllParams | ReceivablesGetParams
) {
  return ['receivables', params];
}

function invalidateReceivablesQueriesCall(queryClient: QueryClient) {
  queryClient.invalidateQueries(['receivables']);
}

export const invalidateReceivablesQueries = throttle(
  invalidateReceivablesQueriesCall,
  FIVE_SECONDS_IN_MS
);

async function postReceivable({
  organizationId,
  companyId,
  ...params
}: ReceivablePostParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/receivables`,
    params
  );

  return decodeResponse<Receivable>(response, ReceivableDecoder);
}

async function DEPRECATED_postRecurringReceivable({
  companyId,
  organizationId,
  relevantDate,
  ...params
}: DEPRECATED_RecurringReceivablePostParams) {
  const formattedRelevantDate = relevantDate
    ? format(relevantDate, 'yyyy-MM-dd')
    : undefined;

  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/recurring_receivables`,
    { ...params, relevantDate: formattedRelevantDate }
  );

  return decodeResponse<RecurringReceivable>(
    response,
    RecurringReceivableDecoder
  );
}

export function useCreateReceivable() {
  const queryClient = useQueryClient();

  const { mutate: createReceivable, ...rest } = useMutation<
    Receivable,
    ApiError<ApiErrorForm>,
    ReceivablePostParams
  >(postReceivable, {
    onSuccess: () => {
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
      invalidateScheduledEntriesQueries(queryClient);
      invalidateAttachmentsQueries(queryClient);
      invalidateReceivablesQueries(queryClient);
      invalidateEntriesToLinkNotaFiscalCache(queryClient);
      invalidateNotasFiscaisQueries(queryClient);
    },
  });

  return { createReceivable, ...rest };
}

export function DEPRECATED_useCreateRecurringReceivable() {
  const queryClient = useQueryClient();

  const { mutate: createRecurringReceivable, ...rest } = useMutation<
    RecurringReceivable,
    ApiError<ApiErrorForm>,
    DEPRECATED_RecurringReceivablePostParams
  >(DEPRECATED_postRecurringReceivable, {
    onSuccess: () => {
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
      invalidateScheduledEntriesQueries(queryClient);
      invalidateAttachmentsQueries(queryClient);
      invalidateReceivablesQueries(queryClient);
      invalidateEntriesToLinkNotaFiscalCache(queryClient);
    },
  });

  return {
    createRecurringReceivable,
    ...rest,
  };
}

async function indexReceivables({
  organizationId,
  companyId,
  ...params
}: ReceivablesGetAllParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/receivables`,
    { params }
  );

  return decodeResponse<Receivable[]>(response, t.array(ReceivableDecoder));
}

type QueryOptions = UseQueryOptions<Receivable[], ApiError>;

export function useGetReceivables(
  params: ReceivablesGetAllParams,
  { enabled = true, ...queryConfig }: QueryOptions = {}
) {
  const { data, ...rest } = useQuery<Receivable[], ApiError>({
    enabled: !!params.organizationId && !!params.companyId && enabled,
    queryFn: () => indexReceivables({ ...params, order: 'asc' }),
    queryKey: receivablesCacheKey(params),
    ...queryConfig,
  });

  return { receivables: withEmptyArrayDefault(data), ...rest };
}

async function showReceivable({
  organizationId,
  companyId,
  receivableId,
}: ReceivablesGetParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/receivables/${receivableId}`
  );

  return decodeResponse<Receivable>(response, ReceivableDecoder);
}

export function useGetReceivable(params: ReceivablesGetParams) {
  const { data, ...rest } = useQuery<Receivable, ApiError>({
    enabled:
      !!params.organizationId && !!params.companyId && !!params.receivableId,
    queryFn: () => showReceivable(params),
    queryKey: receivablesCacheKey(params),
  });

  return { receivable: data, ...rest };
}

async function patchReceivable({
  receivableId,
  organizationId,
  companyId,
  ...params
}: ReceivablePatchParams) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/receivables/${receivableId}`,
    params
  );

  return decodeResponse<Receivable>(response, ReceivableDecoder);
}

export function useUpdateReceivable() {
  const queryClient = useQueryClient();
  const { mutate: updateReceivable, ...rest } = useMutation<
    Receivable,
    ApiError,
    ReceivablePatchParams
  >(patchReceivable, {
    onSuccess: () => {
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
      invalidateScheduledEntriesQueries(queryClient);
      invalidateAttachmentsQueries(queryClient);
      invalidateReceivablesQueries(queryClient);
      invalidateEntriesToLinkNotaFiscalCache(queryClient);
      invalidateEntriesQueries(queryClient);
    },
  });

  return { updateReceivable, ...rest };
}

async function deleteReceivable({
  organizationId,
  companyId,
  receivableId,
}: ReceivablesGetParams) {
  const response = await authenticatedAPI.delete(
    `/organizations/${organizationId}/companies/${companyId}/receivables/${receivableId}`
  );

  return response.data;
}

export function useDeleteReceivable() {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation<
    string,
    ApiError,
    ReceivablesGetParams
  >(deleteReceivable, {
    onSuccess: () => {
      invalidateReceivablesQueries(queryClient);
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
      invalidateScheduledEntriesQueries(queryClient);
      invalidateAttachmentsQueries(queryClient);
      invalidateEntriesQueries(queryClient);
    },
  });

  return { deleteReceivable: mutate, ...rest };
}

async function postCreateFinancialFromReceivable({
  companyId,
  organizationId,
  receivableId,
  ...params
}: CreateFinancialFromReceivableParams) {
  const response = await authenticatedAPI.post(
    `organizations/${organizationId}/companies/${companyId}/receivables/${receivableId}/financial_entries`,
    params
  );

  return decodeResponse<FinancialEntry>(response, FinancialEntryDecoder);
}

async function postCreateFinancialFromReceivables({
  companyId,
  organizationId,
  ...params
}: CreateFinancialFromReceivablesParams) {
  const response = await authenticatedAPI.post(
    `organizations/${organizationId}/companies/${companyId}/receivables/financial_entries_in_batch`,
    params
  );

  return response.data;
}

export function useCreateFinancialFromReceivable() {
  const queryClient = useQueryClient();

  const { mutate: createFinancialFromReceivable, ...rest } = useMutation<
    FinancialEntry,
    ApiError,
    CreateFinancialFromReceivableParams
  >(postCreateFinancialFromReceivable, {
    onSuccess: () => {
      invalidateReceivablesQueries(queryClient);
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
      invalidateEntriesQueries(queryClient);
      invalidateScheduledEntriesQueries(queryClient);
    },
  });

  return { createFinancialFromReceivable, ...rest };
}

export function useCreateFinancialFromReceivables() {
  const queryClient = useQueryClient();

  const { mutate: createFinancialFromReceivables, ...rest } = useMutation<
    FinancialDoneEntry,
    ApiError,
    CreateFinancialFromReceivablesParams
  >(postCreateFinancialFromReceivables, {
    onSuccess: () => {
      invalidateReceivablesQueries(queryClient);
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
      invalidateEntriesQueries(queryClient);
      invalidateScheduledEntriesQueries(queryClient);
    },
  });

  return { createFinancialFromReceivables, ...rest };
}

async function deleteReceivableRecurrences({
  organizationId,
  companyId,
  receivableId,
}: ReceivableRecurrencesDeleteParams) {
  const response = await authenticatedAPI.delete(
    `/organizations/${organizationId}/companies/${companyId}/recurring_receivables/${receivableId}`
  );

  return response.data;
}

export function useDeleteReceivableRecurrences() {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation<
    string,
    ApiError,
    ReceivableRecurrencesDeleteParams
  >(deleteReceivableRecurrences, {
    onSuccess: () => {
      invalidateReceivablesQueries(queryClient);
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
      invalidateScheduledEntriesQueries(queryClient);
      invalidateAttachmentsQueries(queryClient);
    },
  });

  return { deleteReceivableRecurrences: mutate, ...rest };
}

async function postRecurringReceivable({
  companyId,
  organizationId,
  ...params
}: RecurringReceivablePostParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/recurring_receivables/new`,
    params
  );

  return decodeResponse<RecurringReceivable>(
    response,
    RecurringReceivableDecoder
  );
}

export function useCreateRecurringReceivable() {
  const queryClient = useQueryClient();

  const { mutate: createRecurringReceivable, ...rest } = useMutation<
    RecurringReceivable,
    ApiError<ApiErrorForm>,
    RecurringReceivablePostParams
  >(postRecurringReceivable, {
    onSuccess: () => {
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
      invalidateScheduledEntriesQueries(queryClient);
      invalidateAttachmentsQueries(queryClient);
      invalidateReceivablesQueries(queryClient);
      invalidateEntriesToLinkNotaFiscalCache(queryClient);
    },
  });

  return {
    createRecurringReceivable,
    ...rest,
  };
}
