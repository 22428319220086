import { useEffect, useState } from 'react';

import type {
  DateRange,
  DateRangePickerSlotsComponentsProps,
} from '@mui/x-date-pickers-pro';
import {
  DateRangePicker,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import { CalendarIcon } from 'src/mui/_icons';

import {
  addMonths,
  addWeeks,
  endOfMonth,
  endOfWeek,
  endOfYear,
  isBefore,
  isValid,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subMonths,
  subWeeks,
} from 'src/libs/finbits/Date';

type Props = {
  selectedDateRange: [Date, Date];
  setSelectedDateRange: (dates: [Date, Date]) => void;
  minDate?: null | Date;
};

function isRangeValid(dateRange: DateRange<Date>) {
  const [startDate, endDate] = dateRange;

  return isValid(startDate) && isValid(endDate);
}

export default function RangePicker({
  selectedDateRange,
  setSelectedDateRange,
  minDate,
}: Props) {
  const [value, setValue] = useState<DateRange<Date>>(selectedDateRange);

  useEffect(() => {
    setValue(selectedDateRange);
  }, [setValue, selectedDateRange]);

  function handleValueChange(dateRange: DateRange<Date>) {
    if (isRangeValid(dateRange)) {
      setValue(dateRange);
    }
  }

  const slotProps: DateRangePickerSlotsComponentsProps<Date> = {
    popper: { placement: 'bottom-start' },
    textField: {
      variant: 'standard',
      InputProps: { startAdornment: <CalendarIcon /> },
      sx: { width: '230px' },
    },
    shortcuts: {
      items: [
        {
          label: 'Ano atual',
          getValue: () => {
            const today = new Date();
            return [startOfYear(today), endOfYear(today)];
          },
        },
        {
          label: 'Semana atual',
          getValue: () => {
            const today = new Date();
            return [startOfWeek(today), endOfWeek(today)];
          },
        },
        {
          label: 'Semana seguinte',
          getValue: () => {
            const base = addWeeks(selectedDateRange[0], 1);
            return [startOfWeek(base), endOfWeek(base)];
          },
        },
        {
          label: 'Semana anterior',
          getValue: () => {
            const base = subWeeks(selectedDateRange[0], 1);
            return [startOfWeek(base), endOfWeek(base)];
          },
        },
        {
          label: 'Mês atual',
          getValue: () => {
            const today = new Date();
            return [startOfMonth(today), endOfMonth(today)];
          },
        },
        {
          label: 'Mês seguinte',
          getValue: () => {
            const base = addMonths(selectedDateRange[0], 1);
            return [startOfMonth(base), endOfMonth(base)];
          },
        },
        {
          label: 'Mês anterior',
          getValue: () => {
            const base = subMonths(selectedDateRange[0], 1);
            return [startOfMonth(base), endOfMonth(base)];
          },
        },
      ],
    },
  };

  function dateBeforeMinDate(date: Date) {
    if (!minDate) return false;

    return isBefore(date, minDate);
  }

  function handleDateChange(dateRange: DateRange<Date>) {
    if (isRangeValid(dateRange)) {
      const [startDate, endDate] = dateRange as [Date, Date];

      if (dateBeforeMinDate(startDate)) {
        const rangeDate: [Date, Date] = [minDate!, endDate];

        setValue(rangeDate);
        setSelectedDateRange(rangeDate);
        return;
      }

      setSelectedDateRange([startDate, endDate]);
    }
  }

  return (
    <DateRangePicker
      slots={{ field: SingleInputDateRangeField }}
      value={value}
      onChange={handleValueChange}
      onAccept={handleDateChange}
      format="dd/MM/yyyy"
      slotProps={slotProps}
      formatDensity="dense"
      shouldDisableDate={dateBeforeMinDate}
    />
  );
}
