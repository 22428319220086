import { useEffect } from 'react';

import { TabContext, TabPanel } from '@mui/lab';
import { useFormContext } from 'react-hook-form';

import type { BillPayable } from 'src/libs/finbits/Bills/types';
import type { BillPayableOptional } from 'src/libs/finbits/Management/NewInboxItems/types';
import { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import BillFields from 'src/features/bills-to-pay/BillModal/BillFields';
import { useTabPanelsContext } from 'src/features/entries/Modals/EntryModal/Tabs/TabPanels/TabPanelsProvider';
import { Tab } from 'src/features/entries/Modals/EntryModal/Tabs/TabPanels/TabPanelsProvider/types';
import PreviewProvider from 'src/features/entries/Modals/EntryModal/Preview/PreviewProvider';
import {
  ClassificationsTab,
  InformationTab,
  RecurrenceTab,
} from 'src/features/entries/Modals/EntryModal/Tabs/TabPanels';

import styles from './BillContent.module.scss';

type Props = {
  bill?: BillPayable | BillPayableOptional;
};

export default function BillContent({ bill }: Props) {
  const { watch, resetField } = useFormContext();
  const { selectedBillTab } = useTabPanelsContext();
  const date = watch('date');

  useEffect(() => {
    const FIELDS_TO_RESET = [
      'endDate',
      'quantity',
      'frequencyInterval',
    ] as const;
    FIELDS_TO_RESET.forEach((fieldName: (typeof FIELDS_TO_RESET)[number]) => {
      resetField(fieldName);
    });
  }, [date, resetField]);

  return (
    <TabContext value={selectedBillTab}>
      <PreviewProvider>
        <InformationTab entry={bill} entryType={EntryType.BILL}>
          <BillFields />
        </InformationTab>
      </PreviewProvider>
      <TabPanel
        className={styles.tabPanelClassifications}
        value={Tab.CLASSIFICATIONS}
      >
        <ClassificationsTab />
      </TabPanel>
      <TabPanel value={Tab.RECURRENCE}>
        <RecurrenceTab />
      </TabPanel>
    </TabContext>
  );
}
