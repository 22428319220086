import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import cn from 'classnames';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { DownloadCloudIcon } from 'src/mui/_icons';
import { Link } from 'src/mui/_scss';

import { useAutoDownloadFile } from 'src/libs/finbits/File/useAutoDownloadFile';

import { HelperText } from 'src/features/open-banking/AutoPayment/AutoPaymentModal/HelperText';
import { useBBAutoPaymentContext } from 'src/features/open-banking/AutoPayment/BBAutoPayment/BBAutoPaymentProvider';
import type { StepperContentProps } from 'src/features/open-banking/AutoPayment/VerticalStepper/types';

import styles from './CertificateChainDownloaderStep.module.scss';
import type { CertificateChainDownloaderType } from './types';
import { CertificateChainDownloaderResolver } from './validations';

export default function CertificateChainDownloaderStep({
  onNext,
  onPrev,
  tradeName,
}: StepperContentProps) {
  const { certificateChain } = useBBAutoPaymentContext();
  const autoDownloadFile = useAutoDownloadFile();

  const form = useForm<CertificateChainDownloaderType>({
    resolver: zodResolver(CertificateChainDownloaderResolver),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      confirmDownload: false,
    },
  });

  async function handleNextStep(event: React.MouseEvent) {
    const isValid = await form.trigger();

    if (isValid) onNext?.(event);
  }

  function handleDownloadFile() {
    autoDownloadFile({
      file: certificateChain,
      filename: 'cadeia-de-certificados.pem',
      type: 'application/octet-stream',
    });
  }

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <Typography marginBottom={4}>
        Com base no certificado enviado, geramos uma nova cadeia de
        certificados. <br />{' '}
        <strong>
          Ela será necessária para seguir com a sua solicitação junto ao{' '}
          {tradeName}
        </strong>
        , conforme será orientado na próxima etapa.
      </Typography>

      <Card variant="outlined" className={styles.downloadCard}>
        <DownloadCloudIcon className={styles.icon} />

        <Box>
          <Typography color="grey.600" fontWeight="600" marginBottom={3}>
            O download do arquivo foi iniciado automaticamente.
          </Typography>

          <Typography color="grey.600" className={styles.downloadButton}>
            Não iniciou?{' '}
            {!!certificateChain && (
              <Link onClick={handleDownloadFile} role="button">
                Baixe o arquivo aqui
              </Link>
            )}
          </Typography>
        </Box>
      </Card>

      <Box>
        <FormProvider {...form}>
          <Card
            variant="outlined"
            className={cn(styles.card, {
              [styles.error]: form.formState.errors.confirmDownload,
            })}
          >
            <Box className={styles.formControlLabel}>
              <Controller
                name="confirmDownload"
                control={form.control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        size="small"
                        title="Confirmação de download da cadeia de certificados"
                        className={styles.checkbox}
                      />
                    }
                    label="Baixei o arquivo e recebi a cadeia de certificados gerada pela FinBits."
                    title="Confirmação de download da cadeia de certificados"
                  />
                )}
              />

              <Typography color="grey.600" marginLeft={6}>
                Por motivo de segurança, caso você feche o app nesta etapa e
                precise baixar este arquivo novamente, será necessário retornar
                ao passo anterior para reenviar o arquivo e senha do seu
                certificado A1.
              </Typography>
            </Box>
          </Card>

          <HelperText
            message={form.formState.errors.confirmDownload?.message}
          />
        </FormProvider>
      </Box>

      <Box display="flex" justifyContent="end" gap={2}>
        <Button size="medium" variant="outlined" onClick={onPrev}>
          Voltar
        </Button>

        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={handleNextStep}
        >
          Continuar
        </Button>
      </Box>
    </Box>
  );
}
