import { forwardRef, useState } from 'react';

import type { TextFieldProps } from '@mui/material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { EyeIcon, EyeOffIcon } from 'src/mui/_icons';

type Props = TextFieldProps & {
  mask?: string[];
};

const TextFieldPassword = forwardRef<HTMLElement, Props>(
  function TextFieldPassword(props, ref) {
    const [showPassword, setShowPassword] = useState(false);
    function handleClickShowPassword() {
      setShowPassword((show) => !show);
    }

    function handleMouseDownPassword(
      event: React.MouseEvent<HTMLButtonElement>
    ) {
      event.preventDefault();
    }

    return (
      <TextField
        {...props}
        type={showPassword ? 'text' : 'password'}
        data-testid="text-field-password"
        inputRef={ref}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <EyeOffIcon /> : <EyeIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

export default TextFieldPassword;
