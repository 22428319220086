import { useState } from 'react';

import { Button, Col, List, Row, Space, Tooltip, Typography } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { GridIcon } from 'src/mui/_icons';

import { PageWrapper, Title, useOpenPortal } from 'src/ui';

import { useCategories } from 'src/libs/finbits/Management/FinancialEntries/Categories';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import CategoryItem from 'src/features/categories/CategoryItem';
import ImportCategoriesModal from 'src/features/categories/ImportCategoriesModal';
import CreateCategoryModal from 'src/features/categories/CreateCategoryModal/CreateCategoryModal';
import CashFlowBanner from 'src/features/categories/CashFlowBanner/CashFlowBanner';

import styles from './Categories.module.less';

function Categories() {
  const openPortal = useOpenPortal();

  const [isImportCategoriesModalVisible, setIsImportCategoriesModalVisible] =
    useState(false);
  const { organizationId, companyId } = useCompanyParams();
  const { categories, isLoading } = useCategories({
    companyId,
    organizationId,
  });

  function handleOpenCreateCategoryModal() {
    openPortal(CreateCategoryModal);
  }

  return (
    <PageWrapper>
      {isImportCategoriesModalVisible && (
        <ImportCategoriesModal
          onClose={() => setIsImportCategoriesModalVisible(false)}
        />
      )}

      <Row gutter={[10, 32]} align="top" justify="space-between">
        <Col span={24}>
          <Title icon={<GridIcon />}>Categorias</Title>
        </Col>

        <Col>
          <Typography.Text className={styles.paragraph}>
            Aqui você gerencia as categorias que vai usar para contextualizar os
            lançamentos.
          </Typography.Text>
        </Col>

        <Col>
          <Space direction="horizontal" size="small">
            <WithAllowedPermission
              permissions={{
                resource: 'categories',
                action: 'batch_create',
              }}
            >
              <Tooltip
                placement="bottom"
                title="Importar planilha de categorias"
              >
                <Button
                  type="default"
                  aria-label="Importar planilha de categorias"
                  onClick={() => setIsImportCategoriesModalVisible(true)}
                  icon={<UploadOutlined />}
                />
              </Tooltip>
            </WithAllowedPermission>
            <WithAllowedPermission
              permissions={{
                resource: 'categories',
                action: 'create',
              }}
            >
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleOpenCreateCategoryModal}
              >
                Nova categoria
              </Button>
            </WithAllowedPermission>
          </Space>
        </Col>
        <Col span={24}>
          <CashFlowBanner categories={categories} />

          <div className={styles.list}>
            <List
              size="large"
              header={<div>Categorias de Entrada</div>}
              dataSource={categories?.filter(
                (category) => category.type === BalanceType.CREDIT
              )}
              renderItem={(category) => (
                <CategoryItem category={category} key={category.id} />
              )}
              loading={isLoading}
            />
          </div>
          <div className={styles.list}>
            <List
              size="large"
              header={<div>Categorias de Saída</div>}
              dataSource={categories?.filter(
                (category) => category.type === BalanceType.DEBIT
              )}
              renderItem={(category) => (
                <CategoryItem category={category} key={category.id} />
              )}
              loading={isLoading}
            />
          </div>
        </Col>
      </Row>
    </PageWrapper>
  );
}

export default Categories;
