import classNames from 'classnames';
import { amountColumn } from 'src/mui/_scss';
import { Typography } from 'src/design-system/components';
import type { GridCellParams, GridColDef } from '@mui/x-data-grid-premium';

import type { OverviewCashFlow } from 'src/libs/finbits/Overview/types';
import { format } from 'src/libs/finbits/Date';
import {
  CashFlowDataKeys,
  CashFlowGroupBy,
} from 'src/libs/finbits/Overview/enum';
import {
  overviewGroupedByDictionary,
  overviewTableDictionary,
} from 'src/libs/finbits/Overview/translations';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import styles from './formatOverviewTableData.module.scss';

type GenerateKeyParams = {
  date: string;
  groupedBy?: CashFlowGroupBy;
};
export function createKeyBy({
  date,
  groupedBy = CashFlowGroupBy.MONTH,
}: GenerateKeyParams) {
  if (groupedBy === CashFlowGroupBy.DAY) {
    return format(date, 'MM-dd-yyyy');
  }

  if (groupedBy === CashFlowGroupBy.YEAR) {
    return format(date, 'yyyy');
  }

  return format(date, 'MMMyyyy');
}

export function createHeaderName({
  date,
  groupedBy = CashFlowGroupBy.MONTH,
}: GenerateKeyParams) {
  if (groupedBy === CashFlowGroupBy.DAY) {
    return format(date, 'dd/MM');
  }

  if (groupedBy === CashFlowGroupBy.YEAR) {
    return format(date, 'yyyy');
  }

  return format(date, 'MMM/yy');
}

function buildHeaderRow(
  key: CashFlowDataKeys,
  isOverviewProjectedEnabled = false
) {
  return {
    amount: isOverviewProjectedEnabled
      ? overviewTableDictionary[key]
      : // @ts-ignore-next-line - remove once feature flag is removed
        overviewGroupedByDictionary[key],
    id: `row-${key}`,
  };
}

type BuildRowArgs = {
  data: OverviewCashFlow[];
  key: CashFlowDataKeys;
  groupedBy: CashFlowGroupBy;
  isRowEmpty?: boolean;
  isOverviewProjectedEnabled?: boolean;
};
function buildRow({
  data,
  key,
  groupedBy,
  isRowEmpty = false,
  isOverviewProjectedEnabled,
}: BuildRowArgs) {
  return data.reduce((acc, item) => {
    return {
      ...acc,
      [createKeyBy({ date: item.date, groupedBy })]: isRowEmpty
        ? null
        : item[key as keyof OverviewCashFlow],
    };
  }, buildHeaderRow(key, isOverviewProjectedEnabled));
}

type OverviewTableDataParams = {
  data: OverviewCashFlow[];
  groupedBy?: CashFlowGroupBy;
  isOverviewProjectedEnabled?: boolean;
};
export function generateRows({
  data,
  groupedBy = CashFlowGroupBy.MONTH,
  isOverviewProjectedEnabled,
}: OverviewTableDataParams) {
  const creditTotal = buildRow({
    data,
    groupedBy,
    key: CashFlowDataKeys.CREDIT_AMOUNT_TOTAL,
    isOverviewProjectedEnabled,
  });
  const creditRealized = buildRow({
    data,
    groupedBy,
    key: CashFlowDataKeys.CREDIT_AMOUNT,
    isOverviewProjectedEnabled,
  });
  const creditProjected = buildRow({
    data,
    groupedBy,
    key: CashFlowDataKeys.CREDIT_AMOUNT_PROJECTED,
    isOverviewProjectedEnabled,
  });

  const debitTotal = buildRow({
    data,
    groupedBy,
    key: CashFlowDataKeys.DEBIT_AMOUNT_TOTAL,
    isOverviewProjectedEnabled,
  });
  const debitRealized = buildRow({
    data,
    groupedBy,
    key: CashFlowDataKeys.DEBIT_AMOUNT,
    isOverviewProjectedEnabled,
  });
  const debitProjected = buildRow({
    data,
    groupedBy,
    key: CashFlowDataKeys.DEBIT_AMOUNT_PROJECTED,
    isOverviewProjectedEnabled,
  });

  const subtotal = buildRow({
    data,
    groupedBy,
    key: CashFlowDataKeys.TOTAL_AMOUNT,
    isOverviewProjectedEnabled,
  });

  const balanceEmpty = buildRow({
    data,
    groupedBy,
    key: CashFlowDataKeys.BALANCE_AMOUNT_TOTAL,
    isRowEmpty: true,
    isOverviewProjectedEnabled,
  });
  const balanceRelized = buildRow({
    data,
    groupedBy,
    key: CashFlowDataKeys.BALANCE_AMOUNT,
    isOverviewProjectedEnabled,
  });
  const balanceProjected = buildRow({
    data,
    groupedBy,
    key: CashFlowDataKeys.BALANCE_AMOUNT_PROJECTED,
    isOverviewProjectedEnabled,
  });

  if (isOverviewProjectedEnabled) {
    return [
      creditTotal,
      creditRealized,
      creditProjected,
      debitTotal,
      debitRealized,
      debitProjected,
      subtotal,
      balanceEmpty,
      balanceRelized,
      balanceProjected,
    ];
  }
  return [creditRealized, debitRealized, balanceRelized];
}

export function generateEmptyRows() {
  const credit = buildHeaderRow(CashFlowDataKeys.CREDIT_AMOUNT);
  const debit = buildHeaderRow(CashFlowDataKeys.DEBIT_AMOUNT);
  const balance = buildHeaderRow(CashFlowDataKeys.BALANCE_AMOUNT);

  return [credit, debit, balance];
}

const TABLE_SECTIONS = ['Recebimentos', 'Pagamentos', 'Saldo', 'Subtotal'];
function buildHeaderColumn(): GridColDef {
  return {
    width: 140,
    headerName: '',
    field: 'amount',
    disableColumnMenu: true,
    disableReorder: true,
    cellClassName: styles.header,
    renderCell: ({ value }) => (
      <Typography
        variant="text-sm"
        fontWeight={TABLE_SECTIONS.includes(value) ? '700' : 'initial'}
      >
        {value}
      </Typography>
    ),
  };
}

function getBalanceNegativeClass({
  id,
  value = 0,
}: GridCellParams<any, number>) {
  const isBalanceCell = [
    `row-${CashFlowDataKeys.BALANCE_AMOUNT}`,
    `row-${CashFlowDataKeys.BALANCE_AMOUNT_PROJECTED}`,
  ].includes(id as string);

  return classNames(styles.balanceCell, {
    [styles.negativeBalance]: value < 0 && isBalanceCell,
  });
}

function buildColumn({
  date,
  groupedBy,
}: {
  date: string;
  groupedBy: CashFlowGroupBy;
}) {
  return amountColumn({
    columnProps: {
      minWidth: 130,
      field: createKeyBy({ date, groupedBy }),
      headerName: createHeaderName({ date, groupedBy }),
      disableColumnMenu: true,
      disableReorder: true,
      headerClassName: styles.header,
      cellClassName: getBalanceNegativeClass,
      flex: 1,
    },
    type: BalanceType.CREDIT,
  });
}

export function generateColumns({
  data,
  groupedBy = CashFlowGroupBy.MONTH,
}: OverviewTableDataParams) {
  const columns = data.map((item) => {
    return buildColumn({ date: item.date, groupedBy });
  });

  return [buildHeaderColumn(), ...columns];
}

const fakeEmptyData = Array.from({ length: 12 }, (_item, i) =>
  format(new Date(2025, i), 'yyyy-MM-dd')
);
export function generateEmptyColumns() {
  const columns = fakeEmptyData.map((item) => {
    return buildColumn({ date: item, groupedBy: CashFlowGroupBy.MONTH });
  });

  return [buildHeaderColumn(), ...columns];
}
