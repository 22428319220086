import { useDrawingArea, useXScale, useYScale } from '@mui/x-charts-pro';
import { Skeleton } from '@mui/material';

import styles from './LoadingState.module.scss';

const ratios = [0.2, 0.8, 0.6, 0.5];

export default function LoadingState() {
  const { height } = useDrawingArea();

  const xScale = useXScale<'band'>();
  const yScale = useYScale();

  const bandWidth = xScale.bandwidth();

  const [bottom, top] = yScale.range();

  return (
    <>
      {xScale.domain().map((item, index) => {
        const ratio = ratios[index % ratios.length];
        const barHeight = ratio * (bottom - top);

        return (
          <Skeleton
            className={styles.loader}
            variant="rectangular"
            component="rect"
            key={index}
            x={xScale(item)}
            width={bandWidth}
            y={bottom - barHeight}
            height={height}
          />
        );
      })}
    </>
  );
}
