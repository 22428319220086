import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { Typography } from 'src/design-system/components';

import { toCurrency } from 'src/libs/finbits/Money';

type Props = {
  title: string;
  body: JSX.Element;
  value: number;
};

export function MoreInformation({ title, body, value }: Props) {
  return (
    <Box flex={1}>
      <Stack direction="row" gap={2} flex={1}>
        <Typography color="grey.900" fontWeight={600} variant="text-xs">
          {title}
        </Typography>
      </Stack>
      <Typography color="grey.900" fontWeight={600} variant="text-sm">
        {toCurrency(value)}
      </Typography>
      {body}
    </Box>
  );
}
