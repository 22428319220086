import { Typography } from 'src/design-system/components';
import { Box, Button, Stack, Tooltip } from '@mui/material';
import { CursorBoxIcon } from 'src/mui/_icons';

import illustration from 'src/assets/zoom-chart.svg';

import styles from './ZoomTooltip.module.scss';

type Props = {
  isEmpty: boolean;
};

export default function ZoomTooltip({ isEmpty }: Props) {
  return (
    <Tooltip
      disableHoverListener={isEmpty}
      classes={{ tooltip: styles.container, arrow: styles.arrow }}
      arrow
      title={
        <Box>
          <img
            src={illustration}
            alt="Dê zoom e navegue pelo gráfico"
            className={styles.illustration}
          />
          <Stack gap={2} className={styles.content}>
            <Typography fontWeight={600} className={styles.title}>
              Dê zoom e navegue pelo gráfico
            </Typography>
            <ul className={styles.list}>
              <li>
                <Typography variant="text-sm">
                  Use a rolagem do mouse para <b>aproximar e distanciar</b> de
                  um ponto do gráfico.
                </Typography>
              </li>
              <li>
                <Typography variant="text-sm">
                  Clique e arraste para <b>navegar pelo gráfico.</b>
                </Typography>
              </li>
              <li>
                <Typography variant="text-sm">
                  Clique em um ponto do gráfico para{' '}
                  <b>destacar a respectiva coluna</b> na tabela
                </Typography>
              </li>
            </ul>
          </Stack>
        </Box>
      }
    >
      <span>
        <Button
          size="small"
          aria-label="Recursos de navegação"
          color="secondary"
          startIcon={<CursorBoxIcon />}
          disabled={isEmpty}
        >
          Recursos de navegação
        </Button>
      </span>
    </Tooltip>
  );
}
