import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import type { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Typography } from 'src/design-system/components';
import { BankIcon } from 'src/mui/_scss';

import { type Account } from 'src/libs/finbits/Bank/Accounts/types';

import SynchronizeTypeBadge from 'src/features/accounts/SynchronizeTypeBadge';

export default function AccountInfo({
  row,
  rowNode,
  formattedValue,
}: Partial<GridRenderCellParams<{ account?: Account }>>) {
  if (rowNode?.type === 'group') {
    return <Typography>{formattedValue}</Typography>;
  }

  return (
    <ListItem disablePadding disableGutters>
      <ListItemIcon>
        <SynchronizeTypeBadge synchronizeType={row?.account?.synchronizeType!!}>
          <BankIcon
            routingNumber={row?.account?.routingNumber!!}
            width={24}
            height={24}
          />
        </SynchronizeTypeBadge>
      </ListItemIcon>

      <ListItemText secondary={row?.account?.accountNumber}>
        <Typography ellipsis>{row?.account?.name}</Typography>
      </ListItemText>
    </ListItem>
  );
}
