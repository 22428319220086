import { Button, Divider, Stack } from '@mui/material';
import cn from 'classnames';
import { useNavigate } from 'react-router';
import { Typography } from 'src/design-system/components';
import { BankIcon } from 'src/mui/_scss';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import illustration from 'src/assets/auto-payment-banner.svg';

import { RoutingNumber } from 'src/libs/finbits/Bank/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import styles from './AutoPaymentBanner.module.scss';

type Props = {
  big?: boolean;
};

export default function AutoPaymentBanner({ big = false }: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const navigate = useNavigate();

  function handleClick() {
    navigate({
      pathname: generateCompanyPath(RouteKey.ACCOUNTS, {
        companyId,
        organizationId,
      }),
    });
  }

  return (
    <>
      <Stack
        className={cn(styles.container, {
          [styles.big]: big,
        })}
        display="grid"
      >
        <Stack
          className={cn(styles.banksContainer, {
            [styles.big]: big,
          })}
          direction="row"
          gap={8}
        >
          {big && (
            <img
              src={illustration}
              alt="Ilustração de uma pessoa analisando dados em uma tela com pagamentos automatizados"
            />
          )}

          <Stack
            direction={big ? 'column' : 'row-reverse'}
            alignItems={big ? 'flex-start' : 'center'}
            gap={4}
          >
            <Stack direction="row" gap={2}>
              <BankIcon
                routingNumber={RoutingNumber.BTG}
                monochrome
                width={40}
                height={40}
                className={styles.icon}
              />
              <BankIcon
                routingNumber={RoutingNumber.ITAU}
                monochrome
                width={40}
                height={40}
                className={styles.icon}
              />
              <BankIcon
                routingNumber={RoutingNumber.SANTANDER}
                monochrome
                width={40}
                height={40}
                className={styles.icon}
              />
            </Stack>

            <Typography
              fontWeight="600"
              variant={big ? 'text-lg' : 'text-md'}
              className={cn(styles.title, { [styles.big]: big })}
              color="white"
            >
              <span className={styles.brandText}>Automatize</span> Pix,
              {!big && <br />} boletos* e TED.
            </Typography>

            <small className={cn(styles.note, { [styles.big]: big })}>
              * Disponível p/ Santander e BTG.
            </small>
          </Stack>
        </Stack>

        <Stack
          className={cn(styles.cta, { [styles.big]: big })}
          direction={big ? 'column' : 'row'}
          justifyContent="center"
          alignItems={big ? 'flex-start' : 'center'}
          gap={4}
        >
          <Typography variant="text-sm" color="white">
            Além do <strong>BTG</strong>, os{' '}
            <strong>pagamentos automáticos</strong> agora também estão
            disponíveis para os bancos <strong>Itaú</strong> e{' '}
            <strong>Santander</strong>.
          </Typography>

          <Button
            variant="contained"
            className={styles.button}
            onClick={handleClick}
          >
            Conectar conta
          </Button>
        </Stack>
      </Stack>
      <Divider />
    </>
  );
}
