import type { Ref } from 'react';
import { forwardRef } from 'react';

import { Stack } from '@mui/material';

import LegendItem from './LegendItem';
import styles from './Legends.module.scss';

type Props = {};

function Legends({ ...props }, ref: Ref<HTMLDivElement>) {
  return (
    <Stack
      direction="row"
      gap={10}
      className={styles.legend}
      {...props}
      ref={ref}
    >
      <LegendItem
        labelText="Saldo"
        iconProps={{
          className: styles.resultAmountlegendIcon,
        }}
        projectedLabel="Projetado"
      />

      <LegendItem
        labelText="Recebimentos"
        iconProps={{
          className: styles.creditAmountlegendIcon,
        }}
      />

      <LegendItem
        labelText="Pagamentos"
        iconProps={{
          className: styles.debitAmountlegendIcon,
        }}
      />
    </Stack>
  );
}

export default forwardRef<HTMLInputElement, Props>(Legends);
