import { MenuList } from '@mui/material';

import { RouteKey } from 'src/router/types';

import { useIsEnabledBillsApprovalsInCompany } from 'src/libs/finbits/Organization/Companies/Options';

import SwitchCompanyAutocomplete from 'src/features/topmenu/SwitchCompanyAutocomplete';

import NavigationItem from './NavigationItem';
import NavigationBurger from './NavigationBurger';
import { navigation } from './Navigation.sx';

export default function Navigation() {
  const { isEnabledBillsApprovals } = useIsEnabledBillsApprovalsInCompany();

  return (
    <>
      <NavigationBurger />

      <MenuList sx={navigation}>
        <SwitchCompanyAutocomplete />
        <NavigationItem
          routeKey={RouteKey.OVERVIEW}
          permission={{
            action: 'show_overview',
            resource: 'webappPages',
            scope: 'company',
          }}
        >
          Visão geral
        </NavigationItem>
        <NavigationItem
          routeKey={RouteKey.INBOX_ITEMS}
          permission={{
            action: 'index',
            resource: 'inboxItems',
            scope: 'company',
          }}
        >
          Caixa de Entrada
        </NavigationItem>
        <NavigationItem
          routeKey={RouteKey.BILLS_TO_PAY}
          permission={{
            action: 'index',
            resource: 'bills',
            scope: 'company',
          }}
          options={[
            {
              label: 'Contas a Pagar',
              routeKey: RouteKey.BILLS_TO_PAY,
              permission: {
                action: 'index',
                resource: 'bills',
                scope: 'company',
              },
            },
            {
              label: 'Aprovações',
              routeKey: RouteKey.APPROVAL,
              permission: {
                action: 'index_pending_approval',
                resource: 'bills',
                scope: 'company',
              },
              disabled: !isEnabledBillsApprovals,
            },
          ]}
        >
          Pagamentos
        </NavigationItem>
        <NavigationItem
          routeKey={RouteKey.ENTRIES}
          permission={{
            action: 'index',
            resource: 'financialStatements',
            scope: 'company',
          }}
        >
          Lançamentos
        </NavigationItem>
        <NavigationItem
          routeKey={RouteKey.DASHBOARD}
          permission={{
            action: 'show_dashboard',
            resource: 'webappPages',
            scope: 'company',
          }}
          options={[
            {
              label: 'Notas Fiscais',
              routeKey: RouteKey.NOTA_FISCAL,
              dividerAfter: true,
              permission: {
                action: 'index',
                resource: 'notasFiscais',
                scope: 'company',
              },
            },
            {
              label: 'Resumo',
            },
            {
              label: 'Empresa atual',
              routeKey: RouteKey.DASHBOARD,
              permission: {
                action: 'show_dashboard',
                resource: 'webappPages',
                scope: 'company',
              },
            },
            {
              label: 'Todas as empresas',
              routeKey: RouteKey.BALANCES,
              permission: {
                action: 'index',
                resource: 'companiesBalances',
                scope: 'organization',
              },
            },
          ]}
        >
          Mais
        </NavigationItem>
      </MenuList>
    </>
  );
}
