import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { Typography } from 'src/design-system/components';

import illustration from 'src/assets/illustration-intro-consent-bb.svg';

import type { StepperContentProps } from 'src/features/open-banking/AutoPayment/VerticalStepper/types';

export default function IntroStep({ onNext, tradeName }: StepperContentProps) {
  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <Box
        display="flex"
        alignItems="center"
        gap={6}
        paddingInline={20}
        paddingBottom={4}
      >
        <Box>
          <Typography
            variant="text-md"
            fontWeight="600"
            component="h6"
            marginBottom={4}
          >
            Pronto para automatizar os seus pagamentos no {tradeName}?
          </Typography>

          <Typography marginBottom={4}>
            Neste passo a passo, vamos te ajudar a criar a sua conexão de
            pagamentos no {tradeName} de forma segura.
          </Typography>

          <Typography>
            Sabemos que esse processo pode parecer complexo, mas seguindo com
            atenção as orientações de cada etapa, em pouco tempo sua conexão
            estará pronta para uso.
          </Typography>
        </Box>

        <Box>
          <img
            src={illustration}
            alt="Ilustração introdutória sobre consentimento, apresentando personagens e elementos visuais explicativos"
          />
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={onNext}
        >
          Vamos lá
        </Button>
      </Box>
    </Box>
  );
}
