import type { ReactNode } from 'react';

export enum Tab {
  INFORMATION = 'information',
  CLASSIFICATIONS = 'classifications',
  RECURRENCE = 'recurrence',
}

export enum PreviewTab {
  SUGGESTIONS = 'suggestions',
  ATTACHMENTS = 'attachments',
}

export type TabValues = { [key in Tab]?: boolean };

export type TabPanelsProviderProps = {
  children: ReactNode;
  disabledTabs?: TabValues;
};

export type TabPanelsContextProps = {
  selectedPreviewTab: PreviewTab;
  setSelectedPreviewTab: (selectedPreviewTab: PreviewTab) => void;
  selectedBillTab: Tab;
  setSelectedBillTab: (selectedPreviewTab: Tab) => void;
  disabledTabs?: TabValues;
};
