import type { QueryClient } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';

import type {
  BBConsentmentsCertificateParams,
  BBConsentmentsCurrentStepParams,
  CreateBBConsentmentsParams,
  GetCurrentStepParams,
  PostBBCertificateParams,
} from './types';
import {
  BBConsentmentsCertificateDecoder,
  BBConsentmentsCurrentStepDecoder,
} from './types';

const bbConsentmentsKeys = {
  all: ['bbConsentments'] as const,
  certificateChain: (params: GetCurrentStepParams) =>
    [...bbConsentmentsKeys.all, 'certificateChain', params] as const,
  currentConsentStep: (params: GetCurrentStepParams) =>
    [...bbConsentmentsKeys.all, 'currentConsentStep', params] as const,
};

function invalidateOpenBankingConnectionsQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(['openBankingConnections']);
}

async function getCurrentConsentStep({
  accountId,
  companyId,
  organizationId,
}: GetCurrentStepParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/bb_consentment/${accountId}/current_step`
  );

  return decodeResponse<BBConsentmentsCurrentStepParams>(
    response,
    BBConsentmentsCurrentStepDecoder
  );
}

async function certificateBBConsentments({
  organizationId,
  companyId,
  ...params
}: PostBBCertificateParams) {
  const formData = new FormData();
  formData.append('accountId', params.accountId);
  formData.append('password', params.password);
  formData.append('certificate', params.certificate);

  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/bb_consentment`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );

  return decodeResponse<BBConsentmentsCertificateParams>(
    response,
    BBConsentmentsCertificateDecoder
  );
}

async function connectBBConsentments({
  organizationId,
  companyId,
  ...params
}: CreateBBConsentmentsParams) {
  await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/bb_consentment/active`,
    params
  );
}

export function useGetCurrentConsentStep(params: GetCurrentStepParams) {
  const { data, ...rest } = useQuery<BBConsentmentsCurrentStepParams, ApiError>(
    {
      enabled:
        !!params.companyId && !!params.organizationId && !!params.accountId,
      queryKey: bbConsentmentsKeys.currentConsentStep(params),
      queryFn: () => getCurrentConsentStep(params),
    }
  );

  return { currentStep: parseInt(data?.currentStep as string), ...rest };
}

export function useUploadCertificateBBConsentments() {
  const { mutate, ...rest } = useMutation<
    BBConsentmentsCertificateParams,
    ApiError<ApiErrorForm>,
    PostBBCertificateParams
  >({
    mutationFn: certificateBBConsentments,
  });
  return { certificateBBConsentments: mutate, ...rest };
}

export function useCreateBBConsentments() {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation<
    void,
    ApiError<string>,
    CreateBBConsentmentsParams
  >(connectBBConsentments, {
    onSuccess: () => {
      invalidateOpenBankingConnectionsQueries(queryClient);
    },
  });
  return { connectBBConsentments: mutate, ...rest };
}
