const blue = {
  25: '#F5FAFF',
  50: '#EFF8FF',
  100: '#D1E9FF',
  200: '#B2DDFF',
  300: '#84CAFF',
  400: '#53B1FD',
  500: '#2E90FA',
  600: '#1570EF',
  700: '#175CD3',
  800: '#1849A9',
  900: '#194185',
};

export const grey = {
  25: '#FCFCFC',
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#E5E5E5',
  300: '#D6D6D6',
  400: '#A3A3A3',
  500: '#737373',
  600: '#525252',
  700: '#424242',
  800: '#292929',
  900: '#141414',
};

const red = {
  25: '#FFFBFA',
  50: '#FEF3F2',
  100: '#FEE4E2',
  200: '#FECDCA',
  300: '#FDA29B',
  400: '#F97066',
  500: '#F04438',
  600: '#D92D20',
  700: '#B42318',
  800: '#912018',
  900: '#7A271A',
};

const green = {
  25: '#F6FEF9',
  50: '#ECFDF3',
  100: '#D1FADF',
  200: '#A6F4C5',
  300: '#6CE9A6',
  400: '#32D583',
  500: '#12B76A',
  600: '#039855',
  700: '#027A48',
  800: '#05603A',
  900: '#054F31',
};

const yellow = {
  25: '#FFFCF5',
  50: '#FFFAEB',
  100: '#FEF0C7',
  200: '#FEDF89',
  300: '#FEC84B',
  400: '#FDB022',
  500: '#F79009',
  600: '#DC6803',
  700: '#B54708',
  800: '#93370D',
  900: '#7A2E0E',
};

export const primary = {
  ...blue,
  light: '#90B6ff',
  main: blue[600],
  dark: blue[700],
  contrastText: grey[25],
};

export const secondary = {
  light: grey[400],
  main: grey[600],
  dark: grey[700],
  contrastText: grey[25],
};

export const success = {
  ...green,
  light: '#3FC79A',
  main: '#10B981',
  dark: '#0B815A',
  contrastText: grey[25],
};

export const info = {
  ...blue,
  light: '#CFF9FE',
  main: '#06AED4',
  dark: '#0E7090',
  contrastText: grey[25],
};

export const warning = {
  ...yellow,
  light: '#FEF0C7',
  main: '#F79009',
  dark: '#B54708',
  contrastText: grey[25],
};

export const error = {
  ...red,
  light: '#FEE4E2',
  main: '#F04438',
  dark: '#B42318',
  contrastText: grey[25],
};

export const brand = {
  primary: '#108036',
  light: '#F8F5EE',
};
