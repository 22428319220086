import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import {
  Col,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Switch,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import type { FieldData, StoreValue } from 'rc-field-form/lib/interface';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CalendarOutlined,
  HourglassOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import type { RuleObject } from 'antd/lib/form';

import {
  AmountInput,
  DrawerFooter,
  FormItem,
  Loader,
  MaskedDatePicker,
  useOpenPortal,
} from 'src/ui';

import analytics from 'src/libs/analytics';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';
import { useCategories } from 'src/libs/finbits/Management/FinancialEntries/Categories';
import { DEPRECATED_useEntrySuggestions as useEntrySuggestions } from 'src/libs/finbits/Management/Entries/Suggestions';
import { useAccounts } from 'src/libs/finbits/Bank/Accounts';
import { errorsToFormField } from 'src/libs/finbits/Form';
import type { ApiErrorForm } from 'src/libs/finbits/client';
import { greaterThanZeroValidator } from 'src/libs/finbits/Amount';
import { useCompanyContact } from 'src/libs/finbits/Organization/Companies/Contacts';
import { isBefore, isFuture, parseISO } from 'src/libs/finbits/Date';
import type { Category } from 'src/libs/finbits/Management/FinancialEntries/Categories/types';
import {
  filterAvailable,
  useClassifications,
} from 'src/libs/finbits/Classifications';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { BillPayableApprovalType } from 'src/libs/finbits/Bills/types';
import { useIsEnabledBillsApprovalsInCompany } from 'src/libs/finbits/Organization/Companies/Options';
import type {
  ContactSuggestion,
  EntrySuggestions,
  EntrySuggestionsPostParams,
} from 'src/libs/finbits/Management/Entries/types';
import type { Classification } from 'src/libs/finbits/Classifications/types';
import type { PaymentDetails } from 'src/libs/finbits/Payments/types';
import type { Contact } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import type { Label } from 'src/libs/finbits/Labels/types';

import CategorySelect from 'src/features/categories/DEPRECATED_CategorySelect';
import CreateContactDrawer from 'src/features/contacts/CreateContactDrawer';
import UploadAttachments from 'src/features/attachments/UploadAttachments';
import ContactSelect from 'src/features/contacts/ContactSelect';
import AccountSelect from 'src/features/DEPRECATED_accounts/AccountSelect';
import CreateContactFromEntry from 'src/features/contacts/CreateContactFromEntry';
import { DEPRECATED_LabelSelect as LabelSelect } from 'src/features/labels/LabelSelect';
import AssigneeSelect from 'src/features/assignee/DEPRECATED_AssigneeSelect';
import ApprovalTypeSelect from 'src/features/approval/DEPRECATED_ApprovalTypeSelect';
import PaymentFields from 'src/features/payments/PaymentFields';
import LabelModal from 'src/features/labels/LabelModal';

import CreateCategoryModal from '../categories/CreateCategoryModal/CreateCategoryModal';

import type {
  EntryFormDisabledFields,
  EntryFormHiddenActions,
  EntryFormHiddenFields,
  FormValues,
  SubmitParams,
} from './types';
import { EditableFields } from './types';
import ShowContactDetails from './ShowContactDetails';
import RecurrenceFields from './RecurrenceFields';
import NotaFiscalField from './NotaFiscalField';
import CreateReceivableWithNotaFiscalButton from './CreateReceivableWithNotaFiscalButton';
import styles from './EntryForm.module.less';
import { formatNotFoundContactText } from './contactNotFound';
import { classificationToFormValues } from './formValues';
import CreateNotaFiscalFromReceivableButton from './CreateNotaFiscalFromReceivableButton/CreateNotaFiscalFromReceivableButton';

const PAYMENT_FIELDS_KEYS = [
  'accountDocument',
  'accountNumber',
  'accountType',
  'bankName',
  'branchNumber',
  'pixKey',
  'pixType',
  'routingNumber',
  'payeeName',
];

type BoletoParams = Record<string, any>;

function buildInitialValues(
  initialValues?: FormValues,
  classifications?: Classification[]
) {
  const initialClassification =
    initialValues?.classifications?.length !== undefined &&
    initialValues.classifications.length > 0
      ? initialValues?.classifications
      : classificationToFormValues(classifications, initialValues?.labels);

  return {
    ...initialValues,
    paymentDetails: {
      ...initialValues?.paymentDetails,
      boleto:
        initialValues?.paymentDetails?.boleto &&
        Object.entries(
          initialValues?.paymentDetails?.boleto
        ).reduce<BoletoParams>((values, [key, value]) => {
          values[key] = value ?? '';

          return values;
        }, {}),
    },
    approvalType: initialValues?.approvalType ?? BillPayableApprovalType.ALL,
    classifications: initialClassification,
  };
}

function dateLabel(entryType?: BalanceType) {
  if (entryType === BalanceType.DEBIT) return 'Data de pagamento';
  if (entryType === BalanceType.CREDIT) return 'Data de recebimento';

  return 'Data';
}

type FormSuggestions = EntrySuggestions & {
  paymentDetails?: PaymentDetails;
};

type Props = {
  isFormDirty?: boolean;
  organizationId?: string;
  companyId?: string;
  initialValues?: FormValues;
  onSubmit?: (params: SubmitParams, shouldCreateNotaFiscal?: boolean) => void;
  onFieldsChange?: (values?: FormValues) => void;
  loading?: boolean;
  disabled?: boolean;
  disabledFields?: EntryFormDisabledFields;
  hiddenFields?: EntryFormHiddenFields;
  hiddenActions?: EntryFormHiddenActions;
  descriptionLabel?: string;
  onChangeAmount?: () => void;
  errors?: ApiErrorForm;
  customValidations?: {
    [field in keyof FormValues]?: (
      rule: RuleObject,
      value?: StoreValue
    ) => Promise<void>;
  };
  submitButtonText?: string;
  isFinancialEntry?: boolean;
  hideFooter?: boolean;
  footer?: ReactNode;
  newContact?: ContactSuggestion;
  bill?: BillPayable;
  editableFields?: EditableFields[] | null;
};

function EntryForm({
  isFormDirty = false,
  organizationId,
  companyId,
  initialValues,
  onSubmit,
  onFieldsChange,
  loading,
  disabled = false,
  disabledFields = [],
  descriptionLabel = 'Descrição',
  hiddenFields = [],
  hiddenActions = [],
  onChangeAmount,
  errors,
  customValidations,
  submitButtonText = 'Salvar',
  isFinancialEntry = false,
  hideFooter = false,
  footer,
  newContact,
  bill,
  editableFields = Object.values(EditableFields),
}: Props) {
  const [form] = Form.useForm();

  const openPortal = useOpenPortal();

  const [suggestions, setSuggestions] = useState<FormSuggestions>({});
  const [isCreateDrawerVisible, setCreateDrawerVisible] = useState(false);
  const [selectedClassification, setSelectedClassification] = useState<
    Classification | undefined
  >(undefined);

  const isRecurrent = Form.useWatch('isRecurrent', form);
  const selectedContactId = Form.useWatch('contactId', form);
  const type = Form.useWatch('type', form);
  const notaFiscalId = Form.useWatch('notaFiscalId', form);
  const id = Form.useWatch('id', form);

  const contactAvailable =
    !hiddenFields.includes('paymentDetails') && !!selectedContactId;

  const { contact } = useCompanyContact(
    {
      contactId: contactAvailable ? selectedContactId : undefined,
      companyId,
      organizationId,
    },
    {
      onSuccess: setContactPaymentSuggestion,
    }
  );

  function setContactPaymentSuggestion(contact: Contact) {
    if (!contact) return;

    const formValuePaymentDetails = form.getFieldValue(['paymentDetails']);

    const suggestiblePaymentFields = {
      accountDocument: null,
      accountNumber: null,
      accountType: null,
      bankName: null,
      branchNumber: null,
      pixKey: null,
      pixType: null,
      routingNumber: null,
      payeeName: null,
    };

    const contactPaymentDetails = {
      ...suggestiblePaymentFields,
      ...contact?.bankDetails,
      payeeName: contact?.name,
      accountDocument: contact?.document ?? null,
    };

    const { newFormValues, newPaymentSuggestions } = PAYMENT_FIELDS_KEYS.reduce(
      (acc, fieldKey) => {
        const suggestionValue = get(
          suggestions?.paymentDetails,
          fieldKey,
          null
        );
        const formValue = get(formValuePaymentDetails, fieldKey, null);
        const contactValue = get(contactPaymentDetails, fieldKey);

        const isFilledBySuggestion = suggestionValue === formValue;

        const fieldValue = isFilledBySuggestion ? contactValue : formValue;
        const fieldSuggestion = isFilledBySuggestion ? contactValue : null;

        acc = {
          ...acc,
          newFormValues: { ...acc.newFormValues, [fieldKey]: fieldValue },
          newPaymentSuggestions: {
            ...acc.newPaymentSuggestions,
            [fieldKey]: fieldSuggestion,
          },
        };

        return acc;
      },
      {
        newFormValues: suggestiblePaymentFields,
        newPaymentSuggestions: suggestiblePaymentFields,
      }
    );

    const paymentDetails = {
      ...formValuePaymentDetails,
      ...newFormValues,
    };

    form.setFieldsValue({ paymentDetails });
    updateSuggestions({
      paymentDetails: newPaymentSuggestions,
    });
  }

  const { classifications, isLoading: isLoadingClassifications } =
    useClassifications(
      {
        companyId,
        organizationId,
      },
      {
        staleTime: FIVE_MINUTES_IN_MS,
      }
    );

  function handleNewContactCreation(contactId: string) {
    form.setFieldsValue({ contactId });
    onFieldsChange && onFieldsChange();
  }

  function handleNewCategoryCreation(category: Category) {
    if (category.type === type) {
      form.setFieldsValue({ categoryId: category.id });
      onFieldsChange && onFieldsChange();
    }
  }

  function handleNewLabelCreation(label: Label) {
    const availableClassifications = filterAvailable(
      classifications,
      initialValues?.labels
    );

    const classification = availableClassifications.findIndex(
      (classification) => classification.id === label.classificationId
    );

    const labelsIds =
      form.getFieldValue(['classifications', classification, 'labelsIds']) ||
      [];

    form.setFieldValue(
      ['classifications', classification, 'labelsIds'],
      [...labelsIds, label.id]
    );

    onFieldsChange && onFieldsChange();
  }

  function onOpenCreateLabel(classification: Classification) {
    setSelectedClassification(classification);
  }

  useEffect(() => {
    errors && form.setFields(errorsToFormField(errors));
  }, [form, errors]);

  useEffect(() => {
    if (!isFormDirty && initialValues) {
      form.setFieldsValue(buildInitialValues(initialValues, classifications));
    }
  }, [form, isFormDirty, initialValues, classifications]);

  const { entrySuggestions, isLoading: isLoadingSuggestions } =
    useEntrySuggestions();

  const suggestEntryValues = useCallback(
    ({ contactId, type }: EntrySuggestionsPostParams) => {
      entrySuggestions(
        {
          organizationId,
          companyId,
          contactId,
          type,
        },
        {
          onSuccess: (response) => {
            updateSuggestions(response);

            form.setFieldValue('categoryId', response?.category?.id);
          },
        }
      );
    },
    [companyId, organizationId, form, entrySuggestions]
  );

  useEffect(() => {
    if (!initialValues) return;

    const { contactId, type, categoryId } = initialValues;

    if (contactId && type && !categoryId) {
      suggestEntryValues({ contactId, type });
    }
  }, [initialValues, suggestEntryValues]);

  const { categories, isLoading: isLoadingCategories } = useCategories(
    {
      companyId,
      organizationId,
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
    }
  );

  const { accounts, isLoading: isLoadingAccounts } = useAccounts(
    {
      companyId,
      organizationId,
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
    }
  );

  const { isEnabledBillsApprovals } = useIsEnabledBillsApprovalsInCompany({
    enabled: type === BalanceType.DEBIT,
  });

  function isDateBeforeFirstsDailyBalance(date: Date) {
    const accountId = form.getFieldValue('accountId');

    const account = accounts.find((account) => account.id === accountId);

    const initialBalance = account?.initialBalance;

    if (!initialBalance?.date) return false;

    const firstDailyBalanceDate = parseISO(initialBalance.date);

    return isBefore(date, firstDailyBalanceDate);
  }

  function isInvalidDateFinancialEntry(date: Date) {
    if (!isFinancialEntry) return false;

    const accountId = form.getFieldValue('accountId');

    if (!accountId) return isFuture(date);

    return isFuture(date) || isDateBeforeFirstsDailyBalance(date);
  }

  function getCustomValidations(field: keyof FormValues) {
    if (!customValidations) return [];

    if (customValidations[field]) {
      return [
        {
          validator: customValidations[field],
        },
      ];
    }

    return [];
  }

  const filteredCategories = useMemo<Category[]>(() => {
    if (isLoadingCategories || !categories) {
      return [];
    }

    return categories.filter(
      ({ id, active, type: categoryType }) =>
        categoryType === type && (active || initialValues?.categoryId === id)
    );
  }, [categories, isLoadingCategories, type, initialValues?.categoryId]);

  const isContactExists = selectedContactId || initialValues?.contactId;
  const showCreateNewContact = !isContactExists && Boolean(newContact);

  if (isLoadingClassifications) {
    return <Loader size="small" forceCentered />;
  }

  function handleSuggestionsMetrics() {
    const categoryId = form.getFieldValue('categoryId');

    if (suggestions.category === null) {
      analytics.track('Category Suggestion Not Found');
    }

    if (Boolean(suggestions.category)) {
      if (suggestions.category?.id === categoryId) {
        analytics.track('Category Suggestion Accepted');

        return;
      }

      analytics.track('Category Suggestion Rejected');
    }
  }

  function isDisabled(field: keyof typeof EditableFields) {
    return (
      disabled ||
      disabledFields.includes(field as any) ||
      !editableFields?.includes(field as EditableFields)
    );
  }

  function updateSuggestions(params: FormSuggestions) {
    setSuggestions((prevState) => ({
      ...prevState,
      ...params,
    }));
  }

  return (
    <>
      {isCreateDrawerVisible && (
        <CreateContactDrawer
          onClose={() => setCreateDrawerVisible(false)}
          onCreate={handleNewContactCreation}
        />
      )}

      {!!selectedClassification && (
        <LabelModal
          classification={selectedClassification}
          onClose={() => setSelectedClassification(undefined)}
          onCreate={handleNewLabelCreation}
          title={`Criar ${selectedClassification.name}`}
          okText={'Criar'}
        />
      )}

      <Form
        preserve
        form={form}
        className={styles.form}
        layout="vertical"
        initialValues={buildInitialValues(initialValues, classifications)}
        onFinish={(params) => {
          handleSuggestionsMetrics();

          onSubmit && onSubmit(params);
        }}
        onValuesChange={(values: FormValues) =>
          onFieldsChange && onFieldsChange(values)
        }
        onFieldsChange={(changedFields: FieldData[]) => {
          const [{ name }] = changedFields;

          if (isEqual(name, ['type'])) {
            form.setFieldsValue({
              categoryId: null,
            });
          }

          if (isEqual(name, ['accountId']) && form.isFieldTouched('date')) {
            form.validateFields(['date']);
          }

          if (isEqual(name, ['paymentDetails', 'pixType'])) {
            form.setFieldsValue({ paymentDetails: { pixKey: null } });
          }

          if (Array.isArray(name) && name.includes('paymentDetails')) {
            const fieldKey = name.at(1);

            if (fieldKey && PAYMENT_FIELDS_KEYS.includes(fieldKey.toString())) {
              updateSuggestions({
                paymentDetails: {
                  ...suggestions.paymentDetails,
                  [fieldKey]: null,
                },
              });
            }
          }

          if (isEqual(name, ['type']) || isEqual(name, ['contactId'])) {
            if (form.getFieldValue('contactId') && type) {
              const categoryId = form.getFieldValue('categoryId');

              const hasCategorySuggestion =
                suggestions.category && suggestions.category?.id === categoryId;

              if (hasCategorySuggestion || !categoryId) {
                suggestEntryValues({
                  contactId: form.getFieldValue('contactId'),
                  type,
                });
              }

              return;
            }
          }
        }}
      >
        <Form.Item name="id" hidden>
          <input />
        </Form.Item>

        <Form.Item name="scheduledEntryId" hidden>
          <input />
        </Form.Item>

        {hiddenFields.includes('type') && (
          <Form.Item hidden name="type">
            <input />
          </Form.Item>
        )}

        {type === BalanceType.CREDIT && (
          <Form.Item hidden name="notaFiscalId">
            <input />
          </Form.Item>
        )}

        <Row gutter={[30, 10]} className={styles.descriptionContainer}>
          {!hiddenFields.includes('originDescription') &&
            !!initialValues?.originDescription && (
              <Col span={24}>
                <p>{initialValues?.originDescription}</p>
              </Col>
            )}
          <Col span={hiddenFields.includes('type') ? 24 : 12}>
            <FormItem required label={descriptionLabel} name="description">
              <Input
                aria-label="descrição"
                size="large"
                disabled={isDisabled('description')}
              />
            </FormItem>
          </Col>
          {!hiddenFields.includes('type') && (
            <Col span={12}>
              <FormItem required name="type" aria-label="Tipo">
                <Radio.Group
                  disabled={isDisabled('type')}
                  size="large"
                  className={styles.radioGroupType}
                >
                  <Radio.Button value="debit" className={styles.radioType}>
                    <ArrowDownOutlined /> Pagamento
                  </Radio.Button>
                  <Radio.Button value="credit" className={styles.radioType}>
                    <ArrowUpOutlined /> Recebimento
                  </Radio.Button>
                </Radio.Group>
              </FormItem>
            </Col>
          )}
        </Row>

        <Row gutter={[32, 8]}>
          <Col span={12}>
            <FormItem
              name="amount"
              label="Valor"
              required
              hasMax={false}
              rules={[
                {
                  validator: greaterThanZeroValidator,
                },
              ]}
            >
              <AmountInput
                placeholder="0,00"
                disabled={isDisabled('amount')}
                onBlur={onChangeAmount}
              />
            </FormItem>
          </Col>
          {!hiddenFields.includes('accountId') && (
            <Col span={12}>
              <FormItem
                required
                name="accountId"
                label="Conta"
                rules={getCustomValidations('accountId')}
              >
                <AccountSelect
                  accounts={accounts}
                  loading={isLoadingAccounts}
                  disabled={isDisabled('accountId')}
                />
              </FormItem>
            </Col>
          )}
          {!hiddenFields.includes('date') && (
            <Col span={hiddenFields.includes('isRecurrent') ? 12 : 7}>
              <Form.Item shouldUpdate noStyle>
                {() => (
                  <FormItem
                    required
                    hasMax={false}
                    name="date"
                    label={dateLabel(type)}
                    rules={getCustomValidations('date')}
                    dependencies={['accountId']}
                  >
                    <MaskedDatePicker
                      placeholder="dd/mm/aaaa"
                      aria-label="data"
                      className={styles.datePicker}
                      size="large"
                      format="dd/MM/yyyy"
                      suffixIcon={<CalendarOutlined />}
                      disabled={isDisabled('date')}
                      disabledDate={isInvalidDateFinancialEntry}
                    />
                  </FormItem>
                )}
              </Form.Item>
            </Col>
          )}

          {!hiddenFields.includes('isRecurrent') && (
            <Col span={5}>
              <Form.Item
                name="isRecurrent"
                label={
                  <Typography.Text>
                    Recorrente
                    {initialValues?.isRecurrent && (
                      <Tooltip
                        title="Exclua este grupo de lançamentos para criar uma nova recorrência"
                        placement="top"
                        className={styles.recurrenceTooltip}
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    )}
                  </Typography.Text>
                }
                valuePropName="checked"
              >
                <Switch disabled={isDisabled('isRecurrent')} />
              </Form.Item>
            </Col>
          )}

          <Col span={12}>
            <FormItem hasMax={false} name="relevantDate" label="Competência">
              <MaskedDatePicker
                className={styles.datePicker}
                size="large"
                format="MM/yyyy"
                picker="month"
                placeholder="Selecione um mês"
                aria-label="competência"
                disabled={isDisabled('relevantDate')}
                allowClear
                dataTestId="competencia-datepicker"
              />
            </FormItem>
          </Col>

          {isRecurrent && (
            <RecurrenceFields
              hiddenFields={hiddenFields}
              disabled={disabled}
              disabledFields={disabledFields}
            />
          )}

          {isEnabledBillsApprovals && (
            <>
              {!hiddenFields.includes('assigneesIds') &&
                type === BalanceType.DEBIT && (
                  <Col span={12}>
                    <FormItem
                      hasMax={false}
                      name="assigneesIds"
                      label="Aprovadores"
                    >
                      <AssigneeSelect
                        bill={bill}
                        disabled={isDisabled('assigneesIds')}
                      />
                    </FormItem>
                  </Col>
                )}
              {!hiddenFields.includes('approvalType') &&
                type === BalanceType.DEBIT && (
                  <Col span={12}>
                    <FormItem
                      name="approvalType"
                      label="Aprovações necessárias"
                    >
                      <ApprovalTypeSelect
                        disabled={isDisabled('approvalType')}
                      />
                    </FormItem>
                  </Col>
                )}
            </>
          )}
          <Col span={12}>
            <FormItem
              name="contactId"
              label="Contato"
              help={
                showCreateNewContact
                  ? formatNotFoundContactText(newContact)
                  : undefined
              }
              extra={
                showCreateNewContact && (
                  <CreateContactFromEntry
                    document={newContact?.document ?? ''}
                    name={newContact?.name ?? ''}
                    nickname={newContact?.nickname ?? ''}
                    type={newContact?.type}
                    onCreate={handleNewContactCreation}
                    disabled={disabled}
                  />
                )
              }
            >
              <ContactSelect
                listHeight={180}
                companyId={companyId}
                organizationId={organizationId}
                onOpenCreateContact={() => setCreateDrawerVisible(true)}
                disabled={isDisabled('contactId')}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </FormItem>
          </Col>

          <Col span={12}>
            <Form.Item shouldUpdate noStyle>
              {() => (
                <FormItem
                  name="categoryId"
                  label={
                    <Space>
                      Categoria
                      {isLoadingSuggestions ? (
                        <Tag icon={<HourglassOutlined />} color="blue">
                          Carregando sugestão...
                        </Tag>
                      ) : suggestions.category &&
                        suggestions.category?.id ===
                          form.getFieldValue('categoryId') ? (
                        <Tag color="blue">Preenchimento sugerido</Tag>
                      ) : null}
                    </Space>
                  }
                >
                  <CategorySelect
                    disabled={isDisabled('categoryId') || isLoadingSuggestions}
                    categories={filteredCategories}
                    loading={isLoadingCategories}
                    onOpenCreateCategory={() =>
                      openPortal(CreateCategoryModal, {
                        defaultValues: { type },
                        onCreateSuccess: handleNewCategoryCreation,
                      })
                    }
                    placeholder={
                      isLoadingSuggestions
                        ? 'Carregando...'
                        : 'Digite o nome da categoria'
                    }
                  />
                </FormItem>
              )}
            </Form.Item>
          </Col>

          {selectedContactId && (
            <Col span={24} className={styles.showContactDetailsContainer}>
              <ShowContactDetails contactId={selectedContactId} />
            </Col>
          )}

          {!hiddenFields.includes('classifications') && (
            <Col span={24}>
              <LabelSelect
                name="classifications"
                selectedLabels={initialValues?.labels}
                classifications={classifications}
                disabled={isDisabled('classifications')}
                onOpenCreateLabel={onOpenCreateLabel}
              />
            </Col>
          )}

          {type === BalanceType.DEBIT &&
            !hiddenFields.includes('paymentDetails') && (
              <PaymentFields
                disabled={isDisabled('paymentDetails')}
                requiredFields={[
                  'pixType',
                  'pixKey',
                  'boleto',
                  'payeeName',
                  'accountNumber',
                  'branchNumber',
                  'accountType',
                  'routingNumber',
                  'accountDocument',
                ]}
                contactParams={{
                  contactDocument: contact?.document,
                  contactType: contact?.type,
                }}
                pixSearchEnabled
                boletoSearchEnabled
                suggestions={suggestions.paymentDetails}
              />
            )}
          {type === BalanceType.DEBIT && (
            <>
              <Col span={12}>
                <FormItem label="Nota fiscal" name="notaFiscalNumber">
                  <Input
                    aria-label="Nota fiscal"
                    size="large"
                    placeholder="Número da NF"
                    disabled={isDisabled('notaFiscalNumber')}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  hasMax={false}
                  name="notaFiscalIssueDate"
                  label="Data de emissão da NF"
                >
                  <MaskedDatePicker
                    placeholder="dd/mm/aaaa"
                    aria-label="Data de emissão da NF"
                    className={styles.datePicker}
                    size="large"
                    format="dd/MM/yyyy"
                    suffixIcon={<CalendarOutlined />}
                    disabled={isDisabled('notaFiscalIssueDate')}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  hasMax={false}
                  name="dueDate"
                  label="Data de vencimento"
                >
                  <MaskedDatePicker
                    placeholder="dd/mm/aaaa"
                    aria-label="Data de vencimento"
                    className={styles.datePicker}
                    size="large"
                    format="dd/MM/yyyy"
                    suffixIcon={<CalendarOutlined />}
                    disabled={isDisabled('dueDate')}
                  />
                </FormItem>
              </Col>
            </>
          )}

          {type === BalanceType.CREDIT && (
            <NotaFiscalField
              notaFiscalId={notaFiscalId}
              entryId={id}
              isFinancialEntry={isFinancialEntry}
            />
          )}

          <Col span={24}>
            <Form.Item name="attachments" label="Anexos">
              <UploadAttachments
                form={form}
                organizationId={organizationId}
                companyId={companyId}
                disabled={isDisabled('attachmentsIds')}
              />
            </Form.Item>
          </Col>

          <Col span={24} className={styles.bottomContainer}>
            <FormItem hasMax={false} name="comments" label="Observação">
              <Input.TextArea
                size="large"
                autoSize={{ minRows: 2, maxRows: 12 }}
                disabled={isDisabled('comments')}
              />
            </FormItem>
          </Col>
        </Row>

        {!hideFooter && (
          <DrawerFooter>
            {footer}
            {type === BalanceType.CREDIT && (
              <>
                {!hiddenActions.includes('createNotaFiscalFromReceivable') && (
                  <CreateNotaFiscalFromReceivableButton
                    disabled={disabled}
                    isFinancialEntry={isFinancialEntry}
                  />
                )}

                {!hiddenActions.includes('createReceivableWithNotaFiscal') && (
                  <CreateReceivableWithNotaFiscalButton
                    loading={loading}
                    disabled={disabled}
                    contact={contact}
                    onCreateReceivable={
                      onSubmit ? (params) => onSubmit(params, true) : undefined
                    }
                  />
                )}
              </>
            )}
            <DrawerFooter.SubmitButton loading={loading} disabled={disabled}>
              {submitButtonText}
            </DrawerFooter.SubmitButton>
          </DrawerFooter>
        )}
      </Form>
    </>
  );
}

export default EntryForm;
