import { Divider, MenuList } from '@mui/material';
import { ContextItem } from 'src/mui/_scss/Table/GridContextMenu';
import type { ContextMenuInfo } from 'src/mui/_scss/Table/types';

import { Tooltip } from 'src/mui';

import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import styles from './ContextMenuRowActions.module.scss';
import { useRowActions } from './useRowActions';

type RowActionsProps = {
  info: ContextMenuInfo<FinancialStatementEntry>;
  onClose: () => void;
};

export default function ContextMenuRowActions({
  info,
  onClose,
}: RowActionsProps) {
  const { actionsForRow } = useRowActions(info.row);

  const actions = actionsForRow();

  return (
    <MenuList>
      {actions.map((action, index) => (
        <div key={action.name}>
          <Tooltip
            title={action.tooltipTitle}
            placement="top"
            disableHoverListener={!action.tooltipActive}
            className={styles.tooltip}
          >
            <ContextItem
              {...action}
              disabled={action.tooltipActive}
              onClose={onClose}
              key={action.name}
            />
          </Tooltip>
          {action.dividerAfter && actions[index + 1] !== undefined && (
            <Divider variant="fullWidth" />
          )}
        </div>
      ))}
    </MenuList>
  );
}
