import type { RcFile } from 'antd/lib/upload';
import t from 'zod';

import type { scopes } from 'src/libs/finbits/Bank/OpenBanking/enum';

export type GetCurrentStepParams = {
  accountId?: string;
  companyId?: string;
  organizationId?: string;
};

export type PostBBCertificateParams = {
  accountId: string;
  certificate: RcFile;
  companyId?: string;
  organizationId: string;
  password: string;
};

export type CreateBBConsentmentsParams = {
  accountId: string;
  basicAuth: string;
  clientId: string;
  clientSecret: string;
  companyId?: string;
  contractNumber: string;
  developerApplicationKey: string;
  document: string;
  organizationId: string;
  scope: scopes;
};

export const BBConsentmentsCurrentStepDecoder = t.object({
  currentStep: t.string(),
});

export type BBConsentmentsCurrentStepParams = t.TypeOf<
  typeof BBConsentmentsCurrentStepDecoder
>;

export const BBConsentmentsCertificateDecoder = t.string();

export type BBConsentmentsCertificateParams = t.TypeOf<
  typeof BBConsentmentsCertificateDecoder
>;
