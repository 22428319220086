import { useEffect, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Typography } from 'src/design-system/components';
import { FormField, Input } from 'src/mui/_scss';

import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import { useCreateSantanderConsentments } from 'src/libs/finbits/Bank/SantanderConsentments/SantanderConsentments';
import { TradeName } from 'src/libs/finbits/Bank/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import AutoPaymentModal from '../AutoPaymentModal';
import AutoPaymentCard from '../AutoPaymentModal/AutoPaymentCard';

import { SantanderAutoPaymentResolver } from './validations';

type Props = PortalProps & {
  account: Account;
};

function errorMessage(key: string) {
  return (
    {
      missing_pagfor: {
        title: 'Convênio PAGFOR não contratado',
        description:
          'É necessário ter o convênio do PAGFOR contratado para realizar a conexão de pagamentos automáticos do Banco Santander.',
      },
    }[key] || {
      title: 'A credencial informada está incorreta.',
      description:
        'Por favor, verifique o Client ID e o Client Secret obtidos no Portal do Santander e tente novamente.',
    }
  );
}

export default function SantanderAutoPayment({
  onClose,
  onExit,
  open = false,
  account,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const { connectSantanderConsentments, isLoading } =
    useCreateSantanderConsentments();
  const [isInvalidCredentials, setIsInvalidCredentials] =
    useState<boolean>(false);
  const [errorType, setErrorType] = useState('');

  const form = useForm({
    resolver: zodResolver(SantanderAutoPaymentResolver),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      clientId: '',
      clientSecret: '',
    },
  });

  async function handleRegister() {
    const isValid = await form.trigger();

    if (isValid) {
      const { clientId, clientSecret } = form.getValues();

      connectSantanderConsentments(
        {
          organizationId,
          companyId,
          accountId: account.id,
          clientId,
          clientSecret,
          scope: 'payments',
        },
        {
          onSuccess: () => {
            onClose();
            snackbar({
              variant: 'success',
              message: `Sua conta da instituição ${account.name} foi conectada para atualização automática das transações.`,
              title: 'Conta bancária conectada com sucesso',
            });
          },

          onError: (error) => {
            if (error.response?.status === 422) {
              const { errors } = error?.response?.data;
              setErrorType(errors || '');
              setIsInvalidCredentials(true);
              return;
            }

            onClose();
            snackbar({
              variant: 'error',
              message:
                'Infelizmente, a conexão com o Banco Santander falhou. Por favor, tente novamente ou fale com nosso suporte.',
              title: 'Não foi possível realizar a conexão',
            });
          },
        }
      );
    }
  }

  useEffect(() => {
    if (isLoading) {
      setIsInvalidCredentials(false);
    }
  }, [isLoading]);

  return (
    <AutoPaymentModal
      account={account}
      bankTradeName={TradeName.SANTANDER}
      disableConfirmButton={isLoading}
      errorTitle={errorMessage(errorType).title}
      errorDescription={errorMessage(errorType).description}
      hasInvalidCredentials={isInvalidCredentials}
      onClose={onClose}
      onConfirmConnection={handleRegister}
      open={open}
      onExit={onExit}
    >
      <AutoPaymentCard
        stepIndex={1}
        buttonLabel="Acessar Santander"
        heading="Acesse o Portal do Desenvolvedor Santander."
        buttonHref="https://developer.santander.com.br/login"
        buttonTarget="_blank"
      >
        <Typography color="grey.600">
          Para fazer login, use os dados de acesso da sua conta PJ (usuário
          master ou secundário).
        </Typography>

        <Typography color="grey.600" fontWeight="600" variant="text-xs">
          Obs.: Requer o convênio PAGFOR contratado.
        </Typography>
      </AutoPaymentCard>

      <AutoPaymentCard
        stepIndex={2}
        buttonLabel="Ver passo a passo"
        heading="Siga o passo a passo abaixo no portal do Santander:"
        buttonHref="https://www.finbits.com.br/central-de-ajuda/sua-conexao-santander"
        buttonTarget="_blank"
      >
        <Typography color="grey.600">
          Você irá autorizar a conexão do banco com a FinBits.
        </Typography>

        <Typography color="grey.600">
          Ao final deste processo, copie o <strong>Client ID</strong> e o{' '}
          <strong>Client Secret</strong> que serão exibidos na tela.
        </Typography>
      </AutoPaymentCard>

      <AutoPaymentCard
        stepIndex={3}
        heading="Informe o Client ID e o Client Secret obtidos e confirme:"
      >
        <Box
          component="form"
          gap={8}
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <FormProvider {...form}>
            <FormField name="clientId" label="Client ID">
              {(field) => (
                <Input {...field} placeholder="Informe o Client ID recebido" />
              )}
            </FormField>

            <FormField name="clientSecret" label="Client Secret">
              {(field) => (
                <Input
                  {...field}
                  placeholder="Informe o Client Secret recebido"
                />
              )}
            </FormField>
          </FormProvider>
        </Box>
      </AutoPaymentCard>
    </AutoPaymentModal>
  );
}
