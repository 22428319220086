import type { MouseEvent, ReactNode } from 'react';
import { useMemo, useState } from 'react';

import { Divider, MenuItem, MenuList } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronDownIcon } from 'src/mui/_icons';
import { Rotate } from 'src/mui/_transitions';
import { Typography } from 'src/design-system/components';

import type { RouteKey } from 'src/router/types';
import { generateCompanyPath } from 'src/router/routes';
import { useCurrentRoute } from 'src/router/useCurrentRoute';

import { Popper } from 'src/mui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import {
  WithAllowedPermission,
  useAllowedPermission,
} from 'src/libs/finbits/Permissions';
import type { Permissions } from 'src/libs/finbits/Permissions/types';

import styles from './NavigationItem.module.scss';

type Props = {
  children: ReactNode;
  routeKey: RouteKey;
  permission: Permissions;
  disabled?: boolean;
  options?: {
    label: string;
    dividerAfter?: boolean;
    routeKey?: RouteKey;
    permission?: Permissions;
    disabled?: boolean;
  }[];
};

export default function NavigationItem({
  children,
  routeKey,
  permission,
  options = [],
  disabled = false,
  ...props
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { companyId, organizationId } = useCompanyParams();
  const currentRoute = useCurrentRoute();

  const havePermission = useAllowedPermission(permission);

  const navigate = useNavigate();

  const to = generateCompanyPath(routeKey, {
    companyId,
    organizationId,
  });

  const isVisibility = !!anchorEl;

  const availableOptions = options.filter((option) => !option.disabled);

  const selectedItem = useMemo(() => {
    if (currentRoute?.id === routeKey) {
      return routeKey;
    }

    return availableOptions?.find(
      (option) => currentRoute?.id === option.routeKey
    )?.routeKey;
  }, [currentRoute, availableOptions, routeKey]);

  function handleMouseEnter(event: MouseEvent<any>) {
    setAnchorEl(event.currentTarget);
  }

  function handleMouseLeave() {
    setAnchorEl(null);
  }

  function handleClick() {
    if (!havePermission || availableOptions.length > 0) return;

    navigate(to);
  }

  if (disabled) {
    return null;
  }

  if (!havePermission && availableOptions.length === 0) {
    return null;
  }

  return (
    <MenuItem
      tabIndex={0}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      selected={!!selectedItem}
      className={styles.menuItem}
      {...props}
    >
      <Typography fontWeight="500">{children}</Typography>

      {availableOptions.length > 0 && (
        <>
          <Rotate in={isVisibility} className={styles.menuItemArrow}>
            <ChevronDownIcon />
          </Rotate>
          <Popper
            open={isVisibility}
            anchorEl={anchorEl}
            placement="bottom-start"
            disablePortal
            onClick={(e) => e.stopPropagation()}
          >
            <MenuList className={styles.menuList}>
              {availableOptions.map((option) => {
                const routeKey = option.routeKey;
                if (!routeKey) {
                  return (
                    <MenuItem key={`section-${option.label}`} disabled>
                      <Typography
                        color="grey.400"
                        fontWeight="500"
                        variant="text-xs"
                      >
                        {option.label}
                      </Typography>
                    </MenuItem>
                  );
                }

                const to = generateCompanyPath(routeKey, {
                  companyId,
                  organizationId,
                });

                return (
                  <WithAllowedPermission
                    key={routeKey}
                    permissions={option.permission}
                  >
                    <>
                      <MenuItem
                        component={Link}
                        to={to}
                        selected={selectedItem === option.routeKey}
                      >
                        <Typography
                          color="grey.700"
                          fontWeight="500"
                          variant="text-sm"
                        >
                          {option.label}
                        </Typography>
                      </MenuItem>

                      {option?.dividerAfter && <Divider />}
                    </>
                  </WithAllowedPermission>
                );
              })}
            </MenuList>
          </Popper>
        </>
      )}
    </MenuItem>
  );
}
