import t from 'zod';

export const GettingStartedResolver = t.object({
  filePasswordCertificate: t.boolean().refine((val) => val === true, {
    message: 'Por favor, confirme que você tem o convênio PAG contratado.',
  }),
  servicePaymentAgreement: t.boolean().refine((val) => val === true, {
    message:
      'Por favor, confirme que você possui o arquivo e a senha do Certificado A1.',
  }),
});
