import { useDrawingArea } from '@mui/x-charts-pro';

import Img from 'src/assets/empty-chart.svg';

import styles from './EmptyState.module.scss';

const IMG_HEIGHT = 85;
const IMG_WIDTH = 132;
const Y_AXIS_HALF_WIDTH = 25;

export default function EmptyState() {
  const { width, height } = useDrawingArea();

  const offsetX = width / 2 - Y_AXIS_HALF_WIDTH;
  const offsetY = height / 2 - IMG_HEIGHT;

  return (
    <>
      <image
        height={IMG_HEIGHT}
        width={IMG_WIDTH}
        x={offsetX}
        y={offsetY}
        xlinkHref={Img}
      />
      <text
        y={offsetY + 120}
        x={offsetX - 20}
        className={styles.emptyLegend}
        textAnchor="start"
      >
        Sem dados para exibir
      </text>
      <text
        y={offsetY + 130}
        x={offsetX - 60}
        className={styles.emptyDescription}
      >
        <tspan dy="24px">A busca ou filtro aplicado não retornou</tspan>
        <tspan dy="24px" x={offsetX - 30}>
          nenhum dado a ser visualizado.
        </tspan>
      </text>
    </>
  );
}
