import { Link, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Typography } from 'src/design-system/components';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import { format } from 'src/libs/finbits/Date';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useOverviewTotals } from 'src/libs/finbits/Overview';

import { MoreInformation } from './MoreInformation';
import TotalCard from './TotalCard';

type Props = {
  startDate: Date;
  endDate: Date;
  accountsIds: string[];
  showMoreDetails?: boolean;
};

export default function Totals({
  startDate,
  endDate,
  accountsIds,
  showMoreDetails,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const { totals, isLoading } = useOverviewTotals({
    companyId,
    organizationId,
    accountsIds,
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
  });

  if (isLoading) {
    return (
      <Stack direction="row" gap={6} marginBlock={4}>
        <TotalCard.Skeleton />
        <TotalCard.Skeleton />
        <TotalCard.Skeleton />
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap={6} marginBlock={4}>
      <TotalCard
        title="Recebimentos realizados"
        value={totals?.creditAmount ?? 0}
        iconDirection="up"
        showMoreDetails={showMoreDetails}
        bottom={
          <Link
            component={RouterLink}
            variant="text-sm"
            to={generateCompanyPath(RouteKey.ENTRIES, {
              companyId,
              organizationId,
            })}
            fontWeight="600"
            color="grey.600"
          >
            Ver todas as entradas
          </Link>
        }
      >
        <MoreInformation
          title="Entradas não consideradas"
          body={
            <Typography variant="text-xs" marginTop={2}>
              Valor total não categorizado e de{' '}
              <Link
                component={RouterLink}
                variant="text-xs"
                underline="always"
                color="grey.600"
                to={generateCompanyPath(RouteKey.CATEGORIES, {
                  companyId,
                  organizationId,
                })}
              >
                categorias
              </Link>{' '}
              de entrada que estão sendo desconsideradas na visualização do
              fluxo de caixa.
            </Typography>
          }
          value={totals?.creditAmountNotIncluded ?? 0}
        />
      </TotalCard>
      <TotalCard
        title="Pagamentos realizados"
        value={totals?.debitAmount ?? 0}
        iconDirection="down"
        showMoreDetails={showMoreDetails}
        bottom={
          <Link
            component={RouterLink}
            to={generateCompanyPath(RouteKey.ENTRIES, {
              companyId,
              organizationId,
            })}
            variant="text-sm"
            fontWeight="400"
            color="grey.600"
          >
            Ver todas as saídas
          </Link>
        }
      >
        <MoreInformation
          title="Saídas não consideradas"
          body={
            <Typography
              variant="text-xs"
              marginTop={2}
              color="grey.600"
              fontWeight={400}
            >
              Valor total não categorizado e de{' '}
              <Link
                component={RouterLink}
                variant="text-xs"
                underline="always"
                color="grey.600"
                to={generateCompanyPath(RouteKey.CATEGORIES, {
                  companyId,
                  organizationId,
                })}
              >
                categorias
              </Link>{' '}
              de saída que estão sendo desconsideradas na visualização do fluxo
              de caixa.
            </Typography>
          }
          value={totals?.debitAmountNotIncluded ?? 0}
        />
      </TotalCard>
      <TotalCard
        title="Resultado do período"
        value={totals?.resultAmount ?? 0}
        showMoreDetails={showMoreDetails}
      >
        <Stack direction="row" gap={6}>
          <MoreInformation
            title="Caixa atual"
            body={
              <Typography variant="text-xs" marginTop={2}>
                Saldo bancário total desta empresa na data de hoje.
              </Typography>
            }
            value={totals?.currentBalance ?? 0}
          />
          <MoreInformation
            title="Caixa ao final do período"
            body={
              <Typography variant="text-xs" marginTop={2}>
                Projeção do saldo total ao final do período selecionado.
              </Typography>
            }
            value={totals?.periodBalance ?? 0}
          />
        </Stack>
      </TotalCard>
    </Stack>
  );
}
