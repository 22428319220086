import { useEffect, useState } from 'react';

import cn from 'classnames';
import { Button, Col, Form, Input, Row } from 'antd';
import type { RcFile } from 'rc-upload/lib/interface';
import type { FieldData } from 'rc-field-form/lib/interface';
import { Link } from '@mui/material';
import { Alert, AlertTitle } from 'src/design-system/components';

import { DrawerFooter, FormItem } from 'src/ui';

import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';
import { isRcFile } from 'src/libs/finbits/File';
import { toggleChat } from 'src/libs/messageBird';

import type { StepProps } from '../types';
import StepInformation from '../StepInformation';

import UploadDigitalCertificate from './UploadDigitalCertificate';
import styles from './DigitalCertificateStep.module.scss';

type Props = {
  certificateExpiration?: string | null;
  onClickSendLater?: () => void;
  isButtonSendLaterLoading?: boolean;
} & StepProps;

export default function DigitalCertificateStep({
  form,
  errors,
  isButtonLoading = false,
  isButtonSendLaterLoading = false,
  initialValues,
  buttonText = 'Concluir',
  showTitle = true,
  certificateExpiration,
  onSubmit,
  onPrevious,
  onResolveError,
  onClickSendLater,
}: Props) {
  const [file, setFile] = useState<RcFile | undefined>(initialValues?.file);

  const isAttached = !!file;
  const isSendLaterEnabled = !!onClickSendLater;

  const { isEnabled: isCertificationAttachEnabled } = useExternalFeatureFlag(
    Feature.NOTA_FISCAL_CERTIFICATION_ATTACH
  );

  useEffect(() => {
    if (errors) {
      form.setFields(errors);
    }
  }, [form, errors]);

  function handleAttachFile(file: string | Blob | RcFile) {
    if (isRcFile(file)) {
      setFile(file);

      form.setFieldsValue({
        file,
      });
    }
  }

  function handleDetachFile() {
    setFile(undefined);
    form.resetFields(['file']);
  }

  return (
    <>
      <StepInformation
        title={showTitle ? 'Certificado digital' : undefined}
        description="A prefeitura da sua cidade exige o Certificado Digital e-CNPJ A1 como credencial de acesso para emissão das notas fiscais."
      />
      {!isCertificationAttachEnabled && (
        <Alert severity="warning" aria-label="alerta certificado desabilitado">
          <AlertTitle>
            O envio automático de certificados digitais está indisponível no
            momento. <br />
            Para enviar seu certificado, entre em contato conosco pelo{' '}
            <Link
              color="inherit"
              underline="always"
              className={styles.link}
              onClick={() => toggleChat()}
            >
              suporte
            </Link>
            .
          </AlertTitle>
        </Alert>
      )}

      <Form
        preserve
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        onFieldsChange={(changedFields: FieldData[]) => {
          changedFields.forEach(({ name }) => {
            onResolveError && onResolveError(name);
          });
        }}
      >
        <Row
          className={cn(styles.formWrapper, {
            [styles.noMarginTop]: !isCertificationAttachEnabled,
          })}
        >
          <Col span={24}>
            <Form.Item name="file" hidden>
              <Input readOnly />
            </Form.Item>
            <Form.Item shouldUpdate>
              {({ getFieldError }) => (
                <UploadDigitalCertificate
                  isAttached={isAttached}
                  errorMessage={getFieldError('file')[0]}
                  certificateExpiration={certificateExpiration}
                  onDetachFile={handleDetachFile}
                  onAttachFile={handleAttachFile}
                  isDisabled={!isCertificationAttachEnabled}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <FormItem
              required
              name="password"
              label="Senha do certificado"
              hidden={!isAttached}
            >
              <Input.Password role="textbox" size="large" />
            </FormItem>
          </Col>
        </Row>

        <DrawerFooter>
          {isSendLaterEnabled && (
            <Button
              size="large"
              htmlType="button"
              className={styles.sendLaterButton}
              loading={isButtonSendLaterLoading}
              onClick={onClickSendLater}
            >
              Quero enviar depois
            </Button>
          )}

          {onPrevious && <DrawerFooter.BackButton onClick={onPrevious} />}
          <DrawerFooter.SubmitButton
            loading={isButtonLoading}
            disabled={!isAttached}
          >
            {buttonText}
          </DrawerFooter.SubmitButton>
        </DrawerFooter>
      </Form>
    </>
  );
}
