import type { ReactNode } from 'react';
import { useState } from 'react';

import { Alert, Chip } from 'src/design-system/components';

import { getItem, setItem } from 'src/libs/finbits/Storage';

import styles from './NewFeatureBanner.module.scss';

type Props = {
  infoMessage: string;
  storageKey: string;
  companyId: string;
  children: ReactNode;
};

function buildStorageKey(storageKey: string, companyId: string) {
  return `${storageKey}:${companyId}`;
}

function getStoredValue(storageKey: string, companyId: string) {
  const storage = getItem<Storage>(buildStorageKey(storageKey, companyId));

  return storage?.active !== false;
}

export default function NewFeatureBanner({
  infoMessage,
  storageKey,
  companyId,
  children,
}: Props) {
  const [active, setIsActive] = useState(getStoredValue(storageKey, companyId));

  function handleClose() {
    setItem(buildStorageKey(storageKey, companyId), { active: false });
    setIsActive(false);
  }

  if (!active) return null;

  return (
    <Alert
      className={styles.alert}
      severity="default"
      icon={<Chip label={infoMessage} className={styles.chip} square />}
      onClose={handleClose}
    >
      {children}
    </Alert>
  );
}
