import type { Components } from '@mui/material';

export function createMuiToggleButtonGroup(): Components['MuiToggleButtonGroup'] {
  return {
    styleOverrides: {
      grouped: {
        textTransform: 'unset',
      },
    },
  };
}
