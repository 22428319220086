import { useOpenPortal } from 'src/ui';

import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import EditReceivableDrawer from 'src/features/receivables/EditReceivableDrawer/EditReceivableDrawer';
import EditFinancialEntryDrawer from 'src/features/entries/Drawers/FinancialEntry/EditFinancialEntryDrawer';
import EditBillModal from 'src/features/bills-to-pay/EditBillModal';

export default function useOpenEntryEditDrawer() {
  const openPortal = useOpenPortal();

  function openEntryEditDrawer(entry: FinancialStatementEntry) {
    if (entry.entryType === EntryType.FINANCIAL_ENTRY) {
      openPortal(EditFinancialEntryDrawer, {
        financialEntryId: entry.id,
        isSplit: !!entry.isSplit,
      });
    }

    if (entry.entryType === EntryType.BILL) {
      openPortal(EditBillModal, {
        billId: entry.id,
      });
    }

    if (entry.entryType === EntryType.RECEIVABLE) {
      openPortal(EditReceivableDrawer, { id: entry.id });
    }
  }

  return openEntryEditDrawer;
}
