import { Button } from '@mui/material';
import type {
  GridApi,
  GridRowModel,
  GridUpdateAction,
} from '@mui/x-data-grid-premium';
import type { SnackbarOrigin } from 'notistack';
import { CheckIcon, TrashIcon } from 'src/mui/_icons';

import { Tooltip, snackbar } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';

import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';
import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';
import { useDeleteManyEntries } from 'src/libs/finbits/Management/EntriesDeleteMany';
import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import useMarkAsFinancialDoneAction from 'src/features/entries/Actions/mark-financial-done-action/hooks/useMarkAsFinancialDoneAction';

import AmountTotal from './AmountTotal/AmountTotal';
import { buildEntriesIdsToDelete, nonDeletableMessage } from './deleteRows';
import styles from './ToolbarSelectionActions.module.scss';

type Props = {
  selecteds: GridRowModel<FinancialStatementEntry>[];
  apiRef: GridApi;
};

const snackbarAnchorOrigin: SnackbarOrigin = {
  horizontal: 'center',
  vertical: 'bottom',
};

export default function ToolbarSelectionActions({ selecteds, apiRef }: Props) {
  const openConfirmDialog = useOpenConfirmDialog();
  const { organizationId, companyId } = useCompanyParams();
  const { deleteMany } = useDeleteManyEntries();

  const { isLoading, markAsFinancialDone } = useMarkAsFinancialDoneAction({
    entries: selecteds as FinancialStatementEntry[],
  });

  const { isEnabled: isMarkBatchReleasesEnabled } = useExternalFeatureFlag(
    Feature.MARK_BATCH_RELEASES
  );

  function deleteSelection() {
    deleteMany(
      { organizationId, companyId, ...buildEntriesIdsToDelete(selecteds) },
      {
        onSuccess: () => {
          const rowsToRemove: Array<{ id: string; _action: GridUpdateAction }> =
            selecteds.map((s) => ({
              id: s.id,
              _action: 'delete',
            }));

          apiRef.updateRows(rowsToRemove);

          snackbar({
            variant: 'success',
            anchorOrigin: snackbarAnchorOrigin,
            message: 'Os lançamentos foram deletados com sucesso',
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            anchorOrigin: snackbarAnchorOrigin,
            message: 'Falha ao excluir lançamentos',
          });
        },
      }
    );
  }

  const isMarkAsDoneEnabled = selecteds.every(
    (entry) =>
      new Date(entry.date) <= new Date() &&
      entry.availableActions.includes(AvailableActions.MARK_AS_FINANCIAL_ENTRY)
  );

  function handleDeleteSelection() {
    openConfirmDialog({
      variant: 'error',
      icon: <TrashIcon />,
      title: 'Tem certeza que deseja excluir?',
      confirmText: 'Sim, quero excluir',
      cancelText: 'Cancelar',
      onConfirm: deleteSelection,
      content: (
        <>
          As informações serão excluídas permanentemente. <br /> <br />
          {nonDeletableMessage(selecteds)}
        </>
      ),
    });
  }

  return (
    <>
      {isMarkBatchReleasesEnabled && (
        <WithAllowedPermission
          permissions={{
            resource: 'financialEntries',
            action: 'create_from_receivable',
          }}
        >
          <Tooltip
            title="Somente lançamentos de contas bancárias manuais com data de pagamento até hoje podem ser marcados como realizados."
            placement="top"
            disableHoverListener={isMarkAsDoneEnabled}
          >
            <Button
              variant="text"
              startIcon={<CheckIcon />}
              onClick={markAsFinancialDone}
              disabled={!isMarkAsDoneEnabled || isLoading}
              className={styles.button}
            >
              Marcar como realizado
            </Button>
          </Tooltip>
        </WithAllowedPermission>
      )}

      <WithAllowedPermission
        permissions={{
          resource: 'entries',
          action: 'delete_many',
        }}
      >
        <Button
          variant="text"
          startIcon={<TrashIcon />}
          onClick={handleDeleteSelection}
        >
          Excluir
        </Button>
      </WithAllowedPermission>

      <AmountTotal selecteds={selecteds} />
    </>
  );
}
