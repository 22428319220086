import { add } from 'date-fns';

import { BillFrequencyRecurrence } from 'src/libs/finbits/Bills/types';

type Fields = {
  initialDate: Date;
  frequencyInterval: string;
  quantity: string;
};

export function getEndDate({
  initialDate,
  frequencyInterval,
  quantity,
}: Fields) {
  // We need to consider initial date as the first recurrence count,
  // so we need to remove it from the calcululation,
  const repetitionFix = parseInt(quantity) - 1;

  if (frequencyInterval === BillFrequencyRecurrence.DAILY) {
    return add(initialDate, { days: repetitionFix });
  }

  if (frequencyInterval === BillFrequencyRecurrence.WEEKLY) {
    return add(initialDate, { weeks: repetitionFix });
  }

  if (frequencyInterval === BillFrequencyRecurrence.BIWEEKLY) {
    const BIWEEKLY_IN_WEEKS = 2;
    return add(initialDate, { weeks: repetitionFix * BIWEEKLY_IN_WEEKS });
  }

  if (frequencyInterval === BillFrequencyRecurrence.MONTHLY) {
    return add(initialDate, { months: repetitionFix });
  }

  if (frequencyInterval === BillFrequencyRecurrence.QUARTERLY) {
    const QUATERLY_IN_MONTHS = 3;
    return add(initialDate, {
      months: repetitionFix * QUATERLY_IN_MONTHS,
    });
  }

  if (frequencyInterval === BillFrequencyRecurrence.YEARLY) {
    return add(initialDate, { years: repetitionFix });
  }

  return initialDate;
}

type RecurringParams = {
  date: Date;
  endDate?: Date | null;
  frequencyInterval?: string | null;
  quantity?: string | null;
};

type Props<FormParams> = {
  formParams: FormParams;
  formParser: Function;
  inboxItemId?: string;
};

type ReturnPayload<SubmitParams> = {
  entries: SubmitParams[];
};

export function buildRecurrencePayload<
  FormParams extends RecurringParams,
  SubmitParams
>({
  formParams,
  formParser,
  inboxItemId,
}: Props<FormParams>): ReturnPayload<SubmitParams> {
  function generateEntry(_: undefined[], index: number) {
    const isFirstBill = index === 0;
    const isLastBill = index === Number(formParams.quantity) - 1;

    if (!formParams.endDate) {
      throw new Error(
        `End date is missing at buildRecurrencePayload: Start Date: ${formParams.date}, End Date: ${formParams.endDate}, Frequency Interval: ${formParams.frequencyInterval}`
      );
    }

    let newDate = formParams.date;
    if (isFirstBill) {
      newDate = formParams.date;
    } else if (isLastBill) {
      newDate = formParams.endDate;
    } else {
      newDate = getEndDate({
        initialDate: formParams.date,
        frequencyInterval: formParams.frequencyInterval!,
        quantity: (index + 1).toString(),
      });
    }

    return {
      ...formParser(formParams),
      date: newDate,
      inboxItemsIds: inboxItemId ? [inboxItemId] : [],
    };
  }

  const entries = Array.from(
    { length: Number(formParams.quantity) },
    generateEntry
  );

  return {
    entries,
  };
}
