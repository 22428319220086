import { useMutation, useQueryClient } from 'react-query';

import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import { authenticatedAPI } from 'src/libs/finbits/client';

import type { CreateInterConsentmentsParams } from './types';

async function connectInterConsentments({
  organizationId,
  companyId,
  ...params
}: CreateInterConsentmentsParams) {
  const formData = new FormData();
  formData.append('accountId', params.accountId);
  formData.append('clientId', params.clientId);
  formData.append('clientSecret', params.clientSecret);
  formData.append('certCrt', params.certCrt);
  formData.append('certKey', params.certKey);

  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/inter_consentments`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );

  return response.data;
}

export function useCreateInterConsentments() {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation<
    CreateInterConsentmentsParams,
    ApiError<ApiErrorForm>,
    CreateInterConsentmentsParams
  >(connectInterConsentments, {
    onSuccess: ({ accountId }) => {
      queryClient.invalidateQueries({
        queryKey: ['openBankingConnections', { accountId }],
      });
    },
  });
  return { connectInterConsentments: mutate, ...rest };
}
