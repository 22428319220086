import type { ColorSystemOptions } from '@mui/material';
import { common } from '@mui/material/colors';
import {
  brand,
  error,
  grey,
  info,
  primary,
  secondary,
  success,
  warning,
} from 'src/theme/colors';

export const light: ColorSystemOptions = {
  palette: {
    grey,
    primary,
    secondary,
    error,
    info,
    success,
    warning,
    divider: grey[200],
    common,
    brand,
    action: {
      selected: grey[100],
      hover: grey[100],
      disabled: grey[300],
    },
    toolbar: {
      action: {
        active: primary.light,
        disabled: grey[300],
      },
    },
    background: {
      default: common.white,
      paper: common.white,
      secondary: grey[100],
    },
    text: {
      primary: grey[900],
      secondary: grey[600],
      disabled: grey[400],
    },
    highlight: {
      primary: warning[100],
    },
  },
};
