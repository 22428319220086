import { useEffect, useState } from 'react';

import { Chip } from 'src/design-system/components';

import type {
  Account,
  SynchronizeType,
} from 'src/libs/finbits/Bank/Accounts/types';

import SynchronizeTypeBadge from 'src/features/accounts/SynchronizeTypeBadge';

type FilterChipPropsProps = {
  accounts: Account[];
  filterSelected?: SynchronizeType;
  label: string;
  onSetFilter: (filter?: SynchronizeType) => void;
  selectedAccountIds: string[];
  synchronizeType: SynchronizeType;
};

export default function QuickFilterChip({
  accounts,
  filterSelected,
  label,
  onSetFilter,
  selectedAccountIds,
  synchronizeType,
}: FilterChipPropsProps) {
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    if (filterSelected === synchronizeType) {
      setActive(true);
      return;
    }

    setActive(false);
  }, [filterSelected, synchronizeType]);

  useEffect(() => {
    const accountIdsSet = new Set(
      accounts
        .filter((acc) => acc.synchronizeType === synchronizeType)
        .map((acc) => acc.id)
    );

    const allIdsExist =
      accountIdsSet.size > 0 &&
      accountIdsSet.size === selectedAccountIds.length &&
      selectedAccountIds.every((id) => accountIdsSet.has(id));

    setActive(allIdsExist);
  }, [selectedAccountIds, accounts, synchronizeType]);

  function handleClick() {
    if (isActive) {
      handleDelete();
      return;
    }

    onSetFilter(synchronizeType);
    setActive(true);
  }

  function handleDelete() {
    onSetFilter();
    setActive(false);
  }

  return (
    <Chip
      label={label}
      size="small"
      variant={isActive ? 'filled' : 'outlined'}
      onClick={handleClick}
      onDelete={isActive ? handleDelete : undefined}
      color="secondary"
      icon={<SynchronizeTypeBadge synchronizeType={synchronizeType} />}
    />
  );
}
