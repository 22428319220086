import { Box, Fade } from '@mui/material';
import { Alert, AlertTitle } from 'src/design-system/components';
import { SparklesIcon } from 'src/mui/_icons';

import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import { useAutofillSuggestionsContext } from 'src/features/entries/Modals/EntryModal/autofill-suggestions/AutofillSuggestionsProvider';

export default function SuggestionsAppliedAlert() {
  const { isEnabled } = useExternalFeatureFlag(Feature.AI_AUTOFILL_SUGGESTIONS);

  const { suggestionApplied } = useAutofillSuggestionsContext();

  if (!isEnabled) return null;

  return (
    <Fade in={suggestionApplied} mountOnEnter>
      <Box>
        <Alert severity="success" icon={<SparklesIcon color="success" />}>
          <AlertTitle marginBottom={0}>
            As sugestões de preenchimento foram aplicadas.
          </AlertTitle>
        </Alert>
      </Box>
    </Fade>
  );
}
