import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert, AlertTitle, Typography } from 'src/design-system/components';
import { FormField } from 'src/mui/_scss';
import TextFieldPassword from 'src/mui/TextField/TextFieldPassword';

import { useUploadCertificateBBConsentments } from 'src/libs/finbits/Bank/BBConsentments';
import { useAutoDownloadFile } from 'src/libs/finbits/File/useAutoDownloadFile';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import UploadDigitalCertificate from 'src/features/open-banking/AutoPayment/AutoPaymentModal/UploadDigitalCertificate';
import { useBBAutoPaymentContext } from 'src/features/open-banking/AutoPayment/BBAutoPayment/BBAutoPaymentProvider';
import type { StepperContentProps } from 'src/features/open-banking/AutoPayment/VerticalStepper/types';

import styles from './CertificateUploaderStep.module.scss';
import type { CertificateUploaderType } from './types';
import { CertificateUploaderResolver } from './validations';

export default function CertificateUploaderStep({
  onNext,
  onPrev,
  account,
}: StepperContentProps) {
  const { certificateBBConsentments, isLoading, isError } =
    useUploadCertificateBBConsentments();
  const { companyId, organizationId } = useCompanyParams();
  const autoDownloadFile = useAutoDownloadFile();
  const { setCertificateChain } = useBBAutoPaymentContext();

  const form = useForm<CertificateUploaderType>({
    resolver: zodResolver(CertificateUploaderResolver),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      certificate: [],
      password: '',
    },
  });

  async function handleNextStep(event: React.MouseEvent) {
    const isValid = await form.trigger();

    if (!isValid) return;

    const { password, certificate } = form.getValues();

    certificateBBConsentments(
      {
        organizationId,
        companyId,
        accountId: account!!.id,
        certificate: certificate[0],
        password,
      },
      {
        onSuccess: (file) => {
          setCertificateChain(file);
          autoDownloadFile({
            file,
            filename: 'cadeia-de-certificados.pem',
            type: 'application/octet-stream',
          });
          onNext?.(event);
        },
      }
    );
  }

  return (
    <>
      {isError && (
        <Alert
          severity="error"
          variant="standard"
          className={styles.alertError}
        >
          <AlertTitle>A senha do Certificado A1 está incorreta.</AlertTitle>
          Por favor, verifique se digitou corretamente e tente novamente.
        </Alert>
      )}

      <FormProvider {...form}>
        <Box display="flex" flexDirection="column" gap={6}>
          <Typography color="grey.600">
            Por favor, anexe e informe a senha do seu certificado A1.
          </Typography>

          <UploadDigitalCertificate
            name="certificate"
            label="Arquivo .pfx do certificado:"
            placeholder="ou arraste um arquivo (.pfx)"
            acceptFiles={{ 'application/x-pkcs12': ['.pfx'] }}
          />

          <Box display="grid" gridTemplateColumns="1fr 1fr">
            <FormField name="password" label="Senha do certificado:">
              {(field) => (
                <TextFieldPassword {...field} placeholder="Digite a senha" />
              )}
            </FormField>
          </Box>

          <Box display="flex" justifyContent="end" gap={2}>
            <Button size="medium" variant="outlined" onClick={onPrev}>
              Voltar
            </Button>

            <Button
              size="medium"
              color="primary"
              variant="contained"
              onClick={handleNextStep}
              disabled={isLoading}
            >
              Enviar e continuar
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </>
  );
}
