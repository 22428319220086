import { Box } from '@mui/material';
import CollapseSection from 'src/mui/_scss/CollapseSection';

import { sourceDictionary } from 'src/libs/finbits/Management/Attachments';
import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';
import type { NewEntrySuggestion } from 'src/libs/finbits/Management/Entries/types';
import { SuggestionSource } from 'src/libs/finbits/Management/Entries/types';
import { useBanks } from 'src/libs/finbits/Bank';
import { useClassifications } from 'src/libs/finbits/Classifications';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import {
  buildSuggestionItems,
  formatValue,
} from 'src/features/entries/Suggestions/buildSuggestionItems';
import SuggestionItem from 'src/features/entries/Suggestions/SuggestionItem';
import type { EditableFields } from 'src/features/EntryForm/types';

export type SuggestionOriginProps = {
  suggestion: NewEntrySuggestion;
};

function getTitle(suggestion: NewEntrySuggestion) {
  if (suggestion.sourceType === SuggestionSource.ATTACHMENT) {
    const attachmentSuggestionSource = suggestion.source as Attachment;

    return attachmentSuggestionSource.name;
  }

  return sourceDictionary[suggestion.sourceType];
}

export default function SuggestionOrigin({
  suggestion,
}: SuggestionOriginProps) {
  const { data: banks = [] } = useBanks();
  const { companyId, organizationId } = useCompanyParams();
  const { classifications } = useClassifications({ companyId, organizationId });
  const suggestionFields = buildSuggestionItems(suggestion.fields, {
    banks,
    classifications,
  });

  const fieldsList = Object.entries(suggestionFields)
    .map(([key, { index }]) => {
      return { key, index };
    })
    .sort(({ index: a }, { index: b }) => a - b)
    .map(({ key }) => key) as EditableFields[];

  return (
    <Box mb={4} width="100%">
      <CollapseSection title={getTitle(suggestion)}>
        <Box paddingY={3} gap={3} display="flex" flexWrap="wrap">
          {fieldsList.map((fieldName) => {
            const field = suggestionFields[fieldName];
            const item = {
              text: field.label || formatValue(field.fieldName, field.value),
              subText: field.fieldNameDisplay,
              value: field.value,
              origin: getTitle(suggestion),
            };

            return (
              <SuggestionItem
                key={fieldName}
                item={item}
                extraInfo={{
                  type: field.type,
                  typePix:
                    suggestionFields?.[
                      'paymentDetails.pixType'
                    ]?.value?.toString(),
                  paymentMethod:
                    suggestionFields?.[
                      'paymentDetails.paymentMethod'
                    ]?.value?.toString(),
                }}
                fieldName={fieldName}
              />
            );
          })}
        </Box>
      </CollapseSection>
    </Box>
  );
}
