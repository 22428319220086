import throttle from 'lodash/throttle';
import type { QueryClient, UseInfiniteQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { FIVE_SECONDS_IN_MS } from 'src/libs/finbits/Time';
import type {
  ApiError,
  ApiErrorForm,
  DecodedPaginatedResponse,
} from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodePaginatedResponse,
  useInfiniteScroll,
} from 'src/libs/finbits/client';

import type {
  ApprovalRule,
  ApprovalRuleDeleteParams,
  ApprovalRulePatchParams,
  ApprovalRulePostParams,
  ApprovalRuleUpdatePositionParams,
  GetApprovalRulesParams,
} from './types';
import { ApprovalRuleDecoder } from './types';

async function getApprovalRules({
  organizationId,
  companyId,
  pageParam,
}: GetApprovalRulesParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/approvals_rules`,
    { params: { startingAfter: pageParam } }
  );

  return decodePaginatedResponse<ApprovalRule[]>(
    response,
    ApprovalRuleDecoder.array()
  );
}

export function useHasApprovalRules(params: GetApprovalRulesParams) {
  const { data, ...rest } = useQuery<
    DecodedPaginatedResponse<ApprovalRule[]>,
    ApiError
  >({
    enabled: !!params.organizationId && !!params.companyId,
    queryKey: approvalRulesKeys.hasOne(params),
    queryFn: (p) => getApprovalRules({ ...params, ...p }),
  });

  const count = data?.data.length || 0;

  return { hasApprovalRules: count > 0, ...rest };
}

export function useInfiniteApprovalRules(
  params: GetApprovalRulesParams,
  queryConfig?: UseInfiniteQueryOptions<
    DecodedPaginatedResponse<ApprovalRule[]>,
    ApiError
  >
) {
  const { data, ...rest } = useInfiniteScroll<ApprovalRule[]>({
    enabled: !!params.organizationId && !!params.companyId,
    queryKey: approvalRulesKeys.list({
      organizationId: params.organizationId,
      companyId: params.companyId,
    }),
    queryFn: (p) => getApprovalRules({ ...params, ...p }),
    ...queryConfig,
  });

  return { approvalRules: data, ...rest };
}

function invalidateApprovalRulesQueriesCall(queryClient: QueryClient) {
  queryClient.invalidateQueries(approvalRulesKeys.all);
}

export const invalidateApprovalRulesQueries = throttle(
  invalidateApprovalRulesQueriesCall,
  FIVE_SECONDS_IN_MS
);

async function createApprovalRules({
  organizationId,
  companyId,
  ...params
}: ApprovalRulePostParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/approvals_rules`,
    params
  );

  return response.data;
}

async function updateApprovalRule({
  organizationId,
  companyId,
  approvalRuleId,
  ...params
}: ApprovalRulePatchParams) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/approvals_rules/${approvalRuleId}`,
    params
  );

  return response.data;
}

async function deleteApprovalRule({
  organizationId,
  companyId,
  approvalRuleId,
}: ApprovalRuleDeleteParams) {
  const response = await authenticatedAPI.delete(
    `/organizations/${organizationId}/companies/${companyId}/approvals_rules/${approvalRuleId}`
  );

  return response.data;
}

async function updatePosition({
  organizationId,
  companyId,
  approvalRuleId,
  referenceRuleId,
}: ApprovalRuleUpdatePositionParams) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/approvals_rules/${approvalRuleId}/update_position`,
    { referenceRuleId }
  );

  return response.data;
}

export function useCreateApprovalRules() {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation<
    ApprovalRule,
    ApiError<ApiErrorForm>,
    ApprovalRulePostParams
  >(createApprovalRules, {
    onSuccess: () => {
      invalidateApprovalRulesQueries(queryClient);
    },
  });
  return { createApprovalRules: mutate, ...rest };
}

export function useUpdateApprovalRule() {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation<
    ApprovalRule,
    ApiError<ApiErrorForm>,
    ApprovalRulePatchParams
  >(updateApprovalRule, {
    onSuccess: () => {
      invalidateApprovalRulesQueries(queryClient);
    },
  });

  return { updateApprovalRule: mutate, ...rest };
}

export function useDeleteApprovalRule() {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation<
    ApprovalRule,
    ApiError<ApiErrorForm>,
    ApprovalRuleDeleteParams
  >(deleteApprovalRule, {
    onSuccess: () => {
      invalidateApprovalRulesQueries(queryClient);
    },
  });

  return { deleteApprovalRule: mutate, ...rest };
}

export function useUpdateApprovalRulePosition() {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation<
    ApprovalRule,
    ApiError<ApiErrorForm>,
    ApprovalRuleUpdatePositionParams
  >(updatePosition, {
    onSuccess: () => {
      invalidateApprovalRulesQueries(queryClient);
    },
  });

  return { updatePosition: mutate, ...rest };
}

const approvalRulesKeys = {
  all: ['approvalRules'],
  list: (params: unknown) => [...approvalRulesKeys.all, params],
  hasOne: (params: unknown) => [...approvalRulesKeys.all, 'hasOne', params],
};
