import { Box } from '@mui/material';

import { EntrySituation } from 'src/libs/finbits/Management/Entries/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import type { BillPayableOptional } from 'src/libs/finbits/Management/NewInboxItems/types';
import { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import type { Receivable } from 'src/libs/finbits/Receivables/types';

import UnfilledContextAlert from 'src/features/entries/UnfilledContextAlert';
import ReprovedReasonAlert from 'src/features/bills-to-pay/EditBillDrawer/ReprovedReasonAlert';
import PaymentFailedAlert from 'src/features/bills-to-pay/EditBillDrawer/PaymentFailedAlert';
import SuggestionsAppliedAlert from 'src/features/entries/Modals/EntryModal/autofill-suggestions/SuggestionsAppliedAlert';

import styles from './Alerts.module.scss';

export type Props =
  | {
      entry?: BillPayable | BillPayableOptional;
      entryType: EntryType.BILL;
    }
  | {
      entry?: Receivable;
      entryType: EntryType.RECEIVABLE;
    };

export default function Alerts({ entry, entryType }: Props) {
  const isReproved = entry?.currentSituation === EntrySituation.REPROVED;
  const isFailed = entry?.currentSituation === EntrySituation.FAILED;

  return (
    <Box className={styles.wrapper}>
      <SuggestionsAppliedAlert />

      {entryType === EntryType.BILL && (
        <>
          {isReproved && <ReprovedReasonAlert bill={entry as BillPayable} />}
          {isFailed && <PaymentFailedAlert bill={entry as BillPayable} />}

          <UnfilledContextAlert entry={entry} entryType={entryType} />
        </>
      )}
    </Box>
  );
}
