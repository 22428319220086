import t from 'zod';

export const REQUIRED_MESSAGE_CLIENT_ID = 'Por favor, informe o Client ID.';
export const REQUIRED_MESSAGE_BASIC_AUTH = 'Por favor, informe o Basic Auth.';
export const REQUIRED_MESSAGE_CLIENT_SECRET =
  'Por favor, informe o Client Secret.';
export const REQUIRED_MESSAGE_CONTRACT_NUMBER =
  'Por favor, informe o número de identificação.';
export const REQUIRED_MESSAGE_APPLICATION_KEY =
  'Por favor, informe a Application Key.';

export const AuthCredentialsResolver = t.object({
  basicAuth: t.string().min(1, REQUIRED_MESSAGE_BASIC_AUTH),
  clientId: t.string().min(1, REQUIRED_MESSAGE_CLIENT_ID),
  clientSecret: t.string().min(1, REQUIRED_MESSAGE_CLIENT_SECRET),
  contractNumber: t.string().min(1, REQUIRED_MESSAGE_CONTRACT_NUMBER),
  developerApplicationKey: t.string().min(1, REQUIRED_MESSAGE_APPLICATION_KEY),
});
