import classNames from 'classnames';
import { Typography } from 'src/design-system/components';
import { Divider, Stack } from '@mui/material';
import { DotIcon } from 'src/mui/_icons';

import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import styles from './Legends.module.scss';

type Props = {
  iconProps: {
    className: string;
  };
  labelText: string;
  projectedLabel?: string;
};

export default function LegendItem({
  iconProps,
  labelText,
  projectedLabel = 'Programados',
}: Props) {
  const { isEnabled } = useExternalFeatureFlag(Feature.OVERVIEW_PROJECTED);

  return (
    <Stack direction="column" gap={0.5} alignItems="start">
      <Stack direction="row" className={styles.itemWrapper}>
        <Divider
          component="div"
          role="presentation"
          orientation="horizontal"
          textAlign="left"
          variant="fullWidth"
          classes={{ wrapper: styles.dividerWrapper, root: styles.dividerRoot }}
        >
          <Typography variant="text-xs" fontWeight={600}>
            {labelText}
          </Typography>
        </Divider>
      </Stack>
      <Stack direction="row" gap={4}>
        <Stack direction="row" gap={2} alignItems="center">
          <DotIcon
            fontSize="xxsmall"
            {...iconProps}
            className={classNames(iconProps.className)}
          />
          <Typography variant="text-xs" fontWeight={400}>
            Realizados
          </Typography>
        </Stack>

        {isEnabled && (
          <Stack direction="row" gap={2} alignItems="center">
            <DotIcon
              fontSize="xxsmall"
              {...iconProps}
              className={classNames(iconProps.className, styles.projected)}
            />
            <Typography variant="text-xs" fontWeight={400}>
              {projectedLabel}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
