import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import type { BillFormParams } from 'src/libs/finbits/Bills/types';
import { useUpdateBill } from 'src/libs/finbits/Bills';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useInboxItemMerge } from 'src/libs/finbits/Management/NewInboxItems';
import type { InboxItemAttachmentsStatus } from 'src/libs/finbits/Management/NewInboxItems/validations';

import { Tab } from 'src/features/entries/Modals/EntryModal/Tabs/TabPanels/TabPanelsProvider/types';
import BillModal from 'src/features/bills-to-pay/BillModal';

import { parseFormToSubmit } from '../formValues';

type Props = {
  billId?: string;
  onSuccess?: () => void;
  inboxItemId?: string;
  inboxAttachmentsStatus?: InboxItemAttachmentsStatus;
} & PortalProps;

export default function MergeBillModal({
  onClose,
  open = true,
  onExit,
  onSuccess,
  billId,
  inboxItemId,
  inboxAttachmentsStatus,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const { bill, isLoading } = useInboxItemMerge({
    companyId,
    organizationId,
    inboxItemId,
    billId,
  });

  const { updateBill, isLoading: isSaving } = useUpdateBill();

  const disabledTabs = { [Tab.RECURRENCE]: true };

  function handleUpdateBill(params: BillFormParams) {
    if (!billId) return;

    updateBill(
      {
        ...parseFormToSubmit(params),
        companyId,
        organizationId,
        billId,
        inboxItemId,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message:
              'O item selecionado foi vinculado ao lançamento existente.',
          });

          onClose();
          onSuccess?.();
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message:
              'Não foi possível vincular o item selecionado ao lançamento.',
          });
        },
      }
    );
  }

  return (
    <BillModal
      onClose={onClose}
      open={open}
      onExit={onExit}
      onSubmit={handleUpdateBill}
      isLoading={isLoading}
      isSaving={isSaving}
      bill={bill}
      disabledTabs={disabledTabs}
      inboxItemId={inboxItemId}
      inboxAttachmentsStatus={inboxAttachmentsStatus}
      isEditMode
    />
  );
}
