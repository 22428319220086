import cn from 'classnames';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { toCurrency } from 'src/libs/finbits/Money';
import type { OverviewCashFlow } from 'src/libs/finbits/Overview/types';
import type { CashFlowGroupBy } from 'src/libs/finbits/Overview/enum';

import { generateVirtualElement } from './generateVirtualElement';
import styles from './CustomItemTooltip.module.scss';
import PercentageVariation from './PercentageVariation';
import useOverviewChartTooltip from './useOverviewChartTooltip';

type Props = {
  data: OverviewCashFlow[];
  groupedBy: CashFlowGroupBy;
};

function formatCurrency(v: number) {
  return toCurrency(v, undefined, { signDisplay: 'auto' });
}

export default function CustomItemTooltip({ data, groupedBy }: Props) {
  const {
    visible,
    currentAmount,
    currentAmountColor,
    variationAmount,
    percentageVariation,
    percentageVariationSing,
    isBar,
    yOffset,
    isMousePointer,
    mousePosition,
    isProjected,
  } = useOverviewChartTooltip(data);

  if (!visible) return null;

  return (
    <Popper
      open
      placement={isMousePointer ? 'top-end' : 'top'}
      anchorEl={generateVirtualElement(mousePosition)}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, yOffset],
          },
        },
      ]}
    >
      <Paper
        elevation={0}
        className={cn(styles.paper, {
          [styles.projected]: isProjected,
          [styles.blackVariation]: isBar,
        })}
      >
        <Stack alignItems="start">
          <Typography
            variant="text-xs"
            fontWeight={600}
            color={currentAmountColor}
          >
            {formatCurrency(currentAmount ?? 0)}
          </Typography>

          <PercentageVariation
            groupedBy={groupedBy}
            variationAmount={variationAmount}
            percentageVariation={percentageVariation}
            percentageVariationSing={percentageVariationSing}
            variation={isBar ? 'black' : 'white'}
            isProjected={isProjected}
          />
        </Stack>
      </Paper>
    </Popper>
  );
}
