import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router';
import { Typography } from 'src/design-system/components';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import illustration from 'src/assets/final-step-consent-bb.svg';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import type { StepperContentProps } from 'src/features/open-banking/AutoPayment/VerticalStepper/types';

export default function FinalStep({ tradeName, onClose }: StepperContentProps) {
  const { companyId, organizationId } = useCompanyParams();
  const navigate = useNavigate();

  function handleFinish() {
    onClose?.();

    navigate({
      pathname: generateCompanyPath(RouteKey.BILLS_TO_PAY, {
        companyId,
        organizationId,
      }),
    });
  }

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <Box
        display="flex"
        alignItems="center"
        gap={10}
        paddingInline={20}
        paddingBottom={4}
      >
        <Box flex="1">
          <Typography
            variant="text-md"
            fontWeight="600"
            component="h6"
            marginBottom={4}
          >
            A conexão com o {tradeName} foi realizada com sucesso!
          </Typography>

          <Typography marginBottom={6}>
            Você concluiu o processo de conexão de pagamentos automáticos do{' '}
            {tradeName}. <br />
            Está tudo pronto para realizar seus pagamentos com muito mais
            agilidade
          </Typography>

          <Button
            size="medium"
            color="primary"
            variant="contained"
            onClick={handleFinish}
          >
            Ir para Contas a Pagar
          </Button>
        </Box>

        <Box>
          <img
            src={illustration}
            alt="Ilustração de uma pessoa relaxada usando um laptop, cercada por ícones financeiros."
          />
        </Box>
      </Box>
    </Box>
  );
}
