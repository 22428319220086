import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Card } from '@mui/material';
import { Box } from '@mui/system';
import cn from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert, AlertTitle } from 'src/design-system/components';
import { FormField, Input } from 'src/mui/_scss';

import { useCreateBBConsentments } from 'src/libs/finbits/Bank/BBConsentments';
import { scopes } from 'src/libs/finbits/Bank/OpenBanking/enum';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import type { StepperContentProps } from 'src/features/open-banking/AutoPayment/VerticalStepper/types';

import styles from './AuthCredentialsStep.module.scss';
import type { AuthCredentialsType } from './types';
import { AuthCredentialsResolver } from './validations';

export default function AuthCredentialsStep({
  account,
  onNext,
  onPrev,
  tradeName,
}: StepperContentProps) {
  const { companyId, organizationId } = useCompanyParams();
  const { connectBBConsentments, isLoading, isError } =
    useCreateBBConsentments();

  const form = useForm<AuthCredentialsType>({
    resolver: zodResolver(AuthCredentialsResolver),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      basicAuth: '',
      clientId: '',
      clientSecret: '',
      contractNumber: '',
      developerApplicationKey: '',
    },
  });

  async function handleNextStep(event: React.MouseEvent) {
    const isValid = await form.trigger();

    if (!isValid) return;

    const formValues = form.getValues();

    if (!formValues.basicAuth.startsWith('Basic ')) {
      const updatedBasicAuth = `Basic ${formValues.basicAuth}`;
      form.setValue('basicAuth', updatedBasicAuth, { shouldValidate: true });
    }

    connectBBConsentments(
      {
        accountId: account!!.id,
        companyId,
        document: account?.document ?? '',
        organizationId,
        scope: scopes.payments,
        ...formValues,
      },
      {
        onSuccess: () => {
          onNext?.(event);
        },
      }
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      {isError && (
        <Alert
          severity="error"
          variant="standard"
          className={styles.alertError}
        >
          <AlertTitle>
            A conexão não pode ser concluída com os dados informados.
          </AlertTitle>
          Por favor, verifique se preencheu corretamente o número do convênio
          PAG e as credenciais da API e tente novamente.
        </Alert>
      )}

      <FormProvider {...form}>
        <Card variant="outlined" className={styles.card}>
          <Box
            className={cn(styles.formField, {
              [styles.formFieldError]: form.formState.errors.contractNumber,
            })}
          >
            <FormField
              name="contractNumber"
              label="Número de identificação do aderente do Convênio PAG"
            >
              {(field) => <Input {...field} placeholder="Informe o numero" />}
            </FormField>
          </Box>

          <Alert
            severity="info"
            variant="outlined"
            className={styles.alertInfo}
          >
            Você encontra o número de identificação do aderente do Convênio PAG
            no Contrato Único de Prestação de Serviços do {tradeName}.
          </Alert>
        </Card>

        <Card
          variant="outlined"
          className={cn(styles.card, styles.cardCredentials)}
        >
          <Box
            className={cn(styles.formField, {
              [styles.formFieldError]:
                form.formState.errors.developerApplicationKey,
            })}
          >
            <FormField name="developerApplicationKey" label="Application Key">
              {(field) => (
                <Input {...field} placeholder="Informe a Application Key" />
              )}
            </FormField>
          </Box>

          <Box
            className={cn(styles.formField, {
              [styles.formFieldError]: form.formState.errors.clientId,
            })}
          >
            <FormField name="clientId" label="Client ID">
              {(field) => (
                <Input {...field} placeholder="Informe o Client ID" />
              )}
            </FormField>
          </Box>

          <Box
            className={cn(styles.formField, {
              [styles.formFieldError]: form.formState.errors.clientSecret,
            })}
          >
            <FormField name="clientSecret" label="Client Secret">
              {(field) => (
                <Input
                  {...field}
                  placeholder="Informe o Client Secret"
                  multiline
                  rows={4}
                />
              )}
            </FormField>
          </Box>

          <Box
            className={cn(styles.formField, {
              [styles.formFieldError]: form.formState.errors.basicAuth,
            })}
          >
            <FormField name="basicAuth" label="Basic Auth">
              {(field) => (
                <Input
                  {...field}
                  placeholder="Informe o Basic Auth"
                  multiline
                  rows={4}
                />
              )}
            </FormField>
          </Box>
        </Card>
      </FormProvider>

      <Box display="flex" justifyContent="end" gap={2}>
        <Button size="medium" variant="outlined" onClick={onPrev}>
          Voltar
        </Button>

        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={handleNextStep}
          disabled={isLoading}
        >
          Concluir conexão
        </Button>
      </Box>
    </Box>
  );
}
