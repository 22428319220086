import cn from 'classnames';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { MinusIcon, TrendDownIcon, TrendUpIcon } from 'src/mui/_icons';

import { toCurrency } from 'src/libs/finbits/Money';
import { CashFlowGroupBy } from 'src/libs/finbits/Overview/enum';

import { SignIndicator } from '../enum';

import styles from './PercentageVariation.module.scss';

type Props = {
  variationAmount?: number | null;
  percentageVariation?: number | null;
  groupedBy: CashFlowGroupBy;
  variation?: 'black' | 'white';
  percentageVariationSing?: SignIndicator;
  isProjected?: boolean;
};

export function formatCurrency(v: number) {
  return toCurrency(v, undefined, { signDisplay: 'exceptZero' });
}

export function formatPercentage(v: number) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 2,
    signDisplay: 'exceptZero',
  }).format(v / 100);
}

function formatGroupedBy(groupedBy: CashFlowGroupBy) {
  if (groupedBy === CashFlowGroupBy.DAY) {
    return 'DoD';
  }

  if (groupedBy === CashFlowGroupBy.MONTH) {
    return 'MoM';
  }

  return 'YoY';
}

const IconMap = {
  [SignIndicator.POSITIVE]: <TrendUpIcon className={styles.icon} />,
  [SignIndicator.NEGATIVE]: <TrendDownIcon className={styles.icon} />,
  [SignIndicator.ZERO]: <MinusIcon className={styles.icon} />,
};

export default function PercentageVariation({
  variationAmount,
  percentageVariation,
  groupedBy,
  variation = 'white',
  percentageVariationSing = SignIndicator.ZERO,
  isProjected,
}: Props) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      className={cn(styles.percentageVariation, {
        [styles.blackColor]: variation === 'black',
        [styles.projected]: isProjected,
      })}
    >
      {variationAmount !== undefined && (
        <Typography variant="text-xs" fontWeight={600}>
          {formatGroupedBy(groupedBy)}: {formatCurrency(variationAmount ?? 0)}
        </Typography>
      )}
      {percentageVariation !== undefined && (
        <Stack
          direction="row"
          className={cn({
            [styles.positive]:
              percentageVariationSing === SignIndicator.POSITIVE,
            [styles.negative]:
              percentageVariationSing === SignIndicator.NEGATIVE,
          })}
          alignItems="center"
        >
          <Typography variant="text-xs" fontWeight={600}>
            ({formatPercentage(percentageVariation ?? 0)})
          </Typography>
          {IconMap[percentageVariationSing]}
        </Stack>
      )}
    </Stack>
  );
}
