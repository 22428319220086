import { useItemTooltip, useMouseTracker } from '@mui/x-charts/ChartsTooltip';

import type { OverviewCashFlow } from 'src/libs/finbits/Overview/types';
import { CashFlowDataKeys } from 'src/libs/finbits/Overview/enum';

import { SignIndicator } from './enum';

function getSignIndicator(value: number | null) {
  if (value === null) return SignIndicator.ZERO;
  if (value === 0) return SignIndicator.ZERO;
  if (value < 0) return SignIndicator.NEGATIVE;
  return SignIndicator.POSITIVE;
}

function getAmountColor(value: number | null) {
  const sing = getSignIndicator(value);

  return sing === SignIndicator.NEGATIVE ? 'error.600' : undefined;
}

function buildTooltipContentValue(seriesId: unknown, item: OverviewCashFlow) {
  if (seriesId === CashFlowDataKeys.DEBIT_AMOUNT) {
    return {
      currentAmount: item.debitAmount,
      currentAmountColor: undefined,
      variationAmount: item.debitAmountVariation,
      percentageVariation: item.debitAmountPercentageVariation,
      percentageVariationSing: getSignIndicator(
        item.debitAmountPercentageVariation
      ),
      isProjected: false,
    };
  }

  if (seriesId === CashFlowDataKeys.CREDIT_AMOUNT) {
    return {
      currentAmount: item.creditAmount,
      currentAmountColor: undefined,
      variationAmount: item.creditAmountVariation,
      percentageVariation: item.creditAmountPercentageVariation,
      percentageVariationSing: getSignIndicator(
        item.creditAmountPercentageVariation
      ),
      isProjected: false,
    };
  }

  if (seriesId === CashFlowDataKeys.DEBIT_AMOUNT_PROJECTED) {
    return {
      currentAmount: item.debitAmountProjected,
      currentAmountColor: undefined,
      variationAmount: item.debitAmountProjectedVariation,
      percentageVariation: item.debitAmountProjectedPercentageVariation,
      percentageVariationSing: getSignIndicator(
        item.debitAmountProjectedPercentageVariation
      ),
      isProjected: true,
    };
  }

  if (seriesId === CashFlowDataKeys.CREDIT_AMOUNT_PROJECTED) {
    return {
      currentAmount: item.creditAmountProjected,
      currentAmountColor: undefined,
      variationAmount: item.creditAmountProjectedVariation,
      percentageVariation: item.creditAmountProjectedPercentageVariation,
      percentageVariationSing: getSignIndicator(
        item.creditAmountProjectedPercentageVariation
      ),
      isProjected: true,
    };
  }

  if (seriesId === CashFlowDataKeys.BALANCE_AMOUNT_PROJECTED) {
    return {
      currentAmount: item.balanceAmountProjected,
      currentAmountColor: undefined,
      variationAmount: item.balanceAmountProjectedVariation,
      percentageVariation: item.balanceAmountProjectedPercentageVariation,
      percentageVariationSing: getSignIndicator(
        item.balanceAmountProjectedPercentageVariation
      ),
      isProjected: true,
    };
  }

  return {
    currentAmount: item.balanceAmount,
    currentAmountColor: getAmountColor(item.balanceAmount),
    variationAmount: item.balanceVariationAmount,
    percentageVariation: item.balancePercentageVariation,
    percentageVariationSing: getSignIndicator(item.balancePercentageVariation),
    isProjected: false,
  };
}

export default function useOverviewChartTooltip(data: OverviewCashFlow[]) {
  const tooltipData = useItemTooltip();
  const mousePosition = useMouseTracker();
  const item =
    tooltipData?.identifier.dataIndex !== undefined &&
    data.at(tooltipData.identifier.dataIndex);

  if (!tooltipData || !mousePosition || !item) {
    return { visible: false, mousePosition };
  }

  // The pointer type can be used to have different behavior based on pointer type.
  const isMousePointer = mousePosition?.pointerType === 'mouse';
  // Adapt the tooltip offset to the size of the pointer.
  const yOffset = isMousePointer ? 5 : 40 - mousePosition.height;

  const isBar = tooltipData.identifier.type === 'bar';
  const seriesId = tooltipData.identifier.seriesId;

  const {
    currentAmount,
    variationAmount,
    percentageVariation,
    currentAmountColor,
    percentageVariationSing,
    isProjected,
  } = buildTooltipContentValue(seriesId, item);

  return {
    visible: true,
    currentAmount,
    currentAmountColor,
    variationAmount,
    percentageVariation,
    percentageVariationSing,
    isBar,
    yOffset,
    isMousePointer,
    mousePosition,
    isProjected,
  };
}
