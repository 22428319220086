import { useMemo } from 'react';

import type { CashFlowGroupBy } from 'src/libs/finbits/Overview/enum';
import type { OverviewCashFlow } from 'src/libs/finbits/Overview/types';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import {
  generateColumns,
  generateEmptyColumns,
  generateEmptyRows,
  generateRows,
} from 'src/features/overview/parsers/formatOverviewTableData';

type Props = {
  data: OverviewCashFlow[];
  groupedBy: CashFlowGroupBy;
};
export function useFormatOverviewTableData({ data, groupedBy }: Props) {
  const { isEnabled: isOverviewProjectedEnabled } = useExternalFeatureFlag(
    Feature.OVERVIEW_PROJECTED
  );

  const rows = useMemo(() => {
    if (!data.length) return generateEmptyRows();

    return generateRows({ data, groupedBy, isOverviewProjectedEnabled });
  }, [data, groupedBy, isOverviewProjectedEnabled]);

  const columns = useMemo(() => {
    if (!data.length) return generateEmptyColumns();

    return generateColumns({ data, groupedBy });
  }, [data, groupedBy]);

  return {
    columns,
    rows,
  };
}
