import { useCallback, useMemo, useState } from 'react';

import { Divider } from '@mui/material';
import { Typography } from 'src/design-system/components';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import { SynchronizeType } from 'src/libs/finbits/Bank/Accounts/types';

import QuickFilterChip from './QuickFilterChip';
import styles from './QuickFilters.module.scss';

type QuickFilterProps = {
  accounts: Account[];
  onSetFilter: (filter?: SynchronizeType) => void;
  selectedAccountIds: string[];
};

const filters = [
  {
    label: 'Contas manuais',
    synchronizeType: SynchronizeType.MANUAL,
  },
  {
    label: 'Contas automáticas',
    synchronizeType: SynchronizeType.AUTOMATIC,
  },
] as const;

export default function QuickFilters({
  accounts,
  onSetFilter,
  selectedAccountIds,
}: QuickFilterProps) {
  const [activeFilter, setActiveFilter] = useState<SynchronizeType | undefined>(
    undefined
  );

  const handleSetFilter = useCallback(
    (filter?: SynchronizeType) => {
      onSetFilter(filter);
      setActiveFilter(filter);
    },
    [onSetFilter, setActiveFilter]
  );

  const filtersMemo = useMemo(
    () =>
      filters.map((filter) => (
        <QuickFilterChip
          accounts={accounts}
          filterSelected={
            filters.find((f) => f.synchronizeType === activeFilter)
              ?.synchronizeType
          }
          key={filter.synchronizeType}
          label={filter.label}
          onSetFilter={handleSetFilter}
          synchronizeType={filter.synchronizeType}
          selectedAccountIds={selectedAccountIds}
        />
      )),
    [accounts, activeFilter, selectedAccountIds, handleSetFilter]
  );

  return (
    <>
      <div className={styles.quickFiltersRoot}>
        <Typography color="text.secondary" fontWeight={500}>
          Filtros rápidos:
        </Typography>

        <div className={styles.chipsContainer}>{filtersMemo}</div>
      </div>

      <Divider variant="fullWidth" />
    </>
  );
}
