import { createContext, useContext, useState } from 'react';

import { usePageControls } from 'src/features/preview/Pagination';

import type { PreviewContextProps, PreviewProviderProps } from './types';

export const EXPANSION_EXIT_TIME = 500;
export const EXPANSION_ENTER_TIME = 1000;

const PreviewContext = createContext({} as PreviewContextProps);

export default function PreviewProvider({
  children,
  defaultToolbarVisible = false,
}: PreviewProviderProps) {
  const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState(1);
  const [isExpandedPreview, setIsExpandedPreview] = useState(false);
  const [isToolbarVisible, setIsToolbarVisible] = useState(
    defaultToolbarVisible
  );

  const {
    pageNumber,
    numPages,
    onPDFLoadSuccess,
    handleNextPage,
    handlePreviousPage,
    resetPageNumber,
  } = usePageControls();

  function toggleExpansionPreview() {
    setIsExpandedPreview((prevState) => !prevState);
  }

  return (
    <PreviewContext.Provider
      value={{
        pageNumber,
        numPages,
        isExpandedPreview,
        isToolbarVisible,
        toggleExpansionPreview,
        setIsToolbarVisible,
        handleNextPage,
        handlePreviousPage,
        handlePDFLoadSuccess: onPDFLoadSuccess,
        resetPageNumber,
        currentAttachmentIndex,
        setCurrentAttachmentIndex,
      }}
    >
      {children}
    </PreviewContext.Provider>
  );
}

export function usePreviewContext() {
  const context = useContext(PreviewContext);

  if (!context) {
    throw new Error('To use context, you must provide a PreviewProvider');
  }

  return context;
}
