import { CashFlowDataKeys } from './enum';

export const overviewGroupedByDictionary = {
  [CashFlowDataKeys.CREDIT_AMOUNT]: 'Recebimentos',
  [CashFlowDataKeys.DEBIT_AMOUNT]: 'Pagamentos',
  [CashFlowDataKeys.BALANCE_AMOUNT]: 'Saldo',
  [CashFlowDataKeys.CREDIT_AMOUNT_PROJECTED]: 'Recebimentos programados',
  [CashFlowDataKeys.DEBIT_AMOUNT_PROJECTED]: 'Pagamentos programados',
  [CashFlowDataKeys.BALANCE_AMOUNT_PROJECTED]: 'Saldo projetado',
};

export const overviewTableDictionary = {
  [CashFlowDataKeys.CREDIT_AMOUNT_TOTAL]: 'Recebimentos',
  [CashFlowDataKeys.CREDIT_AMOUNT]: 'Realizados',
  [CashFlowDataKeys.CREDIT_AMOUNT_PROJECTED]: 'Programados',
  [CashFlowDataKeys.DEBIT_AMOUNT_TOTAL]: 'Pagamentos',
  [CashFlowDataKeys.DEBIT_AMOUNT]: 'Realizados',
  [CashFlowDataKeys.DEBIT_AMOUNT_PROJECTED]: 'Programados',
  [CashFlowDataKeys.BALANCE_AMOUNT_TOTAL]: 'Saldo',
  [CashFlowDataKeys.BALANCE_AMOUNT]: 'Realizados',
  [CashFlowDataKeys.BALANCE_AMOUNT_PROJECTED]: 'Projetado',
  [CashFlowDataKeys.TOTAL_AMOUNT]: 'Subtotal',
};
