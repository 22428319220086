import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

export function toIntRepresentation(amount: string) {
  const [int, dec] = amount.replaceAll('.', '').split(',');

  return parseInt(int + (dec || '').padEnd(2, '0'));
}

export function toCurrency(
  amount: number,
  type: BalanceType = BalanceType.CREDIT,
  options?: Intl.NumberFormatOptions
) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    ...options,
  }).format(toFloat(amount, type));
}

export function toDecimal(
  amount: number,
  type: BalanceType = BalanceType.CREDIT
) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(toFloat(amount, type));
}

export function toInteger(amount: string | number) {
  return Math.round(Number(amount) * 100);
}

export function toFloat(
  amount: number,
  type: BalanceType = BalanceType.CREDIT
) {
  const convertedAmount = convertToSignedNumber(amount, type);

  return convertedAmount / 100;
}

export function convertToSignedNumber(amount: number, type: BalanceType) {
  if (type === BalanceType.DEBIT && amount >= 0) {
    return amount * -1;
  }

  return amount;
}

export function getAmountType(amount: number): {
  amount: number;
  type: BalanceType;
} {
  return {
    amount: Math.abs(amount),
    type: amount < 0 ? BalanceType.DEBIT : BalanceType.CREDIT,
  };
}

export function floatToDecimal(amount: number) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(amount);
}

export function toFloatRepresentation(amount: string) {
  return Number.parseFloat(amount.replaceAll('.', '').replaceAll(',', '.'));
}
