import { Tab, Tabs } from '@mui/material';

import styles from './OverviewPageTabs.module.scss';

enum OverviewTabs {
  OVERVIEW = 'overview',
}

function a11yProps(index: number) {
  return {
    id: `overview-tabs-${index}`,
    'aria-controls': `overview-tabpanel-${index}`,
  };
}

export default function OverviewPageTabs() {
  return (
    <Tabs
      className={styles.tabs}
      value={OverviewTabs.OVERVIEW}
      textColor="secondary"
      indicatorColor="secondary"
      aria-label="visão geral"
    >
      <Tab
        style={{ textTransform: 'initial' }}
        label="Fluxo de caixa"
        {...a11yProps(0)}
        value={OverviewTabs.OVERVIEW}
      />
    </Tabs>
  );
}
