import { Button } from '@mui/material';
import { CheckIcon } from 'src/mui/_icons';

import { Tooltip } from 'src/mui';

import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';
import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';
import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import useMarkAsFinancialDoneAction from 'src/features/entries/Actions/mark-financial-done-action/hooks/useMarkAsFinancialDoneAction';

import styles from './MarkDoneBillButton.module.scss';

type Props = {
  bills: BillPayable[];
};

export default function MarkDoneBillButton({ bills }: Props) {
  const { isEnabled: isMarkBatchReleasesEnabled } = useExternalFeatureFlag(
    Feature.MARK_BATCH_RELEASES
  );

  const isMarkAsDoneEnabled = bills.every(
    (entry) =>
      new Date(entry.date) <= new Date() &&
      entry.availableActions?.includes(AvailableActions.MARK_AS_FINANCIAL_ENTRY)
  );

  const { isLoading, markAsFinancialDone } = useMarkAsFinancialDoneAction({
    entries: bills as unknown as FinancialStatementEntry[],
  });

  return (
    <>
      {isMarkBatchReleasesEnabled && (
        <WithAllowedPermission
          permissions={{
            action: ['create_from_receivable', 'create_from_bill'],
            resource: 'financialEntries',
          }}
        >
          <Tooltip
            title="Somente lançamentos de contas bancárias manuais com data de pagamento até hoje podem ser marcados como realizados."
            placement="top"
            disableHoverListener={isMarkAsDoneEnabled}
          >
            <Button
              variant="text"
              startIcon={<CheckIcon />}
              onClick={markAsFinancialDone}
              disabled={!isMarkAsDoneEnabled || isLoading}
              className={styles.button}
            >
              Marcar como realizado
            </Button>
          </Tooltip>
        </WithAllowedPermission>
      )}
    </>
  );
}
