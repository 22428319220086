import { Button } from '@mui/material';
import { Alert, AlertTitle } from 'src/design-system/components';
import { LightedBulbIcon } from 'src/mui/_icons';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import styles from './BankAutomationAlert.module.scss';

export default function BankAutomationAlert() {
  const { companyId, organizationId } = useCompanyParams();

  const urlAccountSettings = generateCompanyPath(RouteKey.ACCOUNTS, {
    companyId,
    organizationId,
  });

  return (
    <Alert
      severity="info"
      icon={<LightedBulbIcon fontSize="inherit" />}
      classes={{
        action: styles.alertHasButton,
        root: styles.alert,
      }}
      action={
        <Button
          href={urlAccountSettings}
          target="_blank"
          variant="text"
          color="primary"
          className={styles.alertButton}
        >
          Conectar conta
        </Button>
      }
    >
      <>
        <AlertTitle>
          Conecte sua conta e automatize transferências (TED), pagamentos de
          boletos* e Pix.
        </AlertTitle>
        Agora, além do <strong>Banco BTG</strong>, os pagamentos automáticos
        também estão disponíveis para os bancos <strong>Itaú</strong> e{' '}
        <strong>Santander</strong>. Em breve, esta facilidade estará disponível
        para mais bancos.
      </>
    </Alert>
  );
}
