import { snackbar } from 'src/mui';

import { useCreateFinancialFromBills } from 'src/libs/finbits/Bills';
import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useCreateFinancialFromReceivables } from 'src/libs/finbits/Receivables';

import { useOpenConfirmMarkAsDone } from 'src/features/entries/Dialogs';

type Props = {
  entries: FinancialStatementEntry[];
  onSuccess?: () => void;
};

export default function useMarkAsFinancialDoneAction({
  entries,
  onSuccess,
}: Props) {
  const openConfirmMarkAsDone = useOpenConfirmMarkAsDone();

  const { companyId, organizationId } = useCompanyParams();

  const { createFinancialFromBills, isLoading: isMarkAsBillsDone } =
    useCreateFinancialFromBills();

  const { createFinancialFromReceivables, isLoading: isMarkAsReceivablesDone } =
    useCreateFinancialFromReceivables();

  function handleManualMarkAsDone() {
    const receivables = entries
      .filter(({ entryType }) => entryType === EntryType.RECEIVABLE)
      .map(({ id, date }) => ({ receivableId: id, financialEntryDate: date }));

    const bills = entries
      .filter(({ entryType }) => entryType === EntryType.BILL)
      .map(({ id, date }) => ({ billId: id, financialEntryDate: date }));

    const callbacks = {
      onSuccess: () => {
        onSuccess && onSuccess();

        snackbar({
          variant: 'success',
          message: 'O lançamento programado foi realizado com sucesso!',
        });
      },
      onError: () => {
        snackbar({
          variant: 'error',
          message:
            'Não foi possível marcar um ou mais lançamentos como realizados. Por favor, tente novamente.',
        });
      },
    };

    if (!!receivables.length) {
      createFinancialFromReceivables(
        {
          companyId,
          organizationId,
          ...{ receivables },
        },
        callbacks
      );
    }

    if (!!bills.length) {
      createFinancialFromBills(
        {
          companyId,
          organizationId,
          ...{ bills },
        },
        callbacks
      );
    }
  }

  function markAsFinancialDone() {
    openConfirmMarkAsDone({
      selectedCount: entries.length,
      onConfirm: handleManualMarkAsDone,
    });
  }

  return {
    markAsFinancialDone,
    isLoading: isMarkAsBillsDone || isMarkAsReceivablesDone,
  };
}
