import type { ReactNode } from 'react';

import classNames from 'classnames';

import { Badge } from 'src/mui';

import { SynchronizeType } from 'src/libs/finbits/Bank/Accounts/types';

import styles from './SynchronizeTypeBadge.module.scss';

type Props = {
  children?: ReactNode;
  synchronizeType: SynchronizeType;
};

export default function SynchronizeTypeBadge({
  children,
  synchronizeType,
}: Props) {
  const isAutomatic = synchronizeType === SynchronizeType.AUTOMATIC;

  const badgeClass = classNames(styles.badge, {
    [styles.automatic]: isAutomatic,
    [styles.position]: !children,
  });

  return (
    <Badge
      badgeContent={isAutomatic ? 'A' : 'M'}
      classes={{ badge: badgeClass }}
    >
      {children}
    </Badge>
  );
}
