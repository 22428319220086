import { Typography } from 'src/design-system/components';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import { FormField } from 'src/mui/_scss';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames';

import AutoPaymentCard from '../../AutoPaymentModal/AutoPaymentCard';
import type { StepperContentProps } from '../../VerticalStepper/types';

import styles from './InstructionsStep.module.scss';
import { InterAutoPaymentTermResolver } from './validations';

export default function InstructionsStep({
  onNext,
  onPrev,
}: StepperContentProps) {
  const form = useForm({
    resolver: zodResolver(InterAutoPaymentTermResolver),
    mode: 'onChange',
    defaultValues: {
      term: false,
    },
  });

  async function onSubmit(event: React.MouseEvent) {
    const isValid = await form.trigger();
    if (isValid) {
      onNext?.(event);
    }
  }

  return (
    <>
      <Box className={styles.cards}>
        <AutoPaymentCard
          stepIndex={1.1}
          heading="Acesse o Internet Banking da sua Conta Digital PJ"
          buttonLabel="Acessar Banco Inter"
          buttonHref="https://contadigital.inter.co/?t=pj"
          buttonTarget="_blank"
        >
          <Typography variant="text-sm" fontWeight="400">
            Acesse normalmente a sua conta digital PJ diretamente no app do
            Banco Inter ou no link abaixo.
          </Typography>
        </AutoPaymentCard>

        <AutoPaymentCard
          stepIndex={1.2}
          heading="No Banco Inter, crie sua API e obtenha as credenciais"
          buttonLabel="Ver passo a passo"
          buttonHref="https://developers.inter.co/docs/introducao/como-criar-uma-aplicacao"
          buttonTarget="_blank"
        >
          <Typography fontWeight="400">
            Siga o passo a passo abaixo para criar a seguinte API do Banco
            Inter:
          </Typography>

          <Card variant="outlined" className={styles.card}>
            <CardContent className={styles.cardContent}>
              <Typography
                variant="text-sm"
                color="grey.600"
                fontWeight={600}
                marginBottom={4}
              >
                <b>API Banking</b>
              </Typography>

              <Typography
                variant="text-xs"
                color="grey.600"
                fontWeight="400"
                marginBottom={4}
              >
                Ao criar a API Banking, é importante{' '}
                <strong className={styles.orange}>
                  selecionar todos os escopos
                </strong>{' '}
                de permissões, tais como:
              </Typography>

              <Typography variant="text-xs" color="grey.600">
                Realizar, consultar, agendar e cancelar pagamentos e
                agendamentos; consultar extrato e saldo; criar, editar e
                consultar webhooks.
              </Typography>
            </CardContent>
          </Card>
        </AutoPaymentCard>

        <AutoPaymentCard
          stepIndex={1.3}
          heading="Volte ao app da FinBits com as credenciais geradas"
        >
          <Typography fontWeight="400">
            Ao final do processo de criação da API,{' '}
            <b>obtenha as seguintes credenciais</b> geradas pelo Banco Inter:
          </Typography>

          <Card variant="outlined" className={styles.card}>
            <CardContent className={styles.cardContent}>
              <ul className={styles.list}>
                <li>Client ID</li>
                <li>Client Secret</li>
                <li>Chave Privada</li>
                <li>Certificado</li>
              </ul>
            </CardContent>
          </Card>
        </AutoPaymentCard>
      </Box>

      <Card
        variant="outlined"
        className={classNames(styles.termCard, {
          [styles.error]: form.formState.errors.term,
        })}
      >
        <CardContent className={styles.cardContent}>
          <FormProvider {...form}>
            <FormField name="term" showErrors={false}>
              {(field) => (
                <FormControlLabel
                  {...field}
                  control={
                    <Checkbox
                      size="small"
                      className={classNames({
                        [styles.error]: form.formState.errors.term,
                      })}
                    />
                  }
                  label="Tenho as credenciais geradas pelo Internet Banking do Banco Inter."
                  className={styles.formLabel}
                />
              )}
            </FormField>
          </FormProvider>

          <Typography
            variant="text-sm"
            color="grey.600"
            fontWeight={400}
            marginBottom={4}
          >
            Confirme que já possui as credenciais para seguir para próxima
            etapa.
          </Typography>
        </CardContent>
      </Card>

      {form.formState.errors.term && (
        <Typography
          variant="text-sm"
          fontWeight={400}
          marginTop="5px"
          className={styles.error}
        >
          {form.formState.errors.term.message}
        </Typography>
      )}

      <Box
        display="flex"
        justifyContent="flex-end"
        width="100%"
        marginTop="38px"
        gap={3}
      >
        <Button size="medium" variant="outlined" onClick={onPrev}>
          Voltar
        </Button>

        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={onSubmit}
        >
          Continuar
        </Button>
      </Box>
    </>
  );
}
