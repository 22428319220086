import { useState } from 'react';

import { Alert, Chip, Typography } from 'src/design-system/components';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import { useCategories } from 'src/libs/finbits/Management/FinancialEntries/Categories';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { getItem, setItem } from 'src/libs/finbits/Storage';

import styles from './CategoryAlert.module.scss';

export function buildStorageKey(companyId: string) {
  return `overview_page_category_alert:${companyId}`;
}

function getStoredClosedValue(companyId: string) {
  const storedValue = getItem(buildStorageKey(companyId), { closed: false });

  return storedValue.closed;
}

export default function CategoryAlert() {
  const { companyId, organizationId } = useCompanyParams();
  const { categories } = useCategories({ companyId, organizationId });

  const [closed, setClosed] = useState(getStoredClosedValue(companyId));

  function handleClose() {
    setClosed(true);
    setItem(buildStorageKey(companyId), { closed: true });
  }

  if (
    categories.length === 0 ||
    categories.some((category) => category.includeInCashFlow === false)
  ) {
    return null;
  }

  if (closed) return null;

  return (
    <Alert
      className={styles.alert}
      severity="default"
      icon={<Chip label="#FinDicas" className={styles.chip} square />}
      onClose={handleClose}
    >
      <Typography>
        Configure{' '}
        <Link
          component={RouterLink}
          to={generateCompanyPath(RouteKey.CATEGORIES, {
            companyId,
            organizationId,
          })}
          underline="always"
          variant="text-sm"
          fontWeight="600"
          color="grey.600"
        >
          categorias
        </Link>{' '}
        para <b>lançamentos a serem desconsiderados no fluxo de caixa,</b> como
        investimentos ou transferências entre contas da empresa.
      </Typography>
    </Alert>
  );
}
