import { useCallback } from 'react';

import type { DownloadFileParamsType } from './types';

export function useAutoDownloadFile() {
  return useCallback(({ file, filename, type }: DownloadFileParamsType) => {
    let blob;
    if (file instanceof Blob) {
      blob = file;
    } else {
      blob = new Blob([file], { type });
    }

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, []);
}
