import { Box, Collapse, Stack } from '@mui/material';
import { TabPanel } from '@mui/lab';
import cn from 'classnames';

import type { BillPayable } from 'src/libs/finbits/Bills/types';
import type { BillPayableOptional } from 'src/libs/finbits/Management/NewInboxItems/types';
import type { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import type { Receivable } from 'src/libs/finbits/Receivables/types';

import Tabs from 'src/features/entries/Modals/EntryModal/Tabs';
import Preview from 'src/features/entries/Modals/EntryModal/Preview';
import {
  EXPANSION_ENTER_TIME,
  EXPANSION_EXIT_TIME,
  usePreviewContext,
} from 'src/features/entries/Modals/EntryModal/Preview/PreviewProvider';
import { Tab } from 'src/features/entries/Modals/EntryModal/Tabs/TabPanels/TabPanelsProvider/types';

import Alerts from './Alerts';
import styles from './InformationTab.module.scss';

type Props =
  | {
      children?: React.ReactNode;
      entryType: EntryType.RECEIVABLE | EntryType.BILL;
      entry?: any;
    }
  | {
      children?: React.ReactNode;
      entry?: Receivable;
      entryType: EntryType.RECEIVABLE;
    }
  | {
      children?: React.ReactNode;
      entry?: BillPayable | BillPayableOptional;
      entryType: EntryType.BILL;
    };

export default function InformationTab({ entry, entryType, children }: Props) {
  const { isExpandedPreview } = usePreviewContext();

  return (
    <Stack direction="row" className={styles.container}>
      <Collapse
        in={!isExpandedPreview}
        orientation="horizontal"
        data-testid="information-tab"
        timeout={{
          exit: EXPANSION_EXIT_TIME,
          enter: EXPANSION_ENTER_TIME,
        }}
        classes={{
          root: styles.collapseRoot,
          wrapper: styles.collapseWrapper,
          wrapperInner: styles.collapseWrapperInner,
        }}
      >
        <Alerts entryType={entryType} entry={entry} />
        <Tabs />
        <TabPanel
          value={Tab.INFORMATION}
          aria-label="Informações"
          className={styles.information}
        >
          {children}
        </TabPanel>
      </Collapse>
      <Box className={styles.preview}>
        <TabPanel
          value={Tab.INFORMATION}
          className={cn(styles.previewPanel, {
            [styles.expanded]: isExpandedPreview,
          })}
        >
          <Preview />
        </TabPanel>
      </Box>
    </Stack>
  );
}
