import { useEffect } from 'react';

import { Button, Col, Form, Input, Row } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { AmountInput, FormItem, MaskedDatePicker } from 'src/ui';

import { greaterThanZeroValidator } from 'src/libs/finbits/Amount';
import type { PurchaseOrder } from 'src/libs/finbits/PurchaseOrder/types';
import type { PublicCategory } from 'src/libs/finbits/PublicCompanies/types';
import { emailRegex } from 'src/libs/finbits/Email';
import {
  formCnpjValidator,
  formCpfValidator,
} from 'src/libs/finbits/Documents';
import type { InboxItemContactType } from 'src/libs/finbits/Management/NewInboxItems/types';
import type { ApiErrorForm } from 'src/libs/finbits/client';
import { errorsToFormField } from 'src/libs/finbits/Form';

import DocumentSelect from 'src/features/purchase-order/DocumentSelect/DocumentSelect';
import UploadAttachments from 'src/features/purchase-order/UploadAttachments/UploadAttachments';
import CategorySelect from 'src/features/categories/DEPRECATED_CategorySelect';
import PaymentFields from 'src/features/payments/PaymentFields';

import styles from './PurchaseOrderForm.module.less';
import type {
  PurchaseOrderFormHiddenFields,
  PurchaseOrderFormValues,
} from './types';

type Props = {
  disabled?: boolean;
  isLoadingCategories?: boolean;
  categories?: PublicCategory[];
  loading?: boolean;
  requiredMark?: boolean;
  hideSubmitButton?: boolean;
  className?: string;
  initialValues?: PurchaseOrderFormValues;
  hiddenFields?: PurchaseOrderFormHiddenFields;
  onSubmit?: (values: PurchaseOrder) => void;
  errors?: ApiErrorForm;
};

function selectContactTypeValidator(
  rule: any,
  value: string,
  contactType?: string
) {
  if (contactType === 'natural') {
    return formCpfValidator(rule, value);
  }

  if (contactType === 'legal') {
    return formCnpjValidator(rule, value);
  }

  return Promise.resolve();
}

function buildErrorsToFormValue(errors?: ApiErrorForm) {
  if (!errors) return [];

  const errosToFormValue = {
    ...errors,
    paymentDetails: {
      ...errors.paymentDetails,
      boleto: { digitableLine: errors.digitableLine },
    },
  };

  return errorsToFormField(errosToFormValue);
}

export default function PurchaseOrderForm({
  disabled = false,
  isLoadingCategories = false,
  requiredMark = true,
  className,
  categories = [],
  loading,
  initialValues = {
    contactType: 'legal',
  },
  hiddenFields = [],
  hideSubmitButton = false,
  onSubmit,
  errors,
}: Props) {
  const [form] = Form.useForm();
  const contactType = Form.useWatch<InboxItemContactType>('contactType', form);

  useEffect(() => {
    errors && form.setFields(buildErrorsToFormValue(errors));
  }, [form, errors]);

  return (
    <Form
      fields={buildErrorsToFormValue(errors)}
      form={form}
      className={cn(styles.form, className)}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={initialValues}
      disabled={loading || disabled}
      requiredMark={requiredMark}
    >
      <Row gutter={{ sm: 16, md: 16, lg: 16 }}>
        {!hiddenFields.includes('requesterName') && (
          <Col xs={24} sm={24} md={12} lg={12}>
            <FormItem name="requesterName" label="Seu nome" required>
              <Input
                aria-label="nome"
                size="large"
                placeholder="Seu nome ou da sua empresa"
              />
            </FormItem>
          </Col>
        )}
        {!hiddenFields.includes('requesterEmail') && (
          <Col xs={24} sm={24} md={12} lg={12}>
            <FormItem
              name="requesterEmail"
              label="Seu email"
              required
              rules={[
                {
                  message: 'Formato de e-mail inválido',
                  pattern: emailRegex,
                },
              ]}
            >
              <Input
                aria-label="email"
                size="large"
                placeholder="Para receber confirmação do envio"
              />
            </FormItem>
          </Col>
        )}
      </Row>
      <Row gutter={{ sm: 16, md: 16, lg: 16 }}>
        {!hiddenFields.includes('description') && (
          <Col xs={24} sm={24} md={12} lg={12}>
            <FormItem name="description" label="Descrição" required>
              <Input
                aria-label="descrição"
                size="large"
                placeholder="Identifique a transação"
              />
            </FormItem>
          </Col>
        )}
        {!hiddenFields.includes('amount') && (
          <Col xs={24} sm={24} md={12} lg={12}>
            <FormItem
              name="amount"
              label="Valor"
              required
              hasMax={false}
              rules={[
                {
                  validator: greaterThanZeroValidator,
                },
              ]}
            >
              <AmountInput placeholder="0,00" />
            </FormItem>
          </Col>
        )}
      </Row>
      <Row gutter={{ sm: 16, md: 16, lg: 16 }}>
        {!hiddenFields.includes('contactDocument') && (
          <Col xs={24} sm={24} md={12} lg={12}>
            <FormItem
              name="contactDocument"
              label="Pagar para"
              required
              rules={[
                {
                  validator: (rule: any, value: string) =>
                    selectContactTypeValidator(rule, value, contactType),
                },
              ]}
              shouldUpdate
            >
              <DocumentSelect name="contactDocument" />
            </FormItem>
          </Col>
        )}

        {!hiddenFields.includes('categoryId') && (
          <Col xs={24} sm={24} md={12} lg={12}>
            <FormItem name="categoryId" label="Categoria" required>
              <CategorySelect
                categories={categories}
                loading={isLoadingCategories}
              />
            </FormItem>
          </Col>
        )}
      </Row>
      <Row gutter={{ sm: 16, md: 16, lg: 16 }}>
        {!hiddenFields.includes('date') && (
          <Col xs={24} sm={24} md={12} lg={12}>
            <FormItem hasMax={false} name="date" label="Data de pagamento">
              <MaskedDatePicker
                placeholder="dd/mm/aaaa"
                aria-label="data"
                dataTestId="dateField"
                className={styles.datePicker}
                size="large"
                format="dd/MM/yyyy"
                suffixIcon={<CalendarOutlined />}
              />
            </FormItem>
          </Col>
        )}
        {!hiddenFields.includes('paymentDetails') && (
          <PaymentFields
            paymentMethodSelectUIspan={12}
            requiredFields={['pixType', 'pixKey', 'boleto']}
          />
        )}
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <FormItem name="notaFiscalNumber" label="Nota fiscal">
            <Input
              aria-label="número da nota fiscal"
              size="large"
              placeholder="Número da NF"
            />
          </FormItem>
        </Col>
        <Col xs={24} md={12}>
          <FormItem
            name="notaFiscalIssueDate"
            hasMax={false}
            label="Data de emissão da NF"
          >
            <MaskedDatePicker
              placeholder="dd/mm/aaaa"
              aria-label="Data de emissão da NF"
              dataTestId="notaFiscalIssueDateField"
              className={styles.datePicker}
              size="large"
              format="dd/MM/yyyy"
              suffixIcon={<CalendarOutlined />}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <FormItem name="dueDate" hasMax={false} label="Data de vencimento">
            <MaskedDatePicker
              placeholder="dd/mm/aaaa"
              aria-label="data de vencimento"
              dataTestId="dueDateField"
              className={styles.datePicker}
              size="large"
              format="dd/MM/yyyy"
              suffixIcon={<CalendarOutlined />}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={{ sm: 16, md: 16, lg: 16 }}>
        {!hiddenFields.includes('files') && (
          <Col span={24}>
            <Form.Item name="files" label="Anexos">
              <UploadAttachments />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={{ sm: 16, md: 16, lg: 16 }}>
        {!hiddenFields.includes('comments') && (
          <Col span={24}>
            <FormItem hasMax={false} name="comments" label="Observação">
              <Input.TextArea
                placeholder="Digite aqui"
                size="large"
                autoSize={{ minRows: 2, maxRows: 12 }}
              />
            </FormItem>
          </Col>
        )}
      </Row>
      {!hideSubmitButton && (
        <Col span={24}>
          <Button
            size="large"
            htmlType="submit"
            type="primary"
            className={styles.saveBtn}
            loading={loading}
          >
            Enviar
          </Button>
        </Col>
      )}
    </Form>
  );
}
