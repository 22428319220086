import type { MouseEvent } from 'react';
import { useState } from 'react';

import { IconButton, Menu, MenuItem, MenuList, Stack } from '@mui/material';
import {
  HelpCircleIcon,
  MessageChatCircleIcon,
  SearchHelpIcon,
} from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';
import { Box } from '@mui/system';

import { Badge } from 'src/mui';

import { useMessageBird } from 'src/libs/messageBird/useMessageBird';

export default function HelpCenterMenu() {
  const { hasMessage, toggleChat } = useMessageBird();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isVisibility = !!anchorEl;

  function toggleVisibility(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl((state) => (state ? null : event.currentTarget));
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton onClick={toggleVisibility} size="large" aria-label="Ajuda">
        <Badge variant="dot" color="error" invisible={!hasMessage}>
          <HelpCircleIcon />
        </Badge>
      </IconButton>

      <Menu
        open={isVisibility}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <MenuList>
          <MenuItem disabled>
            <Typography variant="text-xs" fontWeight="500">
              Central de ajuda
            </Typography>
          </MenuItem>
          <MenuItem
            component="a"
            href="https://www.finbits.com.br/central-de-ajuda"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Stack direction="row" alignItems="center" gap={3} color="grey.500">
              <SearchHelpIcon />
              <Box>
                <Typography color="grey.700" variant="text-sm" fontWeight={500}>
                  Dúvidas frequentes
                </Typography>
                <Typography color="grey.700" variant="text-xs">
                  Consulte nossa FAQ
                </Typography>
              </Box>
            </Stack>
          </MenuItem>

          <MenuItem onClick={toggleChat}>
            <Stack direction="row" alignItems="center" gap={3} color="grey.500">
              <Badge variant="dot" color="error" invisible={!hasMessage}>
                <MessageChatCircleIcon />
              </Badge>

              <Box>
                <Typography variant="text-sm" fontWeight={500} color="grey.700">
                  Suporte via chat
                </Typography>
                <Typography color="grey.700" variant="text-xs">
                  Disponível em dias úteis, das 9h às 18h.
                </Typography>
              </Box>
            </Stack>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
