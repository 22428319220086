import type { PaletteMode, PaletteOptions, Shadows } from '@mui/material';
import { experimental_extendTheme as extendTheme } from '@mui/material';
import type { TypographyOptions } from '@mui/material/styles/createTypography';

import { colorSchemes } from './colorSchemes';
import { createComponents } from './components';
import { createPalette } from './create-palette';
import { createLocale } from './locale';
import { shadows } from './shadows';
import { typography } from './typography';

export type ThemeConfig = {
  palette: PaletteOptions;
  typography: TypographyOptions;
  shadows: Shadows;
};

export function createTheme(theme: PaletteMode) {
  const palette = createPalette(theme);
  const components = createComponents({ palette, typography, shadows });

  return extendTheme(
    {
      components,
      colorSchemes,
      shape: { borderRadius: 8 },
      typography,
      spacing: 4,
      shadows,
      zIndex: {
        appBar: 5,
        modal: 8,
      },
      breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'uxl'],
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1280,
          xl: 1440,
          xxl: 2000,
          uxl: 3000,
        },
      },
    },
    createLocale()
  );
}
