import type { GridRowParams } from '@mui/x-data-grid-premium';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';

import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import { useRowActions } from '../useRowActions';

export function RowActions({
  row,
}: Pick<GridRowParams<FinancialStatementEntry>, 'row'>) {
  const { actionsForRow } = useRowActions(row);

  const actions = actionsForRow();

  return actions.map(({ dividerAfter, ...action }) => {
    const { tooltipActive, tooltipTitle, ...actionGrid } = action;
    return (
      <GridActionsCellItem
        {...actionGrid}
        key={action.name}
        showInMenu
        disabled={tooltipActive}
      />
    );
  });
}
