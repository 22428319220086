import useEntriesParams from 'src/features/entries/useEntriesParams';
import { AccountsAutocomplete } from 'src/features/accounts/AccountsAutocomplete';
import { ToolbarDatePicker } from 'src/features/entries/EntriesDataGrid/GridToolBar/ToolbarQuickActions/ToolbarDatePicker';

export default function ToolbarQuickActions() {
  const {
    selectedAccountIds,
    selectedDateRange,
    setSelectedAccountIds,
    setSelectedDateRange,
  } = useEntriesParams();

  return (
    <>
      <ToolbarDatePicker
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
      />
      <AccountsAutocomplete
        selectedAccountIds={selectedAccountIds}
        setSelectedAccountIds={setSelectedAccountIds}
      />
    </>
  );
}
