import { useCallback, useLayoutEffect, useMemo } from 'react';

import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { useBeforeUnload } from 'react-router-dom';
import omit from 'lodash/omit';
import type { GridPinnedColumnFields } from '@mui/x-data-grid-premium';
import type { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';

import * as Storage from 'src/libs/finbits/Storage';

type Props = {
  gridApi: GridApiPremium;
  storageKey?: string;
  pageSize?: number;
  pinnedColumns?: GridPinnedColumnFields;
};

function buildInitialState(
  pageSize?: number,
  pinnedColumns?: GridPinnedColumnFields
) {
  const initialState: GridInitialStatePremium = {};

  if (pageSize) {
    initialState.pagination = {
      paginationModel: { page: 0, pageSize },
    };
  }

  if (pinnedColumns) {
    initialState.pinnedColumns = pinnedColumns;
  }

  return initialState;
}

export function useStoredTableStatus({
  gridApi,
  storageKey,
  pageSize,
  pinnedColumns,
}: Props) {
  const storeGridState = useCallback(() => {
    if (gridApi.exportState && storageKey) {
      const updatedFilter = omit(gridApi.exportState(), [
        'filter.filterModel.quickFilterValues',
      ]);

      Storage.setItem(storageKey, updatedFilter);
    }
  }, [gridApi, storageKey]);

  useBeforeUnload(storeGridState);

  useLayoutEffect(() => {
    return storeGridState;
  }, [storeGridState]);

  const initialState = useMemo(() => {
    if (!storageKey) return buildInitialState(pageSize, pinnedColumns);

    return Storage.getItem(
      storageKey,
      buildInitialState(pageSize, pinnedColumns)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageKey, pageSize]);

  return { initialState };
}
