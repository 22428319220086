import { Suspense, lazy } from 'react';

import { useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Unstable_Grid2';

import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import {
  useIsEnabledBillsApprovalsInCompany,
  useIsEnabledBillsApprovalsRulesInCompany,
} from 'src/libs/finbits/Organization/Companies/Options';
import type { Contact } from 'src/libs/finbits/Organization/Companies/Contacts/types';

import {
  AccountsField,
  AmountField,
  ApprovalTypeField,
  AssigneesField,
  CategoriesField,
  CommentsField,
  ContactsField,
  DateField,
  DescriptionField,
  NotaFiscalNumberField,
  PaymentMethodField,
  UploadField,
} from 'src/features/entries/Fields';
import { useEntrySuggestionsContext } from 'src/features/entries/Suggestions/EntrySuggestionsProvider';
import { EditableFields } from 'src/features/EntryForm/types';
import { useEditableFieldsContext } from 'src/features/entries/Modals/EntryModal/EditableFieldsProvider';
import SuggestionIcon from 'src/features/entries/Suggestions/SuggestionIcon';
import RecurrenceButton from 'src/features/entries/Modals/EntryModal/Tabs/TabPanels/RecurrenceTab/RecurrenceButton';
import { AdornmentSuggestionOverlap } from 'src/features/entries/Suggestions/InputAdornmentSuggestion/endAdornmentFn';

const PaymentFields = lazy(
  () => import('src/features/entries/Fields/PaymentFields')
);

export default function BillFields() {
  const { control } = useFormContext();

  const { suggestionSelected, updateSuggestionSelected } =
    useEntrySuggestionsContext();
  const { isEnabledBillsApprovals } = useIsEnabledBillsApprovalsInCompany();
  const { isEnabledBillsApprovalsRules } =
    useIsEnabledBillsApprovalsRulesInCompany();
  const { isDisabledField } = useEditableFieldsContext();

  const showApprovalFields =
    isEnabledBillsApprovals && !isEnabledBillsApprovalsRules;

  function handleContactChange(value: Contact | null) {
    if (value === null) {
      updateSuggestionSelected({ contact: null });
    }
  }

  return (
    <Grid component="form" container spacing={6}>
      <Grid xs={12}>
        <DescriptionField
          required
          name="description"
          control={control}
          disabled={isDisabledField(EditableFields.description)}
          endAdornment={<SuggestionIcon fieldName="description" />}
        />
      </Grid>

      <Grid xs={6}>
        <AmountField
          required
          name="amount"
          control={control}
          disabled={isDisabledField(EditableFields.amount)}
          endAdornment={<SuggestionIcon fieldName="amount" />}
        />
      </Grid>

      <Grid xs={6} gap={6} display="flex">
        <DateField
          showSuggestionIcon
          name="date"
          control={control}
          label="Data de pagamento"
          required
          disabled={isDisabledField(EditableFields.date)}
        />
        <RecurrenceButton />
      </Grid>

      <Grid xs={12}>
        <AccountsField
          required
          name="accountId"
          control={control}
          disabled={isDisabledField(EditableFields.accountId)}
          endAdornmentFn={AdornmentSuggestionOverlap}
        />
      </Grid>

      <Grid xs={12}>
        <ContactsField
          suggestion={suggestionSelected?.contact}
          name="contactId"
          control={control}
          onChange={handleContactChange}
          disabled={isDisabledField(EditableFields.contactId)}
          endAdornmentFn={AdornmentSuggestionOverlap}
        />
      </Grid>

      <Grid xs={6}>
        <CategoriesField
          name="categoryId"
          type={BalanceType.DEBIT}
          control={control}
          disabled={isDisabledField(EditableFields.categoryId)}
          endAdornmentFn={AdornmentSuggestionOverlap}
        />
      </Grid>

      <Grid xs={6}>
        <PaymentMethodField
          control={control}
          name="paymentDetails.paymentMethod"
          disabled={isDisabledField(EditableFields.paymentDetails)}
          endAdornmentFn={AdornmentSuggestionOverlap}
        />
      </Grid>

      <Suspense>
        <PaymentFields showSuggestionIcon />
      </Suspense>

      <Grid xs={6}>
        <NotaFiscalNumberField
          name="notaFiscalNumber"
          control={control}
          endAdornment={<SuggestionIcon fieldName="notaFiscalNumber" />}
          disabled={isDisabledField(EditableFields.notaFiscalNumber)}
        />
      </Grid>

      <Grid xs={6}>
        <DateField
          showSuggestionIcon
          name="notaFiscalIssueDate"
          control={control}
          label="Data de emissão da NF"
          disabled={isDisabledField(EditableFields.notaFiscalIssueDate)}
        />
      </Grid>

      <Grid xs={6}>
        <DateField
          showSuggestionIcon
          name="relevantDate"
          control={control}
          label="Competência"
          placeholder="Selecione um mês"
          DatePickerProps={{
            views: ['month', 'year'],
            format: 'MMM/yyyy',
          }}
          disabled={isDisabledField(EditableFields.relevantDate)}
        />
      </Grid>

      <Grid xs={6}>
        <DateField
          showSuggestionIcon
          name="dueDate"
          control={control}
          label="Data do vencimento"
          disabled={isDisabledField(EditableFields.dueDate)}
        />
      </Grid>

      {showApprovalFields && (
        <>
          <Grid xs={6}>
            <AssigneesField
              name="assigneesIds"
              control={control}
              disabled={isDisabledField(EditableFields.assigneesIds)}
              endAdornmentFn={AdornmentSuggestionOverlap}
            />
          </Grid>

          <Grid xs={6}>
            <ApprovalTypeField
              name="approvalType"
              control={control}
              disabled={isDisabledField(EditableFields.approvalType)}
            />
          </Grid>
        </>
      )}

      <Grid xs={12}>
        <UploadField
          name="attachments"
          control={control}
          disabled={isDisabledField(EditableFields.attachmentsIds)}
        />
      </Grid>

      <Grid xs={12}>
        <CommentsField
          name="comments"
          control={control}
          disabled={isDisabledField(EditableFields.comments)}
          endAdornment={<SuggestionIcon fieldName="comments" />}
        />
      </Grid>
    </Grid>
  );
}
