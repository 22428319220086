import { TabList } from '@mui/lab';
import { CircularProgress, Tab } from '@mui/material';
import { SparklesIcon } from 'src/mui/_icons';

import { Tooltip } from 'src/mui';

import { useTabPanelsContext } from 'src/features/entries/Modals/EntryModal/Tabs/TabPanels/TabPanelsProvider';
import { PreviewTab } from 'src/features/entries/Modals/EntryModal/Tabs/TabPanels/TabPanelsProvider/types';
import { useEntrySuggestionsContext } from 'src/features/entries/Suggestions/EntrySuggestionsProvider';

import styles from './PreviewTabs.module.scss';
import Label from './Label';

function a11yProps(index: number) {
  return {
    id: `entry-tabs-${index}`,
    'aria-controls': `entry-tabpanel-${index}`,
  };
}

export default function PreviewTabs() {
  const { setSelectedPreviewTab } = useTabPanelsContext();
  const { isLoadingSuggestions } = useEntrySuggestionsContext();

  return (
    <TabList
      textColor="secondary"
      indicatorColor="secondary"
      onChange={(_, value) => setSelectedPreviewTab(value)}
      className={styles.tabs}
    >
      <Tab
        label={
          <Label
            title="Sugestões de Preenchimento"
            icon={
              isLoadingSuggestions ? (
                <Tooltip title="Buscando novas sugestões de preenchimento">
                  <CircularProgress color="secondary" size={16} />
                </Tooltip>
              ) : (
                <Tooltip title="As sugestões de preenchimento são geradas pela FinBot a partir das informações e anexos recebidos na caixa de entrada ou inseridos no lançamento.">
                  <SparklesIcon
                    className={styles.suggestionsIcon}
                    aria-label="Sugestões pela FinBot"
                  />
                </Tooltip>
              )
            }
          />
        }
        value={PreviewTab.SUGGESTIONS}
        {...a11yProps(2)}
      />

      <Tab
        label={<Label title="Visualização de Anexos" />}
        value={PreviewTab.ATTACHMENTS}
        {...a11yProps(3)}
      />
    </TabList>
  );
}
