import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { flatten } from 'flat';
import isRegExp from 'lodash/isRegExp';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useEntrySuggestions } from 'src/libs/finbits/Management/Entries';
import { SuggestionSource } from 'src/libs/finbits/Management/Entries/types';
import type {
  NewEntrySuggestion,
  SuggestionFields,
} from 'src/libs/finbits/Management/Entries/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { useCompanyListener } from 'src/libs/finbits/Channels';

import type {
  EntrySuggestionsContextProps,
  EntrySuggestionsProviderProps,
} from './types';

const EntrySuggestionsContext = createContext<
  EntrySuggestionsContextProps | undefined
>(undefined);

export default function EntrySuggestionsProvider({
  children,
  inboxItemId,
  attachments,
  contactId,
  contact,
  paymentMethod,
  suggestedFields = [],
  dirtyFields = {},
}: EntrySuggestionsProviderProps) {
  const { companyId, organizationId } = useCompanyParams();
  const { entrySuggestions, isLoading: isLoadingSuggestions } =
    useEntrySuggestions();
  const [suggestions, setSuggestions] = useState<NewEntrySuggestion[]>([]);
  const [suggestionSelected, setSuggestionSelected] =
    useState<SuggestionFields>();

  useEffect(() => {
    const hasDocumentWithNoUser = !contact?.id && contact?.document;
    if (hasDocumentWithNoUser) {
      handleUpdateSuggestion({
        contact,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact?.id || contact?.document]);

  function filterSuggestionByParams(suggestions: NewEntrySuggestion[]) {
    return suggestions.filter((suggestion) => {
      if (suggestion.sourceType === SuggestionSource.ATTACHMENT) {
        return attachments?.some(
          (attachment) => attachment.id === suggestion.source.id
        );
      }

      if (suggestion.sourceType === SuggestionSource.CONTACT) {
        return contactId === suggestion.source.id;
      }

      return true;
    });
  }

  async function getSuggestions() {
    const params = {
      contactId: contactId || undefined,
      paymentDetails: paymentMethod
        ? { paymentMethod: paymentMethod }
        : undefined,
      inboxItemsIds: [inboxItemId!],
      attachmentsIds: attachments?.map((attachment) => attachment.id),
      type: BalanceType.DEBIT,
    };

    entrySuggestions(
      {
        organizationId,
        companyId,
        params,
      },
      {
        onSuccess: async (response) => {
          setSuggestions(filterSuggestionByParams(response));
        },
      }
    );
  }

  function handleUpdateSuggestion(newSuggestion = {}) {
    setSuggestionSelected((prev) => {
      return { ...prev, ...newSuggestion };
    });
  }

  function isSuggestionField(fieldName: string | RegExp) {
    const dirtyFieldsKeys = Object.entries(
      flatten(dirtyFields) as { [k: string]: boolean }
    )
      .filter(([_key, value]) => value === true)
      .map(([key, _value]) => key);

    if (isRegExp(fieldName)) {
      return (
        suggestedFields.some((str) => fieldName.test(str)) &&
        !dirtyFieldsKeys.some((str) => fieldName.test(str))
      );
    }

    return (
      suggestedFields.includes(fieldName) &&
      !dirtyFieldsKeys.includes(fieldName)
    );
  }

  useEffect(() => {
    getSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId, inboxItemId, paymentMethod, attachments?.length]);

  useEffect(() => {
    setSuggestions(filterSuggestionByParams(suggestions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId, attachments?.length]);

  const ocrListener = useCallback(
    ({ attachmentId }: { attachmentId: string }) => {
      if (attachments?.some((attachment) => attachment.id === attachmentId)) {
        getSuggestions();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attachments]
  );

  useCompanyListener('attachment_ocr_completed', ocrListener);

  return (
    <EntrySuggestionsContext.Provider
      value={{
        suggestions,
        suggestionSelected,
        isLoadingSuggestions,
        updateSuggestionSelected: handleUpdateSuggestion,
        updateSuggestions: setSuggestions,
        isSuggestionField,
      }}
    >
      {children}
    </EntrySuggestionsContext.Provider>
  );
}

export function useEntrySuggestionsContext() {
  const context = useContext(EntrySuggestionsContext);

  if (!context) {
    throw new Error(
      'To use context, you must provide a EntrySuggestionsProvider'
    );
  }

  return context;
}
