import type { ReactElement } from 'react';

import type { SnackbarOrigin } from 'notistack';
import {
  CheckDoneIcon,
  CheckIcon,
  Copy07Icon,
  DividerIcon,
  FileLinesIcon,
  ShareIcon,
  TrashIcon,
} from 'src/mui/_icons';

import { snackbar } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import { useDeleteBill } from 'src/libs/finbits/Bills';
import {
  BillPayableApprovalType,
  BillStatus,
} from 'src/libs/finbits/Bills/types';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';
import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';
import { useDeleteFinancialEntry } from 'src/libs/finbits/Management/FinancialEntries';
import { canPerformAction } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { useNotaFiscalConnection } from 'src/libs/finbits/NotaFiscal/Connection';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import { useDeleteReceivable } from 'src/libs/finbits/Receivables';

import CreateBillModal from 'src/features/bills-to-pay/CreateBillModal';
import useMarkAsFinancialDoneAction from 'src/features/entries/Actions/mark-financial-done-action/hooks/useMarkAsFinancialDoneAction';
import { useOpenConfirmDeletionDialog } from 'src/features/entries/Dialogs/Dialogs';
import EditFinancialEntryDrawer from 'src/features/entries/Drawers/FinancialEntry/EditFinancialEntryDrawer';
import EditSplitsDrawer from 'src/features/entries/Drawers/FinancialEntry/EditSplitsDrawer';
import useOpenEntryEditDrawer from 'src/features/entries/hooks/useOpenEntryEditDrawer';
import CreateNotaFiscalDrawer from 'src/features/nota-fiscal/CreateNotaFiscalDrawer';
import CreateScheduledDrawer from 'src/features/scheduled/CreateScheduledDrawer';

export type RowAction = {
  name: string;
  icon: ReactElement;
  label: string;
  onClick: () => void;
  dividerAfter?: boolean;
  tooltipTitle?: string;
  tooltipActive?: boolean;
};

const snackbarAnchorOrigin: SnackbarOrigin = {
  horizontal: 'center',
  vertical: 'bottom',
};

function addIf(mustAdd: boolean, action: RowAction) {
  return mustAdd ? [action] : [];
}

export function useRowActions(row: FinancialStatementEntry) {
  const openPortal = useOpenPortal();
  const openEntryEditDrawer = useOpenEntryEditDrawer();

  const { organizationId, companyId } = useCompanyParams();

  const { deleteBill } = useDeleteBill();
  const { deleteFinancialEntry } = useDeleteFinancialEntry();
  const { deleteReceivable } = useDeleteReceivable();

  const { status: connectionStatus } = useNotaFiscalConnection();
  const openConfirmDeletion = useOpenConfirmDeletionDialog();

  const userCanDelete = useAllowedPermission({
    action: 'delete',
    resource: rowResource(),
  });
  const userCanSplit = useAllowedPermission({
    action: 'create',
    resource: 'financialEntrySplit',
  });
  const userCanCreateNF = useAllowedPermission({
    action: 'create',
    resource: 'notasFiscais',
  });
  const userCanConciliate = useAllowedPermission({
    action: 'conciliate',
    resource: rowResource(),
  });
  const userCanMarkAsDone = useAllowedPermission({
    action: ['create_from_receivable', 'create_from_bill'],
    resource: 'financialEntries',
  });
  const { isEnabled: isCloneEntriesEnabled } = useExternalFeatureFlag(
    Feature.CLONE_ENTRIES
  );
  const { isEnabled: isMarkBatchReleasesEnabled } = useExternalFeatureFlag(
    Feature.MARK_BATCH_RELEASES
  );

  const isMarkAsDoneEnabled =
    new Date(row.date) <= new Date() &&
    row.availableActions.includes(AvailableActions.MARK_AS_FINANCIAL_ENTRY);

  const { markAsFinancialDone } = useMarkAsFinancialDoneAction({
    entries: [row],
  });

  function openDeleteEntryModal() {
    const callbacks = {
      onSuccess: () => {
        snackbar({
          variant: 'success',
          anchorOrigin: snackbarAnchorOrigin,
          message: 'Lançamento excluído',
        });
      },
      OnError: () => {
        snackbar({
          variant: 'error',
          anchorOrigin: snackbarAnchorOrigin,
          message: 'Ocorreu um erro ao excluir lançamento',
        });
      },
    };

    openConfirmDeletion({
      onConfirm: () => {
        if (row.entryType === EntryType.FINANCIAL_ENTRY) {
          deleteFinancialEntry(
            {
              organizationId,
              companyId,
              financialEntryId: row.id,
            },
            callbacks
          );
        }

        if (row.entryType === EntryType.BILL) {
          deleteBill({ organizationId, companyId, billId: row.id }, callbacks);
        }

        if (row.entryType === EntryType.RECEIVABLE) {
          deleteReceivable(
            {
              organizationId,
              companyId,
              receivableId: row.id,
            },
            callbacks
          );
        }
      },
    });
  }

  function openCreateNFDrawer() {
    if (row.entryType === EntryType.FINANCIAL_ENTRY) {
      openPortal(CreateNotaFiscalDrawer, {
        initialValues: {
          financialEntryId: row.id,
          amount: row.amount,
          contactId: row.contactId || undefined,
        },
      });
    }

    if (row.entryType === EntryType.RECEIVABLE) {
      openPortal(CreateNotaFiscalDrawer, {
        initialValues: {
          receivableId: row.id,
          amount: row.amount,
          contactId: row.contactId || undefined,
        },
      });
    }
  }

  function openConciliationDrawer() {
    openPortal(EditFinancialEntryDrawer, {
      financialEntryId: row.id,
      isSplit: !!row.isSplit,
      isConciliation: true,
    });
  }

  function openCreateSplitDrawer() {
    openPortal(EditFinancialEntryDrawer, {
      financialEntryId: row.id,
      isSplit: !!row.isSplit,
      isSplitCreation: true,
    });
  }

  function openEditSplitDrawer() {
    if (!row.transactionId) return;

    openPortal(EditSplitsDrawer, {
      transactionId: row.transactionId,
      type: row.amountType,
    });
  }

  function rowResource() {
    if (row.entryType === EntryType.BILL) {
      return 'bills';
    }

    if (row.entryType === EntryType.RECEIVABLE) {
      return 'receivables';
    }

    return 'financialEntries';
  }

  function canDelete() {
    return userCanDelete && canPerformAction(row, AvailableActions.DELETE);
  }

  function canSplit() {
    return userCanSplit && canPerformAction(row, AvailableActions.SPLIT);
  }

  function canConciliate() {
    return (
      userCanConciliate && canPerformAction(row, AvailableActions.CONCILIATE)
    );
  }

  function canCreateNF() {
    return (
      connectionStatus === 'active' &&
      userCanCreateNF &&
      canPerformAction(row, AvailableActions.ISSUE_NF)
    );
  }

  function canMarkAsDone() {
    return userCanMarkAsDone && isMarkBatchReleasesEnabled;
  }

  function cloneEntry(row: FinancialStatementEntry) {
    const amountType =
      row.entryType === EntryType.BILL ? BalanceType.DEBIT : row.amountType;

    if (amountType === BalanceType.CREDIT) {
      createReceivableModal(row);
    } else {
      createBillModal(row);
    }
  }

  function createBillModal(row: FinancialStatementEntry) {
    const { description } = row;

    openPortal(CreateBillModal, {
      defaultBill: {
        ...row,
        id: '',
        publicId: '',
        status: BillStatus.PENDING,
        approvalType: BillPayableApprovalType.ALL,
        amountType: BalanceType.DEBIT,
        companyId,
        description: `(Cópia) ${description}`,
        entryType: EntryType.BILL,
        scheduledRecurrenceId: null,
        paymentRequestErrorDetail: null,
      },
    });
  }

  function createReceivableModal(row: FinancialStatementEntry) {
    const {
      notaFiscalIssueDate,
      notaFiscalId,
      relevantDate,
      description,
      ...filteredRow
    } = row;

    const classifications = row.classifications?.map((classification) => ({
      labelsIds: classification.labels.map((label) => label.id),
      classificationId: classification.id,
    }));

    openPortal(CreateScheduledDrawer, {
      initialValues: {
        ...filteredRow,
        type: BalanceType.CREDIT,
        approvalType: BillPayableApprovalType.ALL,
        date: new Date(row.date),
        dueDate: row?.dueDate ? new Date(row?.dueDate) : null,
        description: `(Cópia) ${description}`,
        classifications,
      },
    });
  }

  function actionsForRow(): RowAction[] {
    return [
      {
        name: 'openEntry',
        icon: <ShareIcon />,
        label: 'Abrir lançamento',
        onClick: () => openEntryEditDrawer(row),
        dividerAfter: true,
      },

      ...addIf(canMarkAsDone(), {
        name: 'markDone',
        icon: <CheckIcon />,
        label: 'Marcar como realizado',
        onClick: markAsFinancialDone,
        tooltipActive: !isMarkAsDoneEnabled,
        tooltipTitle:
          'Somente lançamentos de contas bancárias manuais com data de pagamento até hoje podem ser marcados como realizados.',
      }),

      ...addIf(isCloneEntriesEnabled, {
        name: 'cloneEntry',
        icon: <Copy07Icon />,
        label: 'Duplicar',
        onClick: () => cloneEntry(row),
      }),

      ...addIf(canConciliate(), {
        name: 'conciliateEntry',
        icon: <CheckDoneIcon />,
        label: 'Conciliar lançamento',
        onClick: openConciliationDrawer,
      }),

      ...addIf(!!row.isSplit, {
        name: 'editSplitEntry',
        icon: <DividerIcon />,
        label: 'Editar quebra do lançamento',
        onClick: openEditSplitDrawer,
      }),

      ...addIf(canSplit(), {
        name: 'createSplitEntry',
        icon: <DividerIcon />,
        label: 'Quebrar lançamento',
        onClick: openCreateSplitDrawer,
      }),

      ...addIf(canCreateNF(), {
        name: 'createNF',
        icon: <FileLinesIcon />,
        label: 'Emitir Nota Fiscal',
        onClick: openCreateNFDrawer,
      }),

      ...addIf(canDelete(), {
        name: 'deleteEntry',
        icon: <TrashIcon />,
        label: 'Excluir',
        onClick: openDeleteEntryModal,
      }),
    ];
  }

  return { actionsForRow };
}
