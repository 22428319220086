import { Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LinkExternalIcon, PlusIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { CollapseText } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import { toCurrency } from 'src/libs/finbits/Money';
import type { PurchaseOrder } from 'src/libs/finbits/Management/NewInboxItems/types';
import { formatDocument } from 'src/libs/finbits/Documents';
import { format } from 'src/libs/finbits/Date';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import type { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';

import DetailsInfo from 'src/features/inbox-items/InboxItemDetails/DetailsInfo';
import EditContactDrawer from 'src/features/contacts/EditContactDrawer';
import CreateContactDrawer from 'src/features/contacts/CreateContactDrawer';

import PaymentMethodDetails from './PaymentMethodDetails';

type Props = {
  purchaseOrder?: PurchaseOrder | null;
};

export default function PurchaseOrderDetails({ purchaseOrder }: Props) {
  const openPortal = useOpenPortal();

  const canCreateContact = useAllowedPermission({
    action: 'create',
    resource: 'contacts',
    scope: 'company',
  });

  if (!purchaseOrder) return null;

  const formattedContactDocument = formatDocument(
    purchaseOrder.contactType,
    purchaseOrder.contactDocument
  );

  function handleOpenEditContact() {
    if (!purchaseOrder?.contact) return;

    openPortal(EditContactDrawer, {
      contactId: purchaseOrder?.contact.id,
    });
  }

  function handleOpenCreateContact() {
    const isInternational = purchaseOrder?.contactType === 'international';

    if (isInternational) {
      return openPortal(CreateContactDrawer, {
        defaultContactDocument: {
          isInternational: true,
          number: purchaseOrder?.contactDocument,
        },
      });
    }

    openPortal(CreateContactDrawer, {
      defaultContactDocument: {
        isInternational: false,
        number: purchaseOrder?.contactDocument,
        type: purchaseOrder?.contactType as ContactType,
      },
    });
  }

  return (
    <>
      <Grid
        sx={{ color: 'text.secondary' }}
        container
        columnGap={16}
        rowGap={6}
      >
        <DetailsInfo
          label="Valor"
          value={purchaseOrder.amount}
          formatter={toCurrency}
        />

        <DetailsInfo
          label="Data de pagamento"
          value={purchaseOrder.date}
          noContentMessage="Sem data"
          formatter={(value) => format(value, 'dd/MM/yy')}
        />

        <DetailsInfo
          label="Nota fiscal"
          value={purchaseOrder.notaFiscalNumber}
          noContentMessage="Sem número da NF"
        />

        <DetailsInfo
          label="Data de emissão da NF"
          value={purchaseOrder.notaFiscalIssueDate}
          noContentMessage="Sem data de emissão"
          formatter={(value) => format(value, 'dd/MM/yy')}
        />

        <DetailsInfo
          label="Data de vencimento"
          value={purchaseOrder.dueDate}
          noContentMessage="Sem data de vencimento"
          formatter={(value) => format(value, 'dd/MM/yy')}
        />

        {purchaseOrder.contact ? (
          <DetailsInfo
            label="Contato"
            value={purchaseOrder.contact.nickname}
            icon={
              <LinkExternalIcon
                sx={{ fontSize: '16px', color: 'text.primary' }}
              />
            }
            onClick={handleOpenEditContact}
          />
        ) : (
          <DetailsInfo
            label="Contato"
            value={formattedContactDocument}
            icon={
              canCreateContact && (
                <PlusIcon sx={{ fontSize: '16px', color: 'text.primary' }} />
              )
            }
            onClick={canCreateContact ? handleOpenCreateContact : undefined}
          />
        )}

        <DetailsInfo
          label="Categoria"
          value={purchaseOrder.category?.name}
          noContentMessage="Sem categoria"
        />
      </Grid>

      <Divider sx={{ my: 6 }} />

      <PaymentMethodDetails paymentDetails={purchaseOrder.paymentDetails} />

      <Divider sx={{ my: 6 }} />

      <Typography fontWeight={600} color="text.primary" marginBottom={2}>
        Observação
      </Typography>

      <CollapseText
        text={
          purchaseOrder.comments?.replaceAll('\n', '<br />') ??
          'Sem observações'
        }
      />
    </>
  );
}
