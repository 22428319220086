import { Typography } from 'src/design-system/components';

import styles from './HelperText.module.scss';

export function HelperText({ message }: { message?: string }) {
  return (
    <Typography color="error" variant="text-sm" className={styles.helperText}>
      {message}
    </Typography>
  );
}
