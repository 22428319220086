import type { QueryClient, UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { invalidateAccountsPendenciesQueries } from 'src/libs/finbits/Bank/Accounts';
import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';

import type {
  AccountBalance,
  CompanyBalance,
  GetAccountsBalancesParams,
  GetAccountsInitialBalances,
  GetCompaniesBalancesParams,
  PostInitialBalanceParams,
  SummaryAccountsBalances,
} from './types';
import {
  AccountBalanceDecoder,
  CompanyBalanceDecoder,
  SummaryAccountsBalancesDecoder,
} from './types';

export function invalidateCompaniesBalancesQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(['companiesBalances']);
}

async function getCompaniesBalances({
  organizationId,
  ...params
}: GetCompaniesBalancesParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies_balances`,
    {
      params,
    }
  );

  return decodeResponse<CompanyBalance[]>(
    response,
    CompanyBalanceDecoder.array()
  );
}

async function getAccountsBalances({
  organizationId,
  companyId,
  ...params
}: GetAccountsBalancesParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/accounts_balances`,
    {
      params,
    }
  );

  return decodeResponse<AccountBalance[]>(
    response,
    AccountBalanceDecoder.array()
  );
}

async function createInitialBalance({
  organizationId,
  companyId,
  accountId,
  ...params
}: PostInitialBalanceParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/accounts/${accountId}/set_initial_balance`,
    params
  );

  return response.data;
}

async function getSummaryAccountsBalances({
  organizationId,
  companyId,
  ...params
}: GetAccountsBalancesParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/summary_account_balances`,
    {
      params,
    }
  );

  return decodeResponse<SummaryAccountsBalances[]>(
    response,
    SummaryAccountsBalancesDecoder.array()
  );
}

export function useCompaniesBalances(params: GetCompaniesBalancesParams) {
  const { data: companiesBalances, ...rest } = useQuery<
    CompanyBalance[],
    ApiError
  >({
    enabled: !!params.organizationId,
    queryKey: ['companiesBalances', params],
    queryFn: () => getCompaniesBalances(params),
  });

  return {
    companiesBalances: withEmptyArrayDefault(companiesBalances),
    ...rest,
  };
}

export function useAccountsBalances(params: GetAccountsBalancesParams) {
  const { data: accountsBalances, ...rest } = useQuery<
    AccountBalance[],
    ApiError
  >({
    enabled: !!params.organizationId && !!params.companyId,
    queryKey: ['accountsBalances', params],
    queryFn: () => getAccountsBalances(params),
  });

  return {
    accountsBalances: withEmptyArrayDefault(accountsBalances),
    ...rest,
  };
}

export function useCreateInitialBalance() {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation<
    PostInitialBalanceParams,
    ApiError<ApiErrorForm>,
    PostInitialBalanceParams
  >(createInitialBalance, {
    onSuccess: () => {
      invalidateAccountsPendenciesQueries(queryClient);
    },
  });
  return { createInitialBalance: mutate, ...rest };
}

export function useSummaryAccountsBalances(params: GetAccountsBalancesParams) {
  const { data: summaryAccountsBalances, ...rest } = useQuery<
    SummaryAccountsBalances[],
    ApiError
  >({
    enabled: !!params.organizationId && !!params.companyId,
    queryKey: ['summaryAccountsBalances', params],
    queryFn: () => getSummaryAccountsBalances(params),
  });

  return {
    summaryAccountsBalances: withEmptyArrayDefault(summaryAccountsBalances),
    ...rest,
  };
}

async function getAccountsInitialBalances({
  companyId,
  organizationId,
}: GetAccountsInitialBalances) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/accounts_balances/initial_balances`
  );

  return decodeResponse<AccountBalance[]>(
    response,
    AccountBalanceDecoder.array()
  );
}

export function useAccountsInitialBalances(
  params: GetAccountsInitialBalances,
  customOptions: Partial<UseQueryOptions<AccountBalance[], ApiError>> = {}
) {
  const { data, ...rest } = useQuery<AccountBalance[], ApiError>({
    enabled: !!params.companyId && !!params.organizationId,
    queryKey: ['accountsInitialBalances', params],
    queryFn: () => getAccountsInitialBalances(params),
    ...customOptions,
  });

  return { accountsBalances: withEmptyArrayDefault(data), ...rest };
}
