import { Box } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import cn from 'classnames';

import Suggestions from 'src/features/entries/Suggestions';
import { useTabPanelsContext } from 'src/features/entries/Modals/EntryModal/Tabs/TabPanels/TabPanelsProvider';
import InboxAttachmentsStatusAlert from 'src/features/entries/Modals/EntryModal/autofill-suggestions/InboxAttachmentsStatusAlert';
import { PreviewTab } from 'src/features/entries/Modals/EntryModal/Tabs/TabPanels/TabPanelsProvider/types';

import { usePreviewContext } from './PreviewProvider';
import PreviewTabs from './PreviewTabs';
import Attachments from './Attachments';
import styles from './Preview.module.scss';

export default function Preview() {
  const { selectedPreviewTab } = useTabPanelsContext();

  const { isExpandedPreview, setIsToolbarVisible } = usePreviewContext();

  return (
    <TabContext value={selectedPreviewTab}>
      <Box
        className={cn(styles.preview, {
          [styles.expanded]: isExpandedPreview,
        })}
        onMouseEnter={() => setIsToolbarVisible(true)}
        onMouseLeave={() => setIsToolbarVisible(false)}
      >
        {!isExpandedPreview && <PreviewTabs />}
        <TabPanel className={styles.attachments} value={PreviewTab.ATTACHMENTS}>
          <Attachments />
        </TabPanel>
        <TabPanel
          className={styles.tabContent}
          value={PreviewTab.SUGGESTIONS}
          data-testid="tab-content-suggestions"
        >
          <InboxAttachmentsStatusAlert />

          <Suggestions />
        </TabPanel>
      </Box>
    </TabContext>
  );
}
