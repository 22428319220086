import type { PortalProps } from 'src/ui';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import { useGetCurrentConsentStep } from 'src/libs/finbits/Bank/BBConsentments';
import { TradeName } from 'src/libs/finbits/Bank/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import AutoPaymentModal from '../AutoPaymentModal';
import VerticalStepper from '../VerticalStepper';
import { FinalStep, IntroStep } from '../VerticalStepper/Steps';
import type { StepperContentProps } from '../VerticalStepper/types';

import BBAutoPaymentProvider from './BBAutoPaymentProvider';
import {
  AuthCredentialsStep,
  CertificateChainDownloaderStep,
  CertificateUploaderStep,
  GettingStartedStep,
  VerifyCredentialsStep,
} from './Steps';

type Props = PortalProps & {
  account: Account;
};

export default function BBAutoPayment({
  onClose,
  onExit,
  open = false,
  account,
}: Props) {
  const INTRODUCTION_STEP = 0;
  const INITIAL_STEP = 1;

  const { companyId, organizationId } = useCompanyParams();
  const { currentStep } = useGetCurrentConsentStep({
    organizationId,
    companyId,
    accountId: account.id,
  });

  const steps = [
    {
      content: (props: StepperContentProps) => <IntroStep {...props} />,
      hideLabelOnExpand: true,
      label: 'Introdução',
    },
    {
      content: (props: StepperContentProps) => (
        <GettingStartedStep {...props} />
      ),
      label: 'O que você precisa para começar',
    },
    {
      content: (props: StepperContentProps) => (
        <CertificateUploaderStep {...props} />
      ),
      label: 'Envie o seu Certificado A1',
    },
    {
      content: (props: StepperContentProps) => (
        <CertificateChainDownloaderStep {...props} />
      ),
      label: 'Baixe a cadeia de certificados gerada pela FinBits',
    },
    {
      content: (props: StepperContentProps) => (
        <VerifyCredentialsStep {...props} />
      ),
      label: 'Solicite sua API de Pagamentos no portal do Banco do Brasil',
    },
    {
      content: (props: StepperContentProps) => (
        <AuthCredentialsStep {...props} />
      ),
      label: 'Informe o número do Convênio PAG e as credenciais da API',
    },
    {
      content: (props: StepperContentProps) => <FinalStep {...props} />,
      label: 'Conclusão',
    },
  ];

  return (
    <BBAutoPaymentProvider>
      <AutoPaymentModal
        account={account}
        bankTradeName={TradeName.BB}
        showButtons={false}
        onClose={onClose}
        onExit={onExit}
        open={open}
      >
        <VerticalStepper
          account={account}
          onStepNext={() => {}}
          activeStepIndex={
            currentStep === INITIAL_STEP ? INTRODUCTION_STEP : currentStep
          }
          steps={steps}
          tradeName={TradeName.BB}
          onClose={onClose}
        />
      </AutoPaymentModal>
    </BBAutoPaymentProvider>
  );
}
