import type { PortalProps } from 'src/ui';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import { TradeName } from 'src/libs/finbits/Bank/types';

import AutoPaymentModal from '../AutoPaymentModal';
import VerticalStepper from '../VerticalStepper';
import { FinalStep, IntroStep } from '../VerticalStepper/Steps';
import type { StepperContentProps } from '../VerticalStepper/types';

import { CertificateStep, InstructionsStep } from './Steps';

type Props = PortalProps & {
  account: Account;
};

export default function InterAutoPayment({
  onClose,
  onExit,
  open = false,
  account,
}: Props) {
  const steps = [
    {
      label: 'Introdução',
      content: (props: StepperContentProps) => <IntroStep {...props} />,
      hideLabelOnExpand: true,
    },
    {
      label: 'Acesse sua Conta Digital PJ e crie a API de integração',
      content: (props: StepperContentProps) => <InstructionsStep {...props} />,
    },
    {
      label: 'Informe as credenciais geradas pelo Banco Inter',
      content: (props: StepperContentProps) => (
        <CertificateStep {...props} account={account} />
      ),
    },
    {
      label: 'Conclusão',
      content: (props: StepperContentProps) => <FinalStep {...props} />,
    },
  ];

  return (
    <AutoPaymentModal
      account={account}
      bankTradeName={TradeName.INTER}
      showButtons={false}
      onClose={onClose}
      onExit={onExit}
      open={open}
    >
      <VerticalStepper
        onStepNext={() => {}}
        activeStepIndex={0}
        steps={steps}
        tradeName={TradeName.INTER}
        onClose={onClose}
      />
    </AutoPaymentModal>
  );
}
